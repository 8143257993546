/* eslint-disable no-console */

import React, { useState } from 'react';
import { Box, Text, Image, useDisclosure, Flex } from '@chakra-ui/react';

import VideoModal from '../../../VideoModal/VideoModal';

import BrandExampleVideoCoverImage from '../../../../Assets/Images/brand-example-video-cover.png';

export default function ChallengeItemModalExampleVideos({ challenge }) {
  const { brand, videoExampleUrls } = challenge;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

  if (videoExampleUrls.length === 0) return null;

  const openVideoModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    onOpen();
  };

  return (
    <Box pb={5} pl={5} pr={5}>
      <Text fontFamily="DM Sans" mb={3} fontWeight="bold">
        Vídeo(s) de ejemplo
      </Text>
      <Flex flexWrap="wrap">
        {videoExampleUrls.map((videoUrl) => (
          <Image
            mr={3}
            mb={3}
            key={videoUrl}
            onClick={() => openVideoModal(videoUrl)}
            boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            height="150px"
            width="100px"
            borderRadius="8px"
            src={BrandExampleVideoCoverImage}
            alt="Brand video example"
            cursor="pointer"
          />
        ))}
      </Flex>

      <VideoModal
        brandName={brand.name}
        videoSrc={selectedVideoUrl}
        isOpen={isOpen}
        close={onClose}
        hasControl
      />
    </Box>
  );
}
