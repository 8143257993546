import apiSlice from '../../api/apiSlice';

export const affiliationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAffiliations: builder.query({
      query: () => 'affiliations',
      providesTags: ['Affiliations'],
    }),
  }),
});

export const {
  useGetAffiliationsQuery,
} = affiliationsApiSlice;
