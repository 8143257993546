import React from 'react';
import { Flex, Image } from '@chakra-ui/react';

import CruwiLogo from '../../Assets/Images/logo-cruwi-negro.svg';

import NotificationBell from './NotificationBell';

import HelpButton from './HelpButton';

export default function Header({ user }) {
  return (
    <Flex
      position="relative"
      pt={4}
      pb={4}
      alignItems="center"
      justifyContent="space-between"
      maxWidth="700px"
      margin="0 auto"
      borderBottom="1px solid #eee9e9"
      textAlign="left"
    >
      <Image marginLeft="20px" src={CruwiLogo} alt="CRUWI Logo" width="80px" />
      <Flex alignItems="center" marginRight="25px">
        <HelpButton />
        <NotificationBell user={user} />
      </Flex>
    </Flex>
  );
}
