import { Flex, Button, Box, Text } from '@chakra-ui/react';

import MoneyFlyBG from '../../Assets/Images/money-links-bg.png';

import formatCentsToEurs from '../../Utils/formatCentsToEurs';

export default function LinkItemBalance({ affiliation }) {
  const { balance } = affiliation;
  return (
    <Box
      minH="100px"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={`url(${MoneyFlyBG})`}
      borderRadius="8px"
      p={2}
    >
      <Text fontWeight="bold" fontSize="16px" textAlign="center">
        ¿Cuánto llevas ganado?
      </Text>
      <Text fontWeight="normal" fontSize="10px" textAlign="center">
        Pulsa en reclamar para incluirlo en tu saldo
      </Text>
      <Flex justifyContent="center" alignItems="center">
        <Text fontWeight="bold" fontSize="32px" textAlign="center">
          {formatCentsToEurs(balance)}
        </Text>
        <Button
          _hover={{ bg: '#dddddd' }}
          paddingRight="12px"
          paddingLeft="12px"
          bg="#dddddd"
          color="white"
          variant="solid"
          size="xs"
          ml={3}
          cursor="not-allowed"
        >
          Reclamar
        </Button>
      </Flex>
    </Box>
  );
}
