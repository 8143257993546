import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react';

import { useUpdateCollabMutation } from '../../../../Redux/features/collabs/collabsApiSlice';

export default function BriefingConfirmProductReceptionModal({
  collabData,
  isOpenConfirmProductReceptionModal,
  onCloseConfirmProductReception,
}) {
  // TODO: hasta que el usuario NO HAYA PUESTO SU DIRECCIÓN, NO PUEDE CONFIRMAR LA RECEPCIÓN, NO TIENE SENTIDO

  // Redux
  const [updateCollab, { isLoading: isLoadingUpdateCollab }] = useUpdateCollabMutation();

  // Toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 8000,
    });
  };

  // Función confirmación de la recepción del producto
  const handleConfirmProductReception = async () => {
    await updateCollab({ collabId: collabData._id, status: 'received' });
    showToast(
      'Has confirmado la recepción del producto. Recuerda que dispones de 7 días para realizar los contenidos.',
      'success',
    );
    onCloseConfirmProductReception();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpenConfirmProductReceptionModal}
      onClose={onCloseConfirmProductReception}
      closeOnOverlayClick
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="24px" m={5}>
        <ModalCloseButton />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          <Box mt={5}>
            <Text fontSize="20px" fontWeight="bold" mb={3}>
              Confirma que has recibido el producto
            </Text>
            <Text fontSize="14px" mb={2}>
              Cuando hayas recibido el pedido, pulsa el botón de &apos;Confirmar recepción&apos;.
            </Text>

            <Text fontSize="14px" mb={2}>
              A partir de este momento, <u>tendrás 7 días para grabar los contenidos</u> de la
              colaboración.
            </Text>

            <Text fontSize="14px">
              Este estado podrá cambiar también si la marca indica que el pedido te ha llegado.
            </Text>

            <Button
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
              mt={10}
              onClick={handleConfirmProductReception}
              isLoading={isLoadingUpdateCollab}
            >
              Confirmar recepción
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
