import { Flex, Box, Text, useDisclosure } from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import RewardIcon from '../../../../Assets/Images/basil_present-solid.svg';

import CustomLink from '../../../CustomLink/CustomLink';

import InfoRewardsModal from './InfoRewardsModal';

import LinkItemRewards from '../../../LinkItem/LinkItemRewards';

import formatCentsToEurNoFractionDigits from '../../../../Utils/formatCentsToEurNoFractionDigits';

export default function ChallengeItemModalRewardsNew({ isMission, challenge }) {
  // Sacamos lo que necesitamos
  const {
    reward: { fixed = 0, variable = 0, bonus = 0 },
  } = challenge;

  // Vemos si es de tipo conversión o por views (si tine el objeto, es de conversión)
  const hasAffiliation = Boolean(challenge.affiliation);

  // Para abrir el modal de información de las recompensas variables
  const {
    isOpen: isOpenInfoRewardsModal,
    onOpen: onOpenInfoRewardsModal,
    onClose: onCloseInfoRewardsModal,
  } = useDisclosure();

  // Función que abre el modal pulsando el +info
  const handleOpenInfoRewardsModal = () => {
    onOpenInfoRewardsModal();
  };

  // En función del tipo que sea, mostramos un contenido en el modal u otro. Por defecto será x views
  let conversionModalContent = (
    <Box>
      <Flex justifyContent="center">
        <Box
          width="26px"
          height="26px"
          borderRadius="50%"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundImage={`url(${challenge?.brand?.logoUrl})`}
          mr={2}
        />
        <Text fontSize="20px" fontWeight="bold">
          Variable x views
        </Text>
      </Flex>
      <Box maxW="400px" margin="20px auto 0px auto">
        <Text fontWeight="bold" fontSize="16px" textAlign="center">
          ¿Cuándo ganarás recompensas?
        </Text>
        <Flex mt={2} flexDir="column">
          <Box mb={2}>
            <Flex>
              <Box>
                <Box
                  border="1px solid black"
                  boxShadow="0.5px 0.5px 0px 0px #000"
                  w="18px"
                  h="18px"
                  borderRadius="50%"
                  backgroundRepeat="no-repeat"
                  backgroundSize="13px"
                  backgroundPosition="center"
                  backgroundColor="#ddded1"
                  backgroundImage={`url(${RewardIcon})`}
                  mr={2}
                />
              </Box>

              <Text fontSize="12px">
                Ganarás{' '}
                <strong>{formatCentsToEurNoFractionDigits(variable)} por cada 1000 views</strong>{' '}
                que obtenga tu vídeo en las primeras 48 horas.
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Box mt={2}>
          <Text lineHeight={1.3} fontSize="12px">
            *Recompensa máxima: {formatCentsToEurNoFractionDigits(challenge.maxReward)}. Podrás
            ganar hasta {formatCentsToEurNoFractionDigits(challenge.maxReward)} con este reto.*
          </Text>
        </Box>
        {bonus > 0 && (
          <>
            <Text fontWeight="bold" fontSize="16px" textAlign="center">
              ¿Cómo ganarás el bonus?
            </Text>
            <Flex mt={2} flexDir="column">
              <Box mb={2}>
                <Flex>
                  <Box>
                    <Box
                      border="1px solid black"
                      boxShadow="0.5px 0.5px 0px 0px #000"
                      w="18px"
                      h="18px"
                      borderRadius="50%"
                      backgroundRepeat="no-repeat"
                      backgroundSize="13px"
                      backgroundPosition="center"
                      backgroundColor="#ddded1"
                      backgroundImage={`url(${RewardIcon})`}
                      mr={2}
                    />
                  </Box>

                  <Text fontSize="12px">
                    Ganarás el bonus de <strong>{formatCentsToEurNoFractionDigits(bonus)}</strong>{' '}
                    si subes el vídeo en las primeras 72 horas del reto y es aprobado.
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );

  // Si es con afiliado, cambiamos el contenido del modal
  if (hasAffiliation) {
    conversionModalContent = (
      <Box>
        <Flex justifyContent="center" mr={4}>
          <Box
            width="26px"
            height="26px"
            borderRadius="50%"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundImage={`url(${challenge?.brand?.logoUrl})`}
            mr={2}
          />
          <Text fontSize="20px" fontWeight="bold">
            Variable x conversiones
          </Text>
        </Flex>
        <Box>
          <LinkItemRewards challenge={challenge} />
          <Box mt={5} textAlign="center">
            <Text fontSize="12px" fontWeight="bold" lineHeight={1.2}>
              Participa en el reto para obtener tu link. <br />
              Podrás siempre ver tu link y cuánto llevas ganado en el apartado de tu perfil &nbsp;
              <CustomLink to="/links">
                <span style={{ textDecoration: 'underline' }}>&apos;Mis links&apos;</span>
              </CustomLink>
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }

  if (isMission) {
    conversionModalContent = (
      <Box>
        <Flex justifyContent="center">
          <Box
            width="26px"
            height="26px"
            borderRadius="50%"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundImage={`url(${challenge?.brand?.logoUrl})`}
            mr={2}
          />
          <Text fontSize="20px" fontWeight="bold">
            Recompensas
          </Text>
        </Flex>
        <Box maxW="400px" margin="20px auto 0px auto">
          <Text fontWeight="bold" fontSize="16px" textAlign="center">
            ¿Cuándo ganarás recompensas?
          </Text>
          <Flex mt={2} flexDir="column">
            <Box mb={2}>
              <Flex>
                <Box>
                  <Box
                    border="1px solid black"
                    boxShadow="0.5px 0.5px 0px 0px #000"
                    w="18px"
                    h="18px"
                    borderRadius="50%"
                    backgroundRepeat="no-repeat"
                    backgroundSize="13px"
                    backgroundPosition="center"
                    backgroundColor="#ddded1"
                    backgroundImage={`url(${RewardIcon})`}
                    mr={2}
                  />
                </Box>

                <Text fontSize="12px">
                  Ganarás{' '}
                  <strong>
                    {formatCentsToEurNoFractionDigits(fixed)} cuando tu vídeo sea aprobado y alcance
                    1.000 views{' '}
                  </strong>
                  .
                </Text>
              </Flex>
            </Box>
            <Box mb={2}>
              <Flex>
                <Box>
                  <Box
                    border="1px solid black"
                    boxShadow="0.5px 0.5px 0px 0px #000"
                    w="18px"
                    h="18px"
                    borderRadius="50%"
                    backgroundRepeat="no-repeat"
                    backgroundSize="13px"
                    backgroundPosition="center"
                    backgroundColor="#ddded1"
                    backgroundImage={`url(${RewardIcon})`}
                    mr={2}
                  />
                </Box>

                <Text fontSize="12px">
                  Ganarás{' '}
                  <strong>
                    {formatCentsToEurNoFractionDigits(variable)} por cada 1.000 views extras{' '}
                  </strong>{' '}
                  que obtenga tu vídeo en las primeras 72 horas.
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Box mt={2}>
            <Text lineHeight={1.3} fontSize="12px">
              *Recompensa máxima: {formatCentsToEurNoFractionDigits(challenge.maxReward)}. Podrás
              ganar hasta {formatCentsToEurNoFractionDigits(challenge.maxReward)} con esta misión.*
            </Text>
          </Box>
          {bonus > 0 && (
            <>
              <Text fontWeight="bold" fontSize="16px" textAlign="center">
                ¿Cómo ganarás el bonus?
              </Text>
              <Flex mt={2} flexDir="column">
                <Box mb={2}>
                  <Flex>
                    <Box>
                      <Box
                        border="1px solid black"
                        boxShadow="0.5px 0.5px 0px 0px #000"
                        w="18px"
                        h="18px"
                        borderRadius="50%"
                        backgroundRepeat="no-repeat"
                        backgroundSize="13px"
                        backgroundPosition="center"
                        backgroundColor="#ddded1"
                        backgroundImage={`url(${RewardIcon})`}
                        mr={2}
                      />
                    </Box>

                    <Text fontSize="12px">
                      Ganarás el bonus de <strong>{formatCentsToEurNoFractionDigits(bonus)}</strong>{' '}
                      si subes el vídeo en las primeras 72 horas del reto y es aprobado.
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Flex flexDir="column" width="100%" fontFamily="DM Sans" p={4} bg="#fffff2">
      {/* Nueva caja informativa de recompensas */}
      <Box
        position="relative"
        bg="radial-gradient(50% 1097.43% at 50% 50.68%, #EBD0FF 0%, #E4E4D9 100%)"
        p={3}
        borderRadius="8px"
        onClick={handleOpenInfoRewardsModal}
        cursor="pointer"
      >
        <Box width="100%">
          <Text textAlign="center" fontSize="16px" fontWeight="bold">
            💸 Recompensas 💸
          </Text>
        </Box>
        <Flex mt={1} justifyContent="center">
          <Box mr={4} textAlign="center">
            {isMission ? (
              <Text fontSize="12px">Primeras 1.000 views</Text>
            ) : (
              <Text fontSize="12px">Fija (vídeo aprobado)</Text>
            )}
            <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
              {formatCentsToEurNoFractionDigits(fixed)}
            </Text>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
            <Text fontSize="20px" fontWeight="black">
              +
            </Text>
          </Box>
          <Box ml={4} mr={7} textAlign="center">
            {isMission ? (
              <Text fontSize="12px">x 1.000 views extra</Text>
            ) : (
              <Text fontSize="12px">Variable</Text>
            )}

            {isMission ? (
              <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                {hasAffiliation
                  ? 'x conversiones'
                  : `${formatCentsToEurNoFractionDigits(variable)}`}
              </Text>
            ) : (
              <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                {hasAffiliation
                  ? 'x conversiones'
                  : `${formatCentsToEurNoFractionDigits(variable)}/1000 views`}
              </Text>
            )}
          </Box>
        </Flex>
        {/* Si tiene bonus, lo mostramos */}
        {bonus > 0 && (
          <Flex
            width="100%"
            maxW="400px"
            margin="0 auto"
            minH="20px"
            fontWeight="bold"
            textAlign="center"
            mt={2}
            borderRadius="4PX"
            bg="#FFFFF2"
            fontSize="12px"
            alignItems="center"
            justifyContent="center"
          >
            +{formatCentsToEurNoFractionDigits(bonus)} extras subiéndolo en las primeras 72h{' '}
            <InfoOutlineIcon marginLeft="3px" height="10px" />
          </Flex>
        )}
        <Box
          opacity={0.8}
          top={2}
          right={2}
          position="absolute"
          px={4}
          py="1px"
          display="inline-block"
          borderRadius="4px"
          border="1px solid"
        >
          <Text fontSize="9px">+Info</Text>
        </Box>
      </Box>
      {/* Nuevo modal de explicación de recompensas */}
      <InfoRewardsModal isOpen={isOpenInfoRewardsModal} onClose={onCloseInfoRewardsModal}>
        {conversionModalContent}
      </InfoRewardsModal>
    </Flex>
  );
}
