function validateDNI(dni) {
  const letterMap = 'TRWAGMYFPDXBNJZSQVHLCKET';
  const number = parseInt(dni.slice(0, -1), 10);
  const expectedLetter = letterMap[number % 23];

  return expectedLetter === dni.slice(-1).toUpperCase();
}

function validateNIE(nie) {
  const letterMap = 'TRWAGMYFPDXBNJZSQVHLCKET';
  const niePrefix = { X: 0, Y: 1, Z: 2 };
  const number = parseInt(
    nie.replace(/[XYZ]/, (match) => niePrefix[match]),
    10,
  );
  const expectedLetter = letterMap[number % 23];

  return expectedLetter === nie.slice(-1).toUpperCase();
}

export default function validateSpanishID(id) {
  const dniRegex = /^\d{8}[a-zA-Z]$/;
  const nieRegex = /^[XYZ]\d{7}[a-zA-Z]$/;

  // Validate DNI
  if (dniRegex.test(id)) {
    return validateDNI(id);
  }

  // Validate NIE
  if (nieRegex.test(id)) {
    return validateNIE(id);
  }

  return false;
}
