/* eslint-disable no-console */

import { useNavigate } from 'react-router-dom';

import {
  Box,
  Text,
  Flex,
  Icon,
  Center,
  Button,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';

import { FaCheck } from 'react-icons/fa';
import { InfoOutlineIcon } from '@chakra-ui/icons';

function CustomCheckbox2({ isChecked, onChange }) {
  return (
    <Center
      p="1"
      h="20px"
      width="20px"
      bg={isChecked ? '#EBD0FF' : '#E4E4D9'}
      color={isChecked ? 'black' : ''}
      border="1px solid #000"
      boxShadow="0.5px 0.5px 0px 0px #000"
      rounded="full"
      zIndex={2}
      onClick={onChange}
      cursor="pointer"
    >
      {isChecked && <Icon boxSize="10px" as={FaCheck} />}
    </Center>
  );
}

export default function BriefingItemModalNextSteps({ collabData }) {
  const { _id, status, trackingLink = '' } = collabData;

  const navigate = useNavigate();

  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 8000,
    });
  };

  // Enviamos al usuario a la página de seguimiento que haya puesto la marca desde el panel
  const goToTrackingLinkPage = (link) => {
    if (link === '') {
      showToast('Aún no hay un enlace de seguimiento disponible', 'error');
    } else {
      window.open(link);
    }
  };

  // Enviamos al usuario al chat con la marca
  const goToCollabChat = (id) => {
    navigate(`${id}/chat`);
  };

  return (
    <Box mt={5} p="0px 16px 0px 16px" bg="#E4E4D9">
      {/* Próximos pasos */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          Tus siguientes pasos
        </Text>
        <Box fontSize="14px" borderRadius="24px" bg="white" p={4}>
          <Flex alignItems="center" position="relative" mb={3}>
            <Box>
              <CustomCheckbox2
                isChecked={
                  status === 'confirmed' ||
                  status === 'received' ||
                  status === 'produced' ||
                  status === 'approved' ||
                  status === 'claiming' ||
                  status === 'closed'
                }
              />
            </Box>
            <Text ml={2} fontSize="12px">
              Confirmar candidatura
            </Text>
          </Flex>
          <Flex alignItems="center" position="relative" mb={3}>
            <Box>
              <CustomCheckbox2
                isChecked={
                  status === 'received' ||
                  status === 'produced' ||
                  status === 'approved' ||
                  status === 'claiming' ||
                  status === 'closed'
                }
              />
            </Box>
            <Text ml={2} fontSize="12px">
              Recibir pedido
            </Text>
          </Flex>
          <Flex alignItems="center" position="relative" mb={3}>
            <Box>
              <CustomCheckbox2
                isChecked={
                  status === 'produced' ||
                  status === 'approved' ||
                  status === 'claiming' ||
                  status === 'closed'
                }
              />
            </Box>
            <Text ml={2} fontSize="12px">
              Producir y compartir los contenidos según briefing por WeTransfer
            </Text>
          </Flex>
          <Flex alignItems="center" position="relative" mb={3}>
            <Box>
              <CustomCheckbox2
                isChecked={status === 'approved' || status === 'claiming' || status === 'closed'}
              />
            </Box>
            <Box ml={2} fontSize="12px">
              Reclamar pago cuando la marca apruebe mi contenido{' '}
              <Popover>
                <PopoverTrigger>
                  <InfoOutlineIcon width="15px" />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader fontFamily="DM Sans" fontSize="12px" fontWeight="bold">
                    ¿Cómo y cuándo será el pago?
                  </PopoverHeader>
                  <PopoverBody>
                    <Text fontFamily="DM Sans" fontSize="12px">
                      Una vez que hayas compartido el contenido con la marca y esta lo apruebe,
                      podrás reclamar el pago pulsando en el botón &quot;Reclamar pago&quot; que te
                      aparecerá más abajo.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Flex>
        </Box>
      </Box>

      {/* Chat */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          Chat - ahora puedes hablar con la marca
        </Text>
        <Box
          fontSize="14px"
          borderRadius="24px"
          bg="linear-gradient(180deg, #FFFFF2 80.73%, #EBD0FF 100%)"
        >
          <Text p="16px 16px 8px 16px">
            Desde aquí podrás tener un contacto personal con la marca para cualquier duda sobre el
            briefing.
          </Text>
          <Button
            _hover={{
              backgroundColor: 'transparent',
            }}
            bg="transparent"
            width="full"
            fontSize="14px"
            onClick={() => goToCollabChat(_id)}
          >
            Abrir chat con la marca
          </Button>
        </Box>
      </Box>

      {/* Seguimiento del paquete */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          Seguimiento del paquete
        </Text>
        <Box
          fontSize="14px"
          borderRadius="24px"
          bg="linear-gradient(180deg, #FFFFF2 80.73%, #EBD0FF 100%)"
        >
          <Text p="16px 16px 8px 16px">
            Si la marca ha facilitado el link de seguimiento, podrás seguir el paquete desde aquí
          </Text>
          <Button
            onClick={() => goToTrackingLinkPage(trackingLink)}
            bg="transparent"
            width="full"
            fontSize="14px"
            _hover={{
              backgroundColor: 'transparent',
            }}
          >
            Ver seguimiento del paquete
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
