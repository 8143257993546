import { Box, Heading, Text, Button } from '@chakra-ui/react';

export default function UserHistoryCard({ onOpenHistoryDrawer }) {
  return (
    <Box
      minWidth="300px"
      maxWidth="300px"
      bg="#fffff2"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      border="2px solid black"
      p={3}
      mr={3}
    >
      <Box>
        <Heading fontSize="16px">Historial</Heading>
        <Text fontSize="12px" lineHeight={1.2} mt={2} mb={5}>
          Pulsa en Ver Historial para ver todos los retos que has completado con éxito y todas las
          marcas con las que has colaborado.
        </Text>
      </Box>

      <Button
        height="38px"
        fontSize="14px"
        _hover={{ bg: '#FFFFF2' }}
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        borderRadius="16px"
        bg="#FFFFF2"
        color="black"
        width="100%"
        size="md"
        onClick={onOpenHistoryDrawer}
      >
        Ver historial
      </Button>
    </Box>
  );
}
