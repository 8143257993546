/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */

import React from 'react';

import { Box, Heading, Text } from '@chakra-ui/react';

import ReferralCard from './ReferralCard/ReferralCard';
import DummyReferralCard from './DummyReferralCard/DummyReferralCard';
import FirstChallengeRewardCard from './ConnectTikTokCard/FirstChallengeRewardCard';

export default function StartEarning({ userData }) {
  const canRefer = userData?.hasOwnProperty('referralCode');
  const hasApprovedChallenges = userData?.challenges.some((challenge) =>
    ['approved', 'claimed'].includes(challenge.status),
  );

  // Solo para usuarios a partir del 1 de abril
  const createdAtDate = new Date(userData?.createdAt);
  const currentYear = new Date().getFullYear();
  const aprilFirstThisYear = new Date(currentYear, 3, 1); // April is month 3 in JavaScript Date (0-indexed)
  const createdAfterAprilFirst = createdAtDate >= aprilFirstThisYear;

  return (
    <Box maxWidth="700px" margin="0 auto">
      <Box mt={5}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Gana recomendando
        </Heading>
        <Text fontStyle="italic" opacity="0.8" fontSize="12px">
          Gana recomendando CRUWI a tus seguidores y amigxs
        </Text>
      </Box>

      <Box display="flex" mt={5} overflowX="auto" pb={7}>
        {!userData?.hasFirstChallengeReward && createdAfterAprilFirst && (
          <FirstChallengeRewardCard isDisabled={!hasApprovedChallenges} />
        )}
        {canRefer ? <ReferralCard userData={userData} /> : <DummyReferralCard />}
      </Box>
    </Box>
  );
}
