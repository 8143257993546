import { Flex, Text } from '@chakra-ui/react';

export default function LinkItemTips() {
  return (
    <Flex mt={5} mb={5} justifyContent="center">
      <Text
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({
            top: document.querySelector('#share-advices').offsetTop,
            behavior: 'smooth',
          });
        }}
        textDecor="underline"
        fontSize="10px"
        cursor="pointer"
      >
        Consejos para que más gente use tu link
      </Text>
    </Flex>
  );
}
