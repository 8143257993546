import React from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Flex, Text, Image, useMediaQuery, Icon } from '@chakra-ui/react';

import { BsFillBookmarkStarFill } from "react-icons/bs";

import CustomLink from '../CustomLink/CustomLink';

import HomeIcon from '../../Assets/Images/fluent_home-20-filled.svg';
import UGCProfileIcon from '../../Assets/Images/iconamoon_profile-fill.svg';

export default function FooterNav() {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const location = useLocation();
  const isHomePage = location.pathname === '/challenges' || location.pathname === '/';
  const isProfilePage = location.pathname === '/profile';
  const isMissionPage = location.pathname === '/missions';

  return (
    <Flex
      fontFamily="DM Sans"
      maxWidth="700px"
      margin="0 auto"
      boxShadow="0 -3px 10px 1px #dacfcf78"
      bg="#FFFFF2"
      width="100%"
      left="50%"
      transform="translateX(-50%)"
      position="fixed"
      bottom={0}
      px={5}
      height="50px"
      borderRadius={isLargerThan768 ? '24px 24px 0px 0px' : ''}
    >
      <Box display="flex" alignItems="center" justifyContent="left" width="100%">
        <CustomLink to="/challenges">
          <Box
            border="2px solid black"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100px"
            padding="6px"
            bg={isHomePage ? '#EBD0FF' : 'transparent'}
            boxShadow="1px 1px 0px 0px #000"
          >
            <Image mr={1} boxSize="16px" objectFit="cover" src={HomeIcon} alt="Home Icon" />
            <Text fontWeight="bold" fontSize="14px">
              Retos
            </Text>
          </Box>
        </CustomLink>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CustomLink to="/missions">
          <Box
            border="2px solid black"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100px"
            padding="6px"
            bg={isMissionPage ? '#EBD0FF' : 'transparent'}
            boxShadow="1px 1px 0px 0px #000"
          >
            <Icon mr={1} fontSize="16px" as={BsFillBookmarkStarFill} />
            <Text fontWeight="bold" fontSize="14px">
              Misiones
            </Text>
          </Box>
        </CustomLink>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="right" width="100%">
        <CustomLink to="/profile">
          <Box
            border="2px solid black"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100px"
            padding="6px"
            bg={isProfilePage ? '#EBD0FF' : 'transparent'}
            boxShadow="1px 1px 0px 0px #000"
          >
            <Image
              mr={1}
              boxSize="20px"
              objectFit="cover"
              src={UGCProfileIcon}
              alt="Profile UGC Icon"
            />
            <Text fontWeight="bold" fontSize="14px">
              Perfil
            </Text>
          </Box>
        </CustomLink>
      </Box>
    </Flex>
  );
}
