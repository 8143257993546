/* eslint-disable no-alert */
/* eslint-disable no-console */

import React, { useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';

import { useRequestChangePasswordMutation } from '../../Redux/features/auth/authApiSlice';

export default function PasswordRecoveryModal({ isOpen, onClose }) {

  // Toast
  const toast = useToast();

  // Show toast and type
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Redux
  const [requestChangePassword, { isLoading }] = useRequestChangePasswordMutation();

  // Email
  const [email, setEmail] = useState('');
  const handleChangeEmail = (event) => setEmail(event.target.value);

  // Enviamos form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length === 0) {
      alert('El correo no puede estar vacío');
      return;
    }

    const userData = {
      email: email.toLowerCase(),
    };

    try {
      await requestChangePassword(userData).unwrap();
      showToast('Sigue las instrucciones que te hemos enviado al correo', 'success');
      setEmail('');
      onClose();
    } catch (error) {
      showToast('Hubo un problema solicitando el cambio de contraseña', 'error');
      console.log(error);
    }

  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="16px">Restablecer contraseña</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4} color="black" fontSize="14px" textAlign="left" fontFamily="DM Sans">
            Te enviaremos las instrucciones para restablecer la contraseña al correo que introduzcas.
          </Text>
          <Input
            value={email}
            onChange={handleChangeEmail}
            placeholder="Email"
            fontFamily="DM Sans"
            bg="gray.100"
            border={0}
            olor="gray.900"
            _placeholder={{
              color: 'gray.500',
            }}
          />
          <Button
            isLoading={isLoading}
            onClick={handleSubmit}
            fontFamily="DM Sans"
            mt={4}
            bg="#000000"
            w="full"
            color="white"
            _hover={{
              bg: '#000000',
            }}
            mb={8}
          >
            Recuperar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
