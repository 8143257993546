import './CustomChip2.css';

function CustomChip2({ label, isSelected, onSelected }) {
  return (
    <div
      className={`custom-chip2 ${isSelected ? 'selected' : ''}`}
      onClick={() => onSelected(label)}
    >
      {label}
    </div>
  );
}

export default CustomChip2;
