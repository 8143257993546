import React from 'react';
import './CustomChip.css';

function CustomChip({ label, isSelected, onSelected }) {
  return (
    <div
      className={`custom-chip ${isSelected ? 'selected' : ''}`}
      onClick={() => onSelected(label)}
    >
      {label}
    </div>
  );
}

export default CustomChip;
