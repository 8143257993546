/* eslint-disable no-console */

import React, { useState } from 'react';

import {
  Box,
  useToast,
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import { BiLockAlt } from 'react-icons/bi';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import formatCentsToEurs from '../../Utils/formatCentsToEurs';
import formatCentsToEur from '../../Utils/formatCentsToEur';

import KYCModal from '../KYCModal/KYCModal';
import PaymentInfoModal from '../PaymentInfoModal/PaymentInfoModal';

import { useUnlockBalanceMutation } from '../../Redux/features/challenges/challengesApiSlice';

function hasPassed30Days(createTime) {
  const createDate = new Date(createTime);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - createDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays > 30;
}

function truncateTitle(title, maxLength = 20) {
  return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
}

function combineChallengesAndPendingBalance(pendingBalance, challenges) {
  return pendingBalance
    .map((pending) => {
      const matchingChallenge = challenges.find((challenge) => challenge._id === pending.challenge);

      if (matchingChallenge) {
        return {
          challenge: matchingChallenge,
          pendingData: pending,
        };
      }

      return null;
    })
    .filter(Boolean); // Remove any null entries if no match was found
}

export default function UserBalance({ userData }) {
  const { balance, taxData, pendingBalance, challenges } = userData;

  // Toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const [unlockBalance, { isLoading: isLoadingUnlockBalance }] = useUnlockBalanceMutation();

  // Abre modal de datos fiscales
  const {
    isOpen: isOpenTaxModal,
    onOpen: onOpenTaxModal,
    onClose: onCloseTaxModal,
  } = useDisclosure();

  // Abre modal información del % gasto de gestión
  const {
    isOpen: isOpenPaymentInfoModal,
    onOpen: onOpenPaymentInfoModal,
    onClose: onClosePaymentInfoModal,
  } = useDisclosure();

  // Si ya tiene los datos, no mostramos la parte de revisión de los datos.
  // mostramos la 2º parte del modal sin esa parte
  const showEditData = Boolean(taxData?.dni);

  // Paso del form de datos fiscales
  const [step, setStep] = useState(2);

  // Texto de saldo
  const getBalanceText = (userBalance) => {
    let text = '';
    if (20 - userBalance / 100 < 0) {
      text = '¡Ya puedes reclamar tu saldo!';
    } else {
      text = `faltan ${20 - userBalance / 100}€ para reclamar tu saldo`;
    }
    return text;
  };

  // Comprueba si el usuario puede retirar saldo
  const isWithdrawable = balance >= 2000;

  // Acción de retirar saldo o abrir modal de datos fiscales si no está rellenos los datos
  const showRequiredStepTaxModal = () => {
    if (!taxData?.dni) {
      setStep(0);
      onOpenTaxModal();
    } else {
      setStep(1);
      onOpenTaxModal();
    }
  };

  // Obtenemos un array formado con lo pendiente y el reto en sí con todos los datos
  const pendingBalanceData = combineChallengesAndPendingBalance(pendingBalance, challenges);

  const handleClickUnlockBalance = async (balanceId) => {
    try {
      const unlockBalanceResult = await unlockBalance(balanceId).unwrap();
      console.log(unlockBalanceResult);
      showToast('¡Se ha añadido el importe a tu saldo disponible!', 'success');
    } catch (error) {
      showToast('Hubo un problema solicitando el saldo, contacta con hello@cruwi.com', 'error');
      console.log(error);
    }
  };

  return (
    <Box
      maxWidth="700px"
      margin="0 auto"
      p={3}
      bg="#fff"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      mt={5}
      width="100%"
    >
      <Box>
        <Flex alignItems="flex-end">
          <Text fontFamily="DM Sans" fontWeight="bold" fontSize="16px">
            Saldo disponible
          </Text>
          <Text
            fontFamily="DM Sans"
            marginLeft="5px"
            lineHeight={1.7}
            fontSize="12px"
            opacity={0.85}
          >
            ({getBalanceText(balance)})
          </Text>
        </Flex>
        <Flex textAlign="center" fontFamily="DM Sans" flexDirection="column">
          <Text fontFamily="DM Sans" fontWeight="extrabold" fontSize="50px">
            {formatCentsToEurs(balance)}
          </Text>
        </Flex>
      </Box>
      <Flex alignItems="center">
        <Text
          display="flex"
          alignItems="center"
          onClick={onOpenPaymentInfoModal}
          fontFamily="DM Sans"
          fontSize="12px"
          opacity={0.85}
        >
          Retención del 20% al retirar <InfoOutlineIcon marginLeft="5px" mt="0px" height="12px" />
        </Text>
        <Spacer />
        {isWithdrawable ? (
          <Button
            className="button-pulse"
            _hover={{ bg: '#000000' }}
            paddingRight="12px"
            paddingLeft="12px"
            bg="#000000"
            color="white"
            variant="solid"
            size="xs"
            onClick={() => showRequiredStepTaxModal()}
          >
            Retirar {formatCentsToEurs(balance)}
          </Button>
        ) : (
          <Tooltip label="Debes llegar a 20€">
            <Button disabled="true" variant="solid" size="xs">
              Retirar &nbsp; <BiLockAlt />
            </Button>
          </Tooltip>
        )}
      </Flex>

      {pendingBalanceData.length > 0 && (
        <Box fontFamily="DM Sans">
          <Divider mt="12px" borderWidth="1px" borderColor="black" opacity={1} />
          <Box mt="12px">
            <Text mb="12px" fontFamily="DM Sans" fontWeight="bold" fontSize="16px">
              Saldos pendientes
            </Text>

            {pendingBalanceData.map((b) => {
              const is30DaysPassed = hasPassed30Days(b.challenge.video.createTime);
              const truncatedTitle = truncateTitle(b.challenge.challenge.title);
              return (
                <Flex key={b.challenge._id} flexDir="column" mb="12px">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                      <Box>
                        <Box
                          mr={2}
                          height="50px"
                          w="50px"
                          border="1px solid black"
                          borderRadius="50%"
                          backgroundRepeat="no-repeat"
                          backgroundSize="contain"
                          backgroundPosition="center"
                          backgroundImage={`url('${b.challenge.challenge.brand.logoUrl}')`}
                        />
                      </Box>
                      <Flex flexDir="column">
                        <Text fontSize="16px" fontWeight="bold">
                          {truncatedTitle}
                        </Text>
                        <Text fontSize="14px">
                          {is30DaysPassed
                            ? '¡Ya puedes añadirlo al saldo!'
                            : `Faltan ${30 - Math.ceil((new Date() - new Date(b.challenge.video.createTime)) / (1000 * 60 * 60 * 24))} días para añadirlo al saldo`}
                        </Text>
                      </Flex>
                    </Flex>

                    <Box>
                      {is30DaysPassed ? (
                        <Button
                          className="button-pulse"
                          _hover={{ bg: '#000000' }}
                          paddingRight="12px"
                          paddingLeft="12px"
                          bg="#000000"
                          color="white"
                          variant="solid"
                          size="xs"
                          w="60px"
                          onClick={() => handleClickUnlockBalance(b.pendingData._id)}
                          isLoading={isLoadingUnlockBalance}
                        >
                          +{formatCentsToEur(b.pendingData.reward)}
                        </Button>
                      ) : (
                        <Tooltip label="Debes esperar 30 días desde la publicación">
                          <Button disabled variant="solid" size="xs" w="60px">
                            +{formatCentsToEur(b.pendingData.reward)} 🔒
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </Box>
      )}

      <PaymentInfoModal isOpen={isOpenPaymentInfoModal} close={onClosePaymentInfoModal} />

      <KYCModal
        step={step}
        showEditData={showEditData}
        setStep={setStep}
        isOpen={isOpenTaxModal}
        close={onCloseTaxModal}
        balance={balance}
      />
    </Box>
  );
}
