/* eslint-disable no-console */

import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useMediaQuery,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';

export default function PaymentInfoModal({ isOpen, close }) {
  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <Modal size={isLargerThan768 ? 'lg' : 'lg'} isOpen={isOpen} onClose={close} isCentered>
      <ModalOverlay />
      <ModalContent
        border="2px solid #EBD0FF"
        borderRadius="24px"
        background="#FFFFF2"
        m={5}
        maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
      >
        <ModalCloseButton />
        <ModalBody padding={0}>
          <Flex direction="column" p={5}>
            <Box flex="1">
              <Text mb={3} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
                Retención del 20%
              </Text>
              <Box>
                <Text fontSize={16} fontFamily="DM Sans" mb={3}>
                  Al retirar el saldo disponible de tu cuenta, se aplicará una retención del 20% que
                  se compone de:
                </Text>
                <UnorderedList fontSize={16} fontFamily="DM Sans">
                  <ListItem>15% en concepto de retenciones IRPF</ListItem>
                  <ListItem>5% en concepto de gastos de gestión</ListItem>
                </UnorderedList>
              </Box>
            </Box>

            <Box marginTop="50px">
              <Button
                onClick={() => console.log('hello')}
                _hover={{ bg: '#000000' }}
                fontFamily="DM Sans"
                paddingRight="12px"
                paddingLeft="12px"
                border="2px solid black"
                boxShadow="1px 1px 0px 0px #000"
                borderRadius="16px"
                bg="#FFFFF2"
                color="black"
                width="100%"
                size="md"
              >
                Cerrar
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
