import React from 'react';

import {
  Box,
  Text,
} from '@chakra-ui/react';

export default function ReferralNoFriends() {
  return (
    <Box>
      <Text fontSize="12px">Aún no has invitado ningún amigo :(</Text>
    </Box>
  )
}