/* eslint-disable no-console */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  Flex,
  Input,
  useMediaQuery,
  useToast,
  Button,
} from '@chakra-ui/react';

import { ArrowBackIcon } from '@chakra-ui/icons';

import StepIndicator from '../StepIndicator/StepIndicator';
import CustomChip from '../CustomChip/CustomChip';

import { setUserData } from '../../Redux/features/auth/authSlice';
import { useUpdateMutation } from '../../Redux/features/auth/authApiSlice';

const mapSelectedCategoriesToEnglish = (selectedCategories, categoriesMap) =>
  selectedCategories.map((category) => categoriesMap[category] || category);

export default function CreatorInfoModal({ isOpen, close }) {
  // Paso del form
  const [step, setStep] = useState(1);

  // Toast and show toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Step 1 validation
  const [isValidStep1, setIsValidStep1] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const [update, { isLoading }] = useUpdateMutation();

  // Camnbio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Sexo
  const [selectedSex, setSelectedSex] = useState('');
  const handleSelectSex = (label) => {
    setSelectedSex(label);
  };

  // Fecha de nacimiento
  const [userBirthDate, setUserBirthDate] = useState(null);
  const handleChangeUserBirthDate = (e) => {
    setUserBirthDate(new Date(e.target.value));
  };

  // Tipo de contenido
  const contentCategoriesMap = {
    Mascotas: 'Pets & Animals',
    Deporte: 'Sports & Fitness',
    Alimentación: 'Food & Beverage',
    Moda: 'Apparel & Accessories',
    'Belleza y cuidado personal': 'Beauty & Personal Care',
    Lifestyle: 'Lifestyle',
    Viajes: 'Travel',
    Bebés: 'Babies & Kids',
    Música: 'Music',
    Gaming: 'Gaming',
    Libros: 'Books',
    Cine: 'Movies',
    'No lo tengo claro': 'Not Sure',
  };
  const options = Object.keys(contentCategoriesMap);

  useEffect(() => {
    if (selectedSex && userBirthDate) {
      setIsValidStep1(true);
    }
  }, [selectedSex, userBirthDate]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const handleSelectCategory = (label) => {
    if (selectedCategory.includes(label)) {
      setSelectedCategory((prevOptions) => prevOptions.filter((option) => option !== label));
    } else if (selectedCategory.length < 3) {
      setSelectedCategory((prevOptions) => [...prevOptions, label]);
    }
  };

  // Update del user
  const handleUpdateData = async () => {
    const englishSelectedCategories = mapSelectedCategoriesToEnglish(
      selectedCategory,
      contentCategoriesMap,
    );

    const updatedUserData = {
      gender: selectedSex === 'Hombre' ? 'male' : 'female',
      birthDate: userBirthDate ? userBirthDate.toISOString() : null,
      categories: englishSelectedCategories,
    };

    if (englishSelectedCategories.length === 0) {
      showToast('Debes seleccionar al menos una temática', 'error');
      return;
    }

    try {
      const userUpdatedData = await update(updatedUserData).unwrap();
      showToast('Tu perfil ha sido actualizado', 'success');
      dispatch(setUserData(userUpdatedData));
    } catch (error) {
      showToast('Hubo un problema actualizando los datos de tu perfil', 'error');
      console.log(error);
    }
  };

  // Paso 1: Sexo y fecha de nacimiento
  const Step1 = (
    <Flex direction="column" p={5} minHeight="540px">
      <Box flex="1">
        <Box width="100%" mx="auto">
          <StepIndicator
            currentStep={step}
            steps={['0', '1']}
            setStep={setStep}
            isValidStep1={isValidStep1}
          />
        </Box>

        <Text mb={2} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
          Queremos conocerte mejor
        </Text>

        <Text lineHeight={1.3} mb={6}>
          Para poder ofrecerte más y mejores retos, necesitamos que compartas con nosotros algunos
          datos y preferencias.
        </Text>

        <Text fontWeight="bold" lineHeight={1.1} mb={1}>
          Sexo
        </Text>

        <Box>
          <CustomChip
            label="Mujer"
            isSelected={selectedSex === 'Mujer'}
            onSelected={handleSelectSex}
          />
          <CustomChip
            label="Hombre"
            isSelected={selectedSex === 'Hombre'}
            onSelected={handleSelectSex}
          />
        </Box>

        <Box mt={5}>
          <Text fontWeight="bold">Fecha de nacimiento</Text>
          <Input
            value={userBirthDate ? userBirthDate.toISOString().split('T')[0] : ''}
            onChange={handleChangeUserBirthDate}
            borderRadius={4}
            size="md"
            type="date"
          />
        </Box>
      </Box>

      <Box marginTop="50px">
        <Button
          onClick={() => setStep(2)}
          width="100%"
          _hover={{ bg: '#000000' }}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          bg="#000000"
          color="white"
          variant="solid"
          size="md"
          isDisabled={!isValidStep1}
        >
          Siguiente
        </Button>
      </Box>
    </Flex>
  );

  // Paso 2: Tipo de contenido
  const Step2 = (
    <Flex direction="column" p={5} minHeight="540px">
      <Box flex="1">
        <Box width="100%" mx="auto">
          <StepIndicator
            currentStep={step}
            steps={['0', '1']}
            setStep={setStep}
            isValidStep1={isValidStep1}
          />
        </Box>

        <Text mb={2} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
          Y por último
        </Text>

        <Text fontSize="16px" lineHeight={1.3} mb={5}>
          Tenemos marcas de muchos sectores y nos gustaría conocer qué tipo de contenido te gusta
          más grabar.
        </Text>

        <Text fontWeight="bold" lineHeight={1.1}>
          ¿De qué te gusta crear contenido?
        </Text>
        <Text fontSize="12px" mb={2}>
          Elige máximo 3 opciones
        </Text>
        <Box mt={4}>
          {options.map((option) => (
            <CustomChip
              key={option}
              label={option}
              isSelected={selectedCategory.includes(option)}
              onSelected={handleSelectCategory}
            />
          ))}
        </Box>
      </Box>

      <Flex marginTop="50px">
        <Button
          onClick={() => setStep(1)}
          width="200px"
          mr={5}
          _hover={{ bg: 'white' }}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          bg="white"
          color="black"
          variant="solid"
          size="md"
          isLoading={isLoading}
          leftIcon={<ArrowBackIcon />}
        >
          Anterior
        </Button>
        <Button
          onClick={() => handleUpdateData()}
          width="100%"
          _hover={{ bg: '#000000' }}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          bg="#000000"
          color="white"
          variant="solid"
          size="md"
          isLoading={isLoading}
        >
          Finalizar
        </Button>
      </Flex>
    </Flex>
  );

  // Renderizado de los pasos
  const renderStep = () => {
    if (step === 1) return Step1;
    if (step === 2) return Step2;
    return null;
  };

  return (
    <Modal
      size={isLargerThan768 ? 'lg' : 'lg'}
      isOpen={isOpen}
      onClose={close}
      isCentered
      scrollBehavior={isLargerThan768 ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent m={5} maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}>
        <ModalBody padding={0}>{renderStep()}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
