import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentToken, selectCurrentUser } from '../Redux/features/auth/authSlice';

export default function ProtectedRoute({ children, redirectTo = 'register' }) {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  if (!token || !user) {
    return <Navigate to={`/${redirectTo}`} replace />;
  }

  return children;
}
