/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */

import React from 'react';

import { Box, Heading, Text } from '@chakra-ui/react';
import ConnectPayPalButton from '../ConnectPayPalButton/ConnectPayPalButton';

export default function PaymentMethods({ userData }) {
  const { payPalInfo } = userData;
  const hasPayPalConnected = payPalInfo?.accessToken !== undefined;
  return (
    <Box maxWidth="700px" margin="0 auto">
      <Box mt={5}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Métodos de retirada
        </Heading>
        <Text fontStyle="italic" opacity="0.8" fontSize="12px">
          Configura tu metodo de retirada
        </Text>
      </Box>
      <Box display="flex" mt={5} overflowX="auto" pb={7}>
        <ConnectPayPalButton hasPayPalConnected={hasPayPalConnected} />
      </Box>
    </Box>
  );
}
