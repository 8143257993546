import { createSlice } from '@reduxjs/toolkit';

// Get user from localstorage
const user = JSON.parse(localStorage.getItem('user'));

// Get token from localstorage
const token = JSON.parse(localStorage.getItem('token'));

const initialState = {
  user: user || null,
  token: token || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user: newUser, token: newToken } = action.payload;
      state.user = newUser;
      state.token = newToken;
      localStorage.setItem('user', JSON.stringify(newUser));
      localStorage.setItem('token', JSON.stringify(newToken));
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;

      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
    setUserData: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
  },
});

export const { setCredentials, logOut, setUserData } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
