export default function calculateDifficulty(reward) {
  const { fixed, variable } = reward;
  const result = fixed + variable;

  let rewardLevel = '💸';

  if (result <= 500) {
    rewardLevel = '💸';
  } else if (result > 500 && result <= 1200) {
    rewardLevel = '💸 💸';
  } else {
    rewardLevel = '💸 💸 💸';
  }

  return rewardLevel;
}
