/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */

import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import { useGetUserDataQuery } from '../Redux/features/auth/authApiSlice';

import LoadingChallenges from '../Components/LoadingChallenges/LoadingChallenges';
import ScrollToTop from '../Components/ScrollToTop/ScrollToTop';
import Header from '../Components/Header/Header';
import ChallengeItem from '../Components/ChallengeItem/ChallengeItem';
import FooterNav from '../Components/FooterNav/FooterNav';
import NoChallengesCompleted from '../Components/NoChallengesCompleted/NoChallengesCompleted';

// Milisegundos que solicitamos los datos del usuario
const POLLING_INTERVAL = 30000;

export default function HistoryPage() {
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetUserDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  // Mostramos solo los retos completados
  const shownChallenges = user?.challenges?.filter((challenge) => {
    if (challenge.status === 'claimed') return true;
    return false;
  });

  // Mostramos los últimos retos completados los primeros
  const orderedData = shownChallenges?.slice().sort((a, b) => {
    const dateA = new Date(a.challenge.createdAt);
    const dateB = new Date(b.challenge.createdAt);
    return dateB - dateA;
  });

  // Mostramos algo mientras carga
  if (isUserLoading) {
    return <LoadingChallenges />;
  }

  return (
    <Box bg="#FFFFF2" minH="100dvh">
      <ScrollToTop />
      <Header user={user} />
      <Box pb="60px" mt={5} ml={5} mr={5}>
        {/* Mis retos */}
        <Box mb={8} maxWidth="700px" margin="0 auto">
          <Flex justifyContent="space-between">
            <Heading fontSize="24px" fontFamily="DM Sans">
              Retos completados
            </Heading>
          </Flex>
          <Text
            fontFamily="DM Sans"
            fontStyle="italic"
            opacity="0.8"
            lineHeight={1.2}
            fontSize="12px"
          >
            Retos que has completado
          </Text>

          {/* Listado de retos en los que participas */}
          <Box mt={5}>
            {isUserSuccess &&
              orderedData.map((challenge) => (
                <ChallengeItem
                  status={challenge?.status}
                  challengeData={challenge.challenge}
                  key={challenge._id}
                  userData={user}
                  videoCreateTime={new Date(challenge?.video?.createTime)}
                />
              ))}
            {isUserSuccess && shownChallenges.length === 0 && <NoChallengesCompleted />}
          </Box>
        </Box>
      </Box>
      <FooterNav />
    </Box>
  );
}
