/* eslint-disable no-console */

import React from 'react';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  Flex,
} from '@chakra-ui/react';

import { FiX } from 'react-icons/fi';

import { useMarkNotificationsAsReadMutation } from '../../Redux/features/notifications/notificationsApiSlice';

export default function NotificationsDrawer({ isOpen, onClose, notifications, isUGC }) {
  const [markAsRead] = useMarkNotificationsAsReadMutation();

  const sortedNotifications = notifications.slice().sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  const handleMarkAsReadAndClose = async () => {
    const unreadNotificationIds = notifications
      .filter((notification) => !notification.read)
      .map((notification) => notification._id);

    const notificationData = {
      ids: unreadNotificationIds,
    };

    try {
      const notificationsResult = await markAsRead(notificationData).unwrap();
      onClose();
      console.log(notificationsResult);
    } catch (error) {
      onClose();
      console.log(error);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleMarkAsReadAndClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          bg={isUGC ? '#ddded1' : '#FFFFF2'}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="55px"
          borderBottomWidth="1px"
        >
          <Text>Notificaciones</Text>
          <Box>
            <FiX cursor="pointer" size="20px" onClick={handleMarkAsReadAndClose} />
          </Box>
        </DrawerHeader>

        <DrawerBody bg={isUGC ? '#ddded1' : '#FFFFF2'} padding={5}>
          <Box>
            {sortedNotifications.map((notification) => (
              <Flex
                border="1px solid"
                mb={3}
                key={notification._id}
                alignItems="center"
                p={5}
                bg={notification.read ? 'white' : '#FFFFF2'}
                borderRadius="24px"
                boxShadow="1px 1px 0px 0px #000"
              >
                <Box
                  minHeight={30}
                  minWidth={30}
                  marginRight="10px"
                  bgImage={notification.brandLogoSrc}
                  bgSize="contain"
                  bgRepeat="no-repeat"
                  alt="Challenge Icon"
                  borderRadius="50%"
                />
                <Text fontSize="12px">{notification.content}</Text>
              </Flex>
            ))}
            {sortedNotifications.length === 0 && (
              <Box p={5} textAlign="center">
                <Text fontStyle="italic" fontSize={12}>
                  No tienes notificaciones
                </Text>
              </Box>
            )}
          </Box>
        </DrawerBody>

        <DrawerFooter bg={isUGC ? '#ddded1' : '#FFFFF2'} padding={2} borderTopWidth="1px">
          {isUGC ? (
            <Button
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              size="sm"
              onClick={handleMarkAsReadAndClose}
            >
              Cerrar
            </Button>
          ) : (
            <Button
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#FFFFF2"
              color="black"
              size="sm"
              onClick={handleMarkAsReadAndClose}
            >
              Cerrar
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
