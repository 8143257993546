/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

import ReactPlayer from 'react-player';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Box,
  Button,
  ModalFooter,
  useMediaQuery,
} from '@chakra-ui/react';

import { FiX } from 'react-icons/fi';

import './VideoModal.css';

export default function VideoModal({ brandName, videoSrc, isOpen, close, hasControl }) {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      isCentered
      scrollBehavior={isLargerThan768 ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        border="2px solid #EBD0FF"
        borderRadius="24px"
        maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
        m={5}
      >
        <ModalHeader
          borderTopLeftRadius="24px"
          borderTopRightRadius="24px"
          bg="#feffef"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottomWidth="1px"
        >
          <Text fontSize="16px">Vídeo Ejemplo {brandName}</Text>
          <Box cursor="pointer">
            <FiX size="20px" onClick={close} />
          </Box>
        </ModalHeader>
        <ModalBody padding={0}>
          <div className="react-player-view-example">
            <ReactPlayer
              controls={hasControl}
              url={videoSrc}
              width="100%"
              height={isLargerThan768 ? '100%' : '476px'}
              playing={isOpen}
            />
          </div>
        </ModalBody>
        <ModalFooter borderBottomLeftRadius="24px" borderBottomRightRadius="24px" bg="#feffef">
          <Button size="sm" variant="outline" onClick={close}>
            Cerrar ejemplo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
