/* eslint-disable no-console */

import { Box } from '@chakra-ui/react';

import LinkItemHeader from './LinkItemHeader';
import LinkItemShare from './LinkItemShare';
import LinkItemInAppText from './LinkItemInAppText';
import LinkItemRewards from './LinkItemRewards';
import LinkItemTips from './LinkItemTips';
import LinkItemBalance from './LinkItemBalance';

export default function LinkItem({ challenge, affiliation }) {
  const isLinkAffiliation = challenge.affiliation.type === 'link';
  return (
    <Box
      p={4}
      borderRadius="24px"
      bg="#FFFFF2"
      mb={5}
      minH="300px"
      w="100%"
      border="2px solid #EBD0FF"
      mr={[0, 0, 0, 0, 3]}
    >
      {/* Header */}
      <LinkItemHeader challenge={challenge} />

      {/* Compartición o texto fijo de in-app */}
      {isLinkAffiliation ? (
        <LinkItemShare affiliation={affiliation} challenge={challenge} />
      ) : (
        <LinkItemInAppText challenge={challenge} />
      )}

      {/* Recompensas */}
      <LinkItemRewards challenge={challenge} />

      {/* Consejos */}
      <LinkItemTips />

      {/* Balance */}
      <LinkItemBalance affiliation={affiliation} />
    </Box>
  );
}
