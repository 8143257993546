/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Box,
  Flex,
  useMediaQuery,
  Image,
  Button,
  Stack,
  VStack,
  Circle,
} from '@chakra-ui/react';

import { FiX } from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';

import { useLazySendToFacebookAuthQuery } from '../../Redux/features/auth/authApiSlice';

import FollowersIcon from '../../Assets/Images/followers-icon.svg';
import VideosIcon from '../../Assets/Images/videos_icon.svg';

import './ConnectInstagramModal.css';

export default function ConnectInstagramModal({
  connectionError,
  isOpenConnectFacebookModal,
  onCloseConnectFacebookModal,
  handleClickCloseConnectFacebookModalAndClearParams,
}) {
  const [sendToFacebook] = useLazySendToFacebookAuthQuery();

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const handleClickConnectFacebook = async () => {
    try {
      const facebookApiResult = await sendToFacebook();
      console.log(JSON.stringify(facebookApiResult));
      if (facebookApiResult.isSuccess) {
        window.location.replace(facebookApiResult.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const InfoContent = (
    <Flex p={5} flexDir="column" justifyContent="space-between">
      {/* Textos y condiciones */}
      <Box>
        {/* Intro */}
        <Text mb={0} fontSize={24} fontFamily="DM Sans" fontWeight="bold">
          Conecta tu Instagram
        </Text>
        <Text fontFamily="DM Sans" mb={6}>
          para colaborar con marcas en Instagram
        </Text>

        {/* Requisitos Intro */}
        <Text fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={2}>
          Requisitos
        </Text>

        {/* Requisitos */}
        <Flex mb={6}>
          <Box
            fontFamily="DM Sans"
            bg="#fff"
            width="100%"
            borderStyle="dashed"
            borderColor="#000000"
            borderWidth="1px"
            borderRadius={6}
            display="flex"
            alignItems="center"
            p={2}
            mr={2}
          >
            <Box mr={2} minH="40px" minW="40px">
              <Image
                boxSize="40px"
                objectFit="cover"
                src={FollowersIcon}
                alt="min followers criteria"
              />
            </Box>
            <Box>
              <Text lineHeight="1.3" fontWeight="semibold" fontFamily="DM Sans">
                +1000 <br /> seguidores
              </Text>
            </Box>
          </Box>
          <Box
            fontFamily="DM Sans"
            bg="#fff"
            width="100%"
            borderStyle="dashed"
            borderColor="#000000"
            borderWidth="1px"
            borderRadius={6}
            display="flex"
            alignItems="center"
            p={2}
          >
            <Box mr={2} w="40px" h="40px">
              <Image boxSize="40px" objectFit="cover" src={VideosIcon} alt="min videos criteria" />
            </Box>
            <Box>
              <Text lineHeight="1.3" fontWeight="semibold" fontFamily="DM Sans">
                +5 <br /> contenidos
              </Text>
            </Box>
          </Box>
        </Flex>

        {/* Texto 2 */}
        <Text lineHeight={1.3} fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={4}>
          Asegúrate cuando realices la conexión...
        </Text>

        {/* Pasos */}
        <Box maxW="400px" position="relative" mb={5}>
          <VStack align="start" spacing={6} position="relative">
            {/* Paso 1 */}
            <Stack direction="row" spacing={4} align="flex-start">
              <Box position="relative">
                <Circle size="32px" bg="#EBD0FF" color="black" fontWeight="bold" zIndex={1}>
                  1
                </Circle>
                <Box
                  position="absolute"
                  top="32px"
                  left="16px"
                  width="2px"
                  height="60px"
                  bg="transparent"
                  borderLeft="2px dashed #EBD0FF"
                  zIndex={0}
                />
              </Box>
              <Stack spacing={1}>
                <Text fontWeight="bold">Tu cuenta sea de profesional</Text>
                <Text fontSize="sm">Desbloqueará funciones necesarias</Text>
              </Stack>
            </Stack>

            {/* Paso 2 */}
            <Stack direction="row" spacing={4} align="flex-start">
              <Box position="relative">
                <Circle size="32px" bg="#EBD0FF" color="black" fontWeight="bold" zIndex={1}>
                  2
                </Circle>
                <Box
                  position="absolute"
                  top="32px"
                  left="16px"
                  width="2px"
                  height="60px"
                  bg="transparent"
                  borderLeft="2px dashed #EBD0FF"
                  zIndex={0}
                />
              </Box>
              <Stack spacing={1}>
                <Text fontWeight="bold">Tienes vinculada una página de Facebook</Text>
                <Text fontSize="sm">Se utilizará sólo para la conexión</Text>
              </Stack>
            </Stack>

            {/* Paso 3 */}
            <Stack direction="row" spacing={4} align="flex-start">
              <Box position="relative">
                <Circle size="32px" bg="#EBD0FF" color="black" fontWeight="bold" zIndex={1}>
                  3
                </Circle>
              </Box>
              <Stack spacing={1}>
                <Text fontWeight="bold">Aceptas todos los permisos</Text>
                <Text fontSize="sm">Permitirá obtener el contenido que haces para las marcas</Text>
              </Stack>
            </Stack>
          </VStack>
        </Box>
      </Box>

      {/* Botón */}
      <Box>
        <Button
          onClick={() => handleClickConnectFacebook()}
          width="100%"
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
        >
          <span style={{ marginRight: '3px' }}>Conectar Instagram </span>
          <FaInstagram fontSize={16} />
        </Button>
      </Box>
    </Flex>
  );

  const ErrorContent = (
    <Flex p={5} flexDir="column" justifyContent="space-between">
      {/* Textos y condiciones */}
      <Box>
        {/* Intro */}
        <Text mb={0} fontSize={24} fontFamily="DM Sans" fontWeight="bold">
          Conecta tu Instagram
        </Text>
        <Text fontFamily="DM Sans" mb={6}>
          para colaborar con marcas en Instagram
        </Text>

        {/* Requisitos Intro */}
        <Text fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={2}>
          Requisitos
        </Text>

        {/* Requisitos */}
        <Flex mb={6}>
          <Box
            fontFamily="DM Sans"
            width="100%"
            borderStyle="dashed"
            borderColor="#000000"
            borderWidth="1px"
            borderRadius={6}
            display="flex"
            alignItems="center"
            p={2}
            mr={2}
            backgroundColor="rgba(253, 0, 0, 0.26)"
          >
            <Box mr={2} minH="40px" minW="40px">
              <Image
                boxSize="40px"
                objectFit="cover"
                src={FollowersIcon}
                alt="min followers criteria"
              />
            </Box>
            <Box>
              <Text lineHeight="1.3" fontWeight="semibold" fontFamily="DM Sans">
                +1000 <br /> seguidores
              </Text>
            </Box>
          </Box>
          <Box
            fontFamily="DM Sans"
            backgroundColor="rgba(253, 0, 0, 0.26)"
            width="100%"
            borderStyle="dashed"
            borderColor="#000000"
            borderWidth="1px"
            borderRadius={6}
            display="flex"
            alignItems="center"
            p={2}
          >
            <Box mr={2} w="40px" h="40px">
              <Image boxSize="40px" objectFit="cover" src={VideosIcon} alt="min videos criteria" />
            </Box>
            <Box>
              <Text lineHeight="1.3" fontWeight="semibold" fontFamily="DM Sans">
                +5 <br /> contenidos
              </Text>
            </Box>
          </Box>
        </Flex>

        {/* Texto 2 */}
        <Text lineHeight={1.3} fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={4}>
          Asegúrate cuando realices la conexión...
        </Text>

        {/* Pasos */}
        <Box maxW="400px" position="relative" mb={5}>
          <VStack align="start" spacing={6} position="relative">
            {/* Paso 1 */}
            <Stack direction="row" spacing={4} align="flex-start">
              <Box position="relative">
                <Circle size="32px" bg="#EBD0FF" color="black" fontWeight="bold" zIndex={1}>
                  1
                </Circle>
                <Box
                  position="absolute"
                  top="32px"
                  left="16px"
                  width="2px"
                  height="60px"
                  bg="transparent"
                  borderLeft="2px dashed #EBD0FF"
                  zIndex={0}
                />
              </Box>
              <Stack spacing={1}>
                <Text fontWeight="bold">Tu cuenta sea de Profesional</Text>
                <Text fontSize="sm">Desbloqueará funciones necesarias</Text>
              </Stack>
            </Stack>

            {/* Paso 2 */}
            <Stack direction="row" spacing={4} align="flex-start">
              <Box position="relative">
                <Circle size="32px" bg="#EBD0FF" color="black" fontWeight="bold" zIndex={1}>
                  2
                </Circle>
                <Box
                  position="absolute"
                  top="32px"
                  left="16px"
                  width="2px"
                  height="60px"
                  bg="transparent"
                  borderLeft="2px dashed #EBD0FF"
                  zIndex={0}
                />
              </Box>
              <Stack spacing={1}>
                <Text fontWeight="bold">Tienes vinculada una página de Facebook</Text>
                <Text fontSize="sm">Se utilizará sólo para la conexión</Text>
              </Stack>
            </Stack>

            {/* Paso 3 */}
            <Stack direction="row" spacing={4} align="flex-start">
              <Box position="relative">
                <Circle size="32px" bg="#EBD0FF" color="black" fontWeight="bold" zIndex={1}>
                  3
                </Circle>
              </Box>
              <Stack spacing={1}>
                <Text fontWeight="bold">Aceptas todos los permisos</Text>
                <Text fontSize="sm">Permitirá obtener el contenido que haces para las marcas</Text>
              </Stack>
            </Stack>
          </VStack>
        </Box>
      </Box>

      {/* Botón */}
      <Box>
        <Button
          onClick={() => handleClickConnectFacebook()}
          width="100%"
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
        >
          <span style={{ marginRight: '3px' }}>Conectar Instagram </span>
          <FaInstagram fontSize={16} />
        </Button>
      </Box>
    </Flex>
  );

  return (
    <Modal
      size={isLargerThan768 ? 'lg' : 'lg'}
      isOpen={isOpenConnectFacebookModal}
      onClose={onCloseConnectFacebookModal}
      isCentered
      scrollBehavior={isLargerThan768 ? 'inside' : 'inside'}
    >
      <ModalOverlay />
      <ModalContent
        border="2px solid #EBD0FF"
        borderRadius={isLargerThan768 ? '24px' : '24px'}
        m={isLargerThan768 ? 5 : 5}
        maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
        bg="#feffef"
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottomWidth="1px"
        >
          <Text fontSize="16px"> </Text>
          <Box cursor="pointer">
            <FiX
              size="20px"
              onClick={
                connectionError
                  ? handleClickCloseConnectFacebookModalAndClearParams
                  : onCloseConnectFacebookModal
              }
            />
          </Box>
        </ModalHeader>
        <ModalBody padding={0}>
          {connectionError === 'requirements' ? ErrorContent : InfoContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
