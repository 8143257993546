import React from 'react';

import './LoadingChallenges.css';

export default function LoadingChallenges() {

  return (
    <div className='loading__challenges'>
      <div className="loading__challenges__animation" data-loading-text="Cargando..." />
    </div>
  );
}
