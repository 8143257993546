import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Image,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
  useDisclosure,
} from '@chakra-ui/react';

import { logOut } from '../../Redux/features/auth/authSlice';

import LogOutIcon from '../../Assets/Images/logout-svgrepo-com.svg';

export default function LogOut() {
  
  const dispatch = useDispatch();
  const { isOpen: isOpenConfirmation, onOpen: onOpenConfirmation, onClose: onCloseConfirmation } = useDisclosure();

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpenConfirmation}
      onClose={onCloseConfirmation}
      placement="bottom"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          _hover={{ bg: '#FFFFFF' }}
          paddingRight="12px"
          paddingLeft="12px"
          color="black"
          variant="solid"
          size="xs"
          fontSize="14px"
          opacity="0.65"
          onClick={onOpenConfirmation}
        >
          <Flex alignItems="center">
            Cerrar sesión
            {' '}
            <Image
              marginTop="2px"
              marginLeft="5px"
              width="10px"
              src={LogOutIcon}
              alt="Logout"
            />
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody display="flex" justifyContent="left" fontSize="12px">
          ¿Estás seguro que quieres cerrar sesión?
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <ButtonGroup size="xs">
            <Button onClick={onCloseConfirmation} fontSize="12px" variant="outline">Cancelar</Button>
            <Button fontSize="12px" colorScheme="red" onClick={() => dispatch(logOut())}>Cerrar sesión</Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
