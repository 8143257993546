/* eslint-disable no-alert */
/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as amplitude from '@amplitude/analytics-browser';

import {
  Box,
  Stack,
  Heading,
  InputGroup,
  InputRightElement,
  Input,
  Button,
  Image,
  useToast,
  Text,
  useDisclosure,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';
import PasswordRecoveryModal from '../PasswordRecoveryModal/PasswordRecoveryModal';
import NewPasswordModal from '../NewPasswordModal/NewPasswordModal';

import { setCredentials } from '../../Redux/features/auth/authSlice';
import { useLoginMutation } from '../../Redux/features/auth/authApiSlice';

import './LoginForm.css';

export default function LoginForm() {
  // Cuando viene de intentar recuperar contrase�a
  const [searchParams] = useSearchParams();
  const forgottenPasswordToken = searchParams.get('forgottenPassword');

  // Toast
  const toast = useToast();

  // Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();

  // Email
  const [email, setEmail] = useState('');
  const handleChangeEmail = (event) => setEmail(event.target.value);

  // Contraseña
  const [password, setPassword] = useState('');
  const handleChangePassword = (event) => setPassword(event.target.value);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // Show toast and type
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Recovery password modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  // New password modal
  const {
    isOpen: isOpenNewPasswordModal,
    onOpen: onOpenNewPasswordModal,
    onClose: onCloseNewPasswordModal,
  } = useDisclosure();

  useEffect(() => {
    if (forgottenPasswordToken) {
      onOpenNewPasswordModal();
    }
  }, [forgottenPasswordToken, onOpenNewPasswordModal]);

  // Enviamos form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length === 0 || password.length === 0) {
      alert('Ningún campo puede estar vacío');
      return;
    }

    const userData = {
      email: email.toLowerCase(),
      password,
    };

    try {
      const userLoginData = await login(userData).unwrap();
      amplitude.setUserId(userLoginData.user._id);
      amplitude.track('session_started');
      showToast('Bienvenido de nuevo', 'success');
      dispatch(setCredentials(userLoginData));
      navigate('/challenges');
    } catch (error) {
      showToast('Hubo un problema con el login', 'error');
      console.log(error);
    }
  };

  return (
    <Box minH="100vh" fontFamily="DM Sans" bg="#fffff2">
      <Box py={5}>
        <Image
          margin="0 auto"
          src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
          alt="CRUWI Logo"
          width="80px"
        />
      </Box>
      <Box py={{ base: 5, sm: 20, lg: 32 }}>
        <Stack
          rounded="xl"
          p={{ base: 4, sm: 6, md: 8 }}
          maxW={{ lg: 'lg' }}
          m={{
            base: 4,
            sm: 6,
            md: 8,
            lg: '0 auto',
          }}
        >
          <Stack spacing={1}>
            <Heading
              textAlign="center"
              color="gray.800"
              lineHeight={1.1}
              fontFamily="DM Sans"
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Inicia sesión
            </Heading>
            <Text
              textAlign="center"
              color="gray.800"
              lineHeight={1.1}
              fontFamily="DM Sans"
              fontSize={{ base: '16px', sm: '20px', md: '20px' }}
            >
              como creador
            </Text>
          </Stack>
          <Box height="16px" />
          <Box as="form" mt={10}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel mb="2px">Email</FormLabel>
                <Input
                  size="sm"
                  borderRadius="8px"
                  fontFamily="DM Sans"
                  type="email"
                  placeholder="Email"
                  onChange={handleChangeEmail}
                  value={email}
                  bg="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel mb="2px">Contraseña</FormLabel>
                <InputGroup>
                  <Input
                    size="sm"
                    borderRadius="8px"
                    position="relative"
                    type={show ? 'text' : 'password'}
                    placeholder="Contraseña"
                    onChange={handleChangePassword}
                    value={password}
                    bg="white"
                  />
                  <InputRightElement height="32px" mr="4px" w="60px">
                    <Button size="xs" fontSize="12px" onClick={handleClick}>
                      {show ? 'Ocultar' : 'Mostrar'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
            <Button
              isLoading={isLoading}
              onClick={handleSubmit}
              fontFamily="DM Sans"
              mt={8}
              bg="#000000"
              w="full"
              color="white"
              _hover={{
                bg: '#000000',
              }}
            >
              Entrar
            </Button>
          </Box>
          <Text
            cursor="pointer"
            onClick={onOpen}
            mt={0}
            color="black"
            fontSize="14px"
            textAlign="center"
            fontFamily="DM Sans"
          >
            ¿Olvidaste tu contraseña?
          </Text>
        </Stack>

        <Text marginTop={4} color="black" fontSize="14px" textAlign="center" fontFamily="DM Sans">
          ¿No tienes cuenta?{' '}
          <CustomLink to="/register">
            <strong>Regístrate</strong>
          </CustomLink>
        </Text>

        <PasswordRecoveryModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        <NewPasswordModal
          forgottenPasswordToken={forgottenPasswordToken}
          isOpen={isOpenNewPasswordModal}
          onClose={onCloseNewPasswordModal}
          onOpen={onOpenNewPasswordModal}
        />
      </Box>
    </Box>
  );
}
