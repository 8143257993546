import { FaInstagram } from 'react-icons/fa';
import './ConnectFacebookButton.css';

export default function ConnectFacebookButton({ theme, click }) {
  const buttonClass =
    theme === 'white'
      ? 'connectfacebookbutton connectfacebookbutton__white'
      : 'connectfacebookbutton connectfacebookbutton__black';
  return (
    <button onClick={click} type="button" className={buttonClass}>
      Conectar <FaInstagram fontSize="15px" />
    </button>
  );
}
