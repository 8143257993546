import { FaTiktok } from 'react-icons/fa';

import './ConnectTikTokButton.css';

export default function ConnectTikTokButton({ theme, click }) {
  const buttonClass =
    theme === 'white'
      ? 'connecttiktokbutton connecttiktokbutton__white'
      : 'connecttiktokbutton connecttiktokbutton__black';
  return (
    <button onClick={click} type="button" className={buttonClass}>
      Conectar <FaTiktok fontSize="15px" />
    </button>
  );
}
