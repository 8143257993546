/* eslint-disable no-console */
import { Box, Flex, Text } from '@chakra-ui/react';

export default function LinkItemHeader({ challenge }) {
  const { brand: { logoUrl, name } } = challenge;
  return (
    <Flex justifyContent="center" alignItems="center">
      <Box
        width="26px"
        height="26px"
        borderRadius="50%"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundImage={`url(${logoUrl})`}
        mr={2}
      />
      <Text fontSize="20px" fontWeight="bold">
        Tu link de {name}
      </Text>
    </Flex>
  );
}
