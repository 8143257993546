import { Box, Heading, Text } from '@chakra-ui/react';
import UserLinkCard from './UserLinkCard';

export default function UserLinks() {
  return (
    <Box maxWidth="700px" margin="0 auto">
      <Box mt={5}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Links
        </Heading>
        <Text fontStyle="italic" opacity="0.8" fontSize="12px">
          Accede para ver tus links de los retos en los que has participado y cuánto llevas ganado
        </Text>
      </Box>
      <Box display="flex" mt={5} overflowX="auto" pb={7}>
        <UserLinkCard />
      </Box>
    </Box>
  );
}
