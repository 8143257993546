import { Box, Heading, Text, Button } from '@chakra-ui/react';
import CustomLink from '../CustomLink/CustomLink';

export default function UserLinkCard() {
  return (
    <Box
      minWidth="300px"
      maxWidth="300px"
      bg="#fffff2"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      border="2px solid black"
      p={3}
      mr={3}
    >
      <Box>
        <Heading fontSize="16px">Mis links</Heading>
        <Text fontSize="12px" lineHeight={1.2} mt={2} mb={5}>
          Los retos pueden incluir un enlace o un código que podrás compartir en redes sociales para
          ganar recompensas. Echa un vistazo a tus enlaces y sus conversiones.
        </Text>
      </Box>

      <CustomLink to="/links">
        <Button
          height="38px"
          fontSize="14px"
          _hover={{ bg: '#FFFFF2' }}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#FFFFF2"
          color="black"
          width="100%"
          size="md"
        >
          Ver mis links
        </Button>
      </CustomLink>
    </Box>
  );
}
