/* eslint-disable no-console */
import { Flex, Button, Box, Text } from '@chakra-ui/react';

import useCopyToClipboard from '../../Hooks/useCopyToClipboard';
import useShareApi from '../../Hooks/useShareApi';

import formatDate from '../../Utils/formatDate';

export default function LinkItemShare({ affiliation, challenge }) {
  const { externalId } = affiliation;
  const {
    affiliation: { validUntil },
  } = challenge;

  const [copiedText, copy] = useCopyToClipboard();
  const share = useShareApi();

  const handleShareClick = () => {
    share({
      title: 'Regístrate desde mi link',
      text: 'Estás usando mi link :)',
      url: externalId,
    });
  };

  return (
    <Box>
      <Flex mt={5} justifyContent="center">
        <Button
          bg="black"
          color="white"
          size="sm"
          _hover={{ bg: '#000000' }}
          mr={2}
          onClick={() => copy(externalId)}
        >
          {copiedText ? 'Copiado!' : 'Copiar link'}
        </Button>
        <Button onClick={handleShareClick} bg="white" color="black" size="sm" variant="outline">
          Enviar Link
        </Button>
      </Flex>
      <Flex mt={2} justifyContent="center">
        <Text margin="0 auto" maxW="200px" fontSize="10px" textAlign="center">
          Link válido hasta <strong>{formatDate(validUntil)}</strong> o hasta que se acabe el presupuesto de la marca
        </Text>
      </Flex>
    </Box>
  );
}
