import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

import ChallengeIcon from '../../Assets/Images/cancel.png';

export default function NoChallengesCompleted() {
  return (
    <Box
      fontFamily="DM Sans"
      bg="#fffff2"
      width="100%"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      mb={4}
      p={4}
    >
      <Box textAlign="center">
        <Image margin="5px auto 10px auto" src={ChallengeIcon} alt="Challenge Icon" width="30px" />
        <Text  fontSize="12px" >No tienes retos completados</Text>
      </Box>
    </Box>
  );
}
