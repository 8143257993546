/* eslint-disable no-alert */
/* eslint-disable no-console */

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  InputGroup,
  Input,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';

import { useConfirmNewPasswordMutation } from '../../Redux/features/auth/authApiSlice';

export default function NewPasswordModal({ forgottenPasswordToken, isOpen, onClose }) {

  // Toast
  const toast = useToast();

  // Show toast and type
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Redux
  const [confirmNewPassword, { isLoading }] = useConfirmNewPasswordMutation();

  // Router
  const navigate = useNavigate();

  // Password
  const [password, setPassword] = useState('');
  const handleChangePassword = (event) => setPassword(event.target.value);

  // Confirmation password
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const handlechangeConfirmationPassword = (event) => setConfirmationPassword(event.target.value);

  // See first password
  const [seePassword, setSeePassword] = useState(false);
  const handleClickSeePassword = () => setSeePassword(!seePassword);

  // See confirmation password
  const [seeConfirmationPassword, setSeeConfirmationPassword] = useState(false);
  const handleClickSeeConfirmationPassword = () => setSeeConfirmationPassword(!seeConfirmationPassword);

  // Enviamos form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length === 0 || confirmationPassword.length === 0) {
      alert('Las contraseñas no pueden estar vacías');
      return;
    }

    if(password !== confirmationPassword) {
      alert('Las contraseñas no coinciden');
      return;
    }

    const userData = {
      password,
      token: forgottenPasswordToken,
    };

    try {
      await confirmNewPassword(userData).unwrap();
      showToast('Tu contraseña ha sido cambiada correctamente', 'success');
      setPassword('');
      setConfirmationPassword('');
      onClose();
      navigate('/login');
    } catch (error) {
      showToast('Hubo un problema solicitando el cambio de contraseña', 'error');
      console.log(error);
    }
    
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="16px">Nueva contraseña</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4} color="black" fontSize="14px" textAlign="left" fontFamily="DM Sans">
            Introduce tu nueva contraseña
          </Text>
          <InputGroup>
            <Input
              value={password}
              onChange={handleChangePassword}
              pr="4.5rem"
              fontFamily="DM Sans"
              bg="gray.100"
              border={0}
              color="gray.900"
              type={seePassword ? 'text' : 'password'}
              placeholder="Contraseña"
              mb={4}
            />
            <InputRightElement width="4.5rem" mr="10px">
              <Button h="1.75rem" size="sm" onClick={handleClickSeePassword}>
                {seePassword ? 'Ocultar' : 'Mostrar'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <InputGroup>
            <Input
              value={confirmationPassword}
              onChange={handlechangeConfirmationPassword}
              pr="4.5rem"
              fontFamily="DM Sans"
              bg="gray.100"
              border={0}
              color="gray.900"
              type={seeConfirmationPassword ? 'text' : 'password'}
              placeholder="Contraseña"
            />
            <InputRightElement width="4.5rem" mr="10px">
              <Button h="1.75rem" size="sm" onClick={handleClickSeeConfirmationPassword}>
                {seeConfirmationPassword ? 'Ocultar' : 'Mostrar'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button
            isLoading={isLoading}
            onClick={handleSubmit}
            fontFamily="DM Sans"
            mt={4}
            bg="#000000"
            w="full"
            color="white"
            _hover={{
              bg: '#000000',
            }}
            mb={8}
          >
            Cambiar contraseña
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

}
