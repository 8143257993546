import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  ModalCloseButton,
} from '@chakra-ui/react';

export default function InfoRewardsModal({ isOpen, onClose, children }) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent border="2px solid #EBD0FF" borderRadius="24px" m={5}>
        <ModalCloseButton bg="#fff" borderRadius="50%" m={1} border="1px solid" />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          <Box>{children}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
