/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import { Box, Text, IconButton, Flex } from '@chakra-ui/react';

import { MdPlayCircleOutline, MdPauseCircleOutline } from 'react-icons/md';

function VideoSlider({ videos }) {
  const [currentPlaying, setCurrentPlaying] = useState(null);

  const handlePlayPause = (index) => {
    setCurrentPlaying(currentPlaying === index ? null : index);
  };

  // Assuming a 16:9 aspect ratio for the videos and placeholders
  const aspectRatio = 9 / 16;

  return (
    <Flex gap="4" p="2">
      {videos.slice(0, 3).map((video, index) => (
        <Box
          key={index}
          position="relative"
          width="100%"
          pb={`${aspectRatio * 100}%`}
          borderRadius="12px"
          overflow="hidden"
          bg="black"
        >
          <ReactPlayer
            url={video.url}
            playing={currentPlaying === index}
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: '0', left: '0', objectFit: 'cover' }}
            onClick={() => handlePlayPause(index)}
          />
          <IconButton
            aria-label={currentPlaying === index ? 'Pause' : 'Play'}
            icon={currentPlaying === index ? <MdPauseCircleOutline /> : <MdPlayCircleOutline />}
            size="sm"
            isRound
            bg="#ffffffab"
            fontSize="20px"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="10"
            onClick={() => handlePlayPause(index)}
          />
        </Box>
      ))}

      {[...Array(3 - videos.length)].map((_, index) => (
        <Box
          key={`placeholder-${index}`}
          position="relative"
          width="100%"
          pb={`${aspectRatio * 100}%`} // This maintains the aspect ratio
          borderRadius="12px"
          bg="#EBD0FF"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
          />
        </Box>
      ))}
    </Flex>
  );
}

export default function BriefingItemModalYourVideos({ briefingData }) {
  const { applicantVideos } = briefingData;
  return (
    <Box p={4} mb="24px">
      <Text mb="8px" fontWeight="bold" fontSize="14px">
        Estos son los vídeos de tu candidatura
      </Text>
      <Box fontSize="14px" borderRadius="24px 24px 24px 24px" bg="#FFFFF2" p={2}>
        <VideoSlider videos={applicantVideos || []} />
      </Box>
    </Box>
  );
}
