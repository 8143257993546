import React from 'react';
import { Box, Text, Button, ListItem, UnorderedList, useDisclosure } from '@chakra-ui/react';

import describeVideoNeeds from '../../../../Utils/describeVideoNeeds';

import BriefingItemModalFullBriefing from '../BriefingItemModalFullBriefing/BriefingItemModalFullBriefing';

export default function BriefingItemModalDetails({ briefingData }) {
  const {
    brand,
    selectedVideoNeeds,
    productLink,
    productValue,
    rewardAmount,
    finalText = '',
  } = briefingData;

  const {
    isOpen: isOpenModalFullBriefing,
    onOpen: onOpenModalFullBriefing,
    onClose: onCloseModalFullBriefing,
  } = useDisclosure();

  // Function to trim text to 300 characters and add ellipsis
  const trimText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // Trim finalText if it's longer than 300 characters
  const displayText = trimText(finalText, 300);

  return (
    <Box mt={5} p="0px 16px 16px 16px" bg="#E4E4D9">
      {/* Qué buscamos */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          ¿Qué buscamos?
        </Text>
        <Text fontSize="14px">{describeVideoNeeds(selectedVideoNeeds)}</Text>
      </Box>

      {/* Descripción */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          Descripción - Briefing
        </Text>
        <Box fontSize="14px" borderRadius="24px 24px 0px 0px" bg="#FFFFF2" p={4}>
          <div className="description-html">
            <Box
              fontFamily="DM Sans"
              fontSize="14px"
              dangerouslySetInnerHTML={{ __html: displayText }}
            />
          </div>
        </Box>
        <Button
          width="100%"
          borderRadius="0px 0px 24px 24px"
          bgGradient="linear(to-b, #E4E4D9, #EBD0FF)"
          fontSize="14px"
          onClick={onOpenModalFullBriefing}
          _hover={{
            backgroundColor: 'transparent',
          }}
        >
          Ver briefing completo
        </Button>
      </Box>

      {/* Remuneración */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          ¿Cuál es tu remuneración?
        </Text>
        <UnorderedList fontSize="14px">
          <ListItem>Producto {productValue > 0 ? `valorado en ${productValue}€` : ''}.</ListItem>
          {rewardAmount && <ListItem>Pago de {rewardAmount}€</ListItem>}
        </UnorderedList>
      </Box>

      {/* Sobre el producto */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          Sobre el producto
        </Text>
        <UnorderedList fontSize="14px">
          <ListItem>
            Puedes verlo pulsando{' '}
            <u>
              <a href={productLink} target="_blank" rel="noopener noreferrer">
                aquí
              </a>
            </u>
            .
          </ListItem>
          <ListItem>Tiene un valor de {productValue}€</ListItem>
        </UnorderedList>
      </Box>

      {/* Sobre nuestra marca */}
      <Box mb="24px">
        <Text mb="8px" fontWeight="bold" fontSize="14px">
          Sobre nuestra marca
        </Text>
        <Text fontSize="14px">{brand && brand.description}</Text>
      </Box>

      {/* Briefing completo en modal */}
      <BriefingItemModalFullBriefing
        briefingData={briefingData}
        isOpen={isOpenModalFullBriefing}
        onClose={onCloseModalFullBriefing}
      />
    </Box>
  );
}
