import { Button, ModalFooter, Box } from '@chakra-ui/react';

export default function BriefingItemModalButton({
  isCollab,
  applicantStatus,
  isLargerThan768,
  collabData,
  onOpenModalApply,
  onOpenConfirmApplicationModal,
  onOpenConfirmProductReceptionModal,
  onOpenShareVideosModal,
  onOpenClaimPaymentModal,
}) {
  // Vemos si es una collab y en qué estado está
  const collabStatus = (isCollab && collabData) ? collabData.status : 'pending';

  // Cambiamos el contenido y acción del botón según el estado del challenge y las plazas
  let buttonContent = 'Aplicar';
  let buttonAction = onOpenModalApply;
  const isDisabled = false;

  // Si es una collab  y tiene el estado pending: debe confirmar la participación
  if (Boolean(isCollab) && collabStatus === 'pending') {
    buttonContent = 'Confirmar';
    buttonAction = onOpenConfirmApplicationModal;
  }

  // Si es una collab  y tiene el estado confirmed: debe confirmar la recepción del producto
  if (Boolean(isCollab) && collabStatus === 'confirmed') {
    buttonContent = 'Confirmar recepción producto';
    buttonAction = onOpenConfirmProductReceptionModal;
  }

  // Si es una collab  y tiene el estado received: debe ya compartir los contenidos
  if (Boolean(isCollab) && collabStatus === 'received') {
    buttonContent = 'Compartir vídeos';
    buttonAction = onOpenShareVideosModal;
  }

  // Si es una collab  y tiene el estado received: debe ya compartir los contenidos
  if (Boolean(isCollab) && collabStatus === 'approved') {
    buttonContent = 'Reclamar pago';
    buttonAction = onOpenClaimPaymentModal;
  }

  // Cuando ya es estado aplicado o se han enviado los contenidos, quitamos cualquier botón
  if (
    applicantStatus === 'applied' ||
    (applicantStatus === 'selected' && (collabStatus === 'produced' || collabStatus === 'claiming'))
  ) {
    return null;
  }

  return (
    <ModalFooter
      borderRadius={isLargerThan768 ? '0px 0px 24px 24px' : ''}
      bg="#E4E4D9"
      p="16px"
      boxShadow="0px -5px 10px #8888886b"
    >
      <Box width="100%">
        <Button
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          onClick={() => buttonAction()}
          disabled={isDisabled}
        >
          {buttonContent}
        </Button>
      </Box>
    </ModalFooter>
  );
}
