/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */

import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Button, Text, useDisclosure, useToast } from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import TikTokImage from '../../Assets/Images/tik-tok_icon.png';
import InstagramImage from '../../Assets/Images/instagram_icon.png';

import VideoModal from '../VideoModal/VideoModal';
import ChallengeItemModal from './ChallengeItemModal/ChallengeItemModal';

import ChallengeDefaultHeaderImage from '../../Assets/Images/challenge-modal_header.png';

import {
  useParticipateInChallengeMutation,
  useStopParticipatingInChallengeMutation,
  useClaimMutation,
  useNotifyMeMutation,
  useRepeatChallengeMutation,
} from '../../Redux/features/challenges/challengesApiSlice';

import { getDiffInHours } from '../../Utils/date';
import formatCentsToEurNoFractionDigits from '../../Utils/formatCentsToEurNoFractionDigits';
import calculateRelativeChallengeFinalizationDate from '../../Utils/calculateRelativeChallengeFinalizationDate';

import './ChallengeItem.css';

export default function ChallengeItem({ status, challengeData, userData, videoCreateTime }) {
  const {
    _id,
    title,
    brand,
    videoExampleUrls,
    participantsCount,
    subscribers,
    bannerUrl,
    maxParticipants,
    affiliation,
    isComingSoon,
    isMission,
    isInstagram,
    finalizationDate = '2024-08-27T00:00:00.000Z',
    reward: { fixed = 0, variable = 0, bonus = 0 },
  } = challengeData;

  // Router
  const navigate = useNavigate();

  // Vemos si el user está activo
  const userIsActive = userData?.isActive;

  // Usuario ha hecho el nuevo flujo de pago
  const hasMadeTheNewPaymentFlow = localStorage.getItem('hasMadeTheNewPaymentFlow');

  // Vemos si están todas las plazas llenas del reto o misión
  const isFull = participantsCount >= maxParticipants;

  // Vemos si es de tipo conversión o por views (si tine el objeto, es de conversión)
  const hasAffiliation = Boolean(affiliation);

  // Vemos si ya tiene el reto participando
  const isParticipating =
    userData?.challenges.findIndex(
      (challenge) => challenge.challenge._id === _id && !challenge.repeated,
    ) !== -1;

  // Vemos si el user can puede participar en misiones
  const userCanParticipateInMissions = userData?.tikTokInfo?.followerCount >= 5000;

  //  Vemos si está el User suscrito al reto
  const isSubscribed = subscribers.includes(userData?.id);

  // Vemos si el reto ya está reclamado (quitar el botón de desapuntarse)
  const isClaimed =
    userData?.challenges.findIndex(
      (challenge) => challenge.challenge._id === _id && challenge.status === 'claimed',
    ) !== -1;

  // Comprobamos si tiene TikTok Conectado
  const hasTikTokConnected = userData?.tikTokInfo.hasOwnProperty('openId');
  const hasTikTokSessionExpired = userData?.tikTokInfo.outdated === true;

  // Comprobamos si tiene o no conectado el Instagram (facebook)
  const hasFacebookConnected = userData?.facebookInfo?.hasOwnProperty('instagramAccountId');

  // Toasts
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Modal del reto completo
  const {
    isOpen: isOpenChallengeItemModal,
    onOpen: onOpenChallengeItemModal,
    onClose: onCloseChallengeItemModal,
  } = useDisclosure();

  const openChallengeItemModal = () => {
    onOpenChallengeItemModal();
  };

  // Check para que confirmen que tienen que subir el reto en 72h (mete presión virtual)
  const [hasAcceptedConditions, setHasAcceptedConditions] = useState(false);
  const toggleHasAcceptedConditions = () => setHasAcceptedConditions(!hasAcceptedConditions);

  // Acciones del reto
  const [participate, { isLoadingParticipate }] = useParticipateInChallengeMutation();

  const [stopParticipating, { isLoadingStopParticipating }] =
    useStopParticipatingInChallengeMutation();

  const [repeatChallenge] = useRepeatChallengeMutation();

  const [claim, { isLoadingClaim }] = useClaimMutation();

  const [notifyMe] = useNotifyMeMutation();

  const participateInChallenge = async () => {
    // Vemos si tiene tiktok conectado o experido y es un reto de TikTok
    if ((!hasTikTokConnected || hasTikTokSessionExpired) && !isInstagram) {
      showToast(
        'Conecta tu TikTok para poder participar en los retos y misiones de TikTok',
        'warning',
      );
      return;
    }

    // Vemos si tiene instagram conectado o experido y es un reto de instagram
    if (!hasFacebookConnected && isInstagram) {
      showToast(
        'Conecta tu Instagram para poder participar en los retos y misiones de Instagram',
        'warning',
      );
      return;
    }

    if (!hasAcceptedConditions) {
      showToast('Debes confirmar que subirás el vídeo en las próximas 72h', 'warning');
      return;
    }

    try {
      await participate(_id).unwrap();

      const eventProperties = {
        brandName: brand.name,
        challengeTitle: title,
      };

      amplitude.track('participate', eventProperties);

      onCloseChallengeItemModal();

      showToast(
        `Ahora participas en ${isMission ? 'la misión' : 'el reto'}, sube el TikTok para ganar las recompensas`,
        'success',
      );
    } catch (error) {
      if (error.status === 403) {
        showToast(
          `No cumples los requisitos para participar en ${isMission ? 'esta misión' : 'este reto'}`,
          'error',
        );
        return;
      }
      showToast(
        `Hubo un problema participando en ${isMission ? 'en la misión' : 'en el reto'}`,
        'error',
      );
      console.log(error);
    }
  };

  const stopParticipatingInChallenge = async () => {
    try {
      await stopParticipating(_id).unwrap();

      const eventProperties = {
        brandName: brand.name,
        challengeTitle: title,
      };

      amplitude.track('unparticipate', eventProperties);

      onCloseChallengeItemModal();

      showToast(`Ya no participas en ${isMission ? 'esta misión' : 'este reto'} `, 'success');
    } catch (error) {
      showToast(
        `Hubo un problema desapuntándote ${isMission ? 'de la misión' : 'del reto'} `,
        'error',
      );
      console.log(error);
    }
  };

  const onRepeatChallenge = async () => {
    try {
      const response = await repeatChallenge(_id).unwrap();
      if (response.freePlaces) {
        showToast('Ya puedes participar en el reto de nuevo', 'success');
      } else {
        showToast(
          'Lo sentimos, no quedan plazas para el reto. Te avisaremos cuando esté disponible de nuevo',
          'warning',
        );
      }
      onCloseChallengeItemModal();
    } catch (error) {
      showToast('Hubo un problema repitiendo el reto', 'error');
      console.log(error);
    }
  };

  const notifyMeWhenPlaceAvailable = async () => {
    if (!userIsActive) {
      showToast('No puedes suscribirte a las notificaciones del reto. Contacta con hello@cruwi.com', 'error');
      return;
    }

    if ((!hasTikTokConnected || hasTikTokSessionExpired) && !isInstagram) {
      showToast('Conecta tu TikTok para poder participar en los retos y misiones de TikTok', 'warning');
      return;
    }

    // Vemos si tiene instagram conectado o experido y es un reto de instagram
    if (!hasFacebookConnected && isInstagram) {
      showToast(
        'Conecta tu Instagram para poder participar en los retos y misiones de Instagram',
        'warning',
      );
      return;
    }

    try {
      const notifyResult = await notifyMe(_id).unwrap();
      console.log(notifyResult);

      const eventProperties = {
        brandName: brand.name,
        challengeTitle: title,
      };

      amplitude.track('notifyMe', eventProperties);

      showToast(
        `Te has suscrito ${isMission ? 'a la misión' : 'al reto'} correctamente`,
        'success',
      );
      onCloseChallengeItemModal();
    } catch (error) {
      showToast('Hubo un problema suscribiéndote al reto', 'error');
      console.log(error);
    }
  };

  const claimMoney = async () => {
    try {
      const claimedResult = await claim(_id).unwrap();
      console.log(claimedResult);

      const eventProperties = {
        brandName: brand.name,
        challengeTitle: title,
      };

      amplitude.track('claim', eventProperties);
    } catch (error) {
      showToast('Hubo un problema reclamando tu recompensa, escríbenos.', 'error');
      console.log(error);
    }
  };

  const claimMoney2 = async () => {
    if (hasMadeTheNewPaymentFlow) {
      claimMoney();
      onCloseChallengeItemModal();
    } else {
      claimMoney();
      localStorage.setItem('hasMadeTheNewPaymentFlow', 'true');
      onCloseChallengeItemModal();
      navigate('/profile');
    }
    showToast('Ya tienes este saldo en tu saldo pendiente', 'success');
  };

  // Lógica para ver si puede o no reclamar (está aprobado y más de 48h monetizando)
  const claimingHours = isMission ? 72 : 48;
  const isClaimable =
    status === 'approved' && getDiffInHours(videoCreateTime, new Date()) > claimingHours;

  // Ver si está declinado el vídeo
  const isDeclined = status === 'declined';

  // Si está lleno el reto y no está suscrito (o misión)
  const isFullAndSubscribed = isFull && isSubscribed;
  const isFullAndUnsubscribed = isFull && !isSubscribed;

  // Obtener el reward del reto
  const currentUserChallenge = userData?.challenges.filter(
    (challenge) => challenge.challenge._id === _id && challenge.status === 'approved',
  );

  const moneyToClaim = currentUserChallenge[0]?.rewardAmount || 0;

  // Obtenemos el texto y color correcto en base al estado del reto del usuario
  const getRightStatusTextAndColor = (challengeStatus) => {
    let text = 'SIN PARTICIPAR';
    let statusText = '';
    let textColor = 'black';
    let backgroundColor = '#FFFFF2';
    let explainingText = `Pulsa en "Participar" para ver en qué consiste ${isMission ? 'la misión' : 'el reto'} y los hashtags necesarios para que tu vídeo sea detectado.`;

    if (challengeStatus === 'active') {
      text = 'PARTICIPANDO';
      statusText = `Te toca subir el vídeo ${hasAffiliation ? '(y si hay conversión, compartir tu link)' : ''}`;
      textColor = 'black';
      backgroundColor = '#EBD0FF';
      explainingText = `Pulsa en ${isMission ? 'Ver misión' : 'Ver reto'} para ver el briefing y los hashtags necesarios para que tu vídeo sea detectado.`;
    }

    if (challengeStatus === 'revision') {
      text = 'EN REVISIÓN';
      statusText = 'Tu vídeo está en revisión, la marca tiene hasta 72h para aprobarlo o denegarlo';
      textColor = 'black';
      backgroundColor = '#FFB347';
      explainingText = `Pulsa en ${isMission ? 'Ver misión' : 'Ver reto'} para ver el briefing y los hashtags necesarios para que tu vídeo sea detectado.`;
    }

    if (challengeStatus === 'approved') {
      text = 'APROBADO';
      statusText = 'Tu vídeo ha sido aprobado, te avisaremos cuando puedas reclamar tu recompensa';
      backgroundColor = '#BDECB6';
      explainingText = `Pulsa en ${isMission ? 'Ver misión' : 'Ver reto'} para ver el briefing y los hashtags necesarios para que tu vídeo sea detectado.`;
    }

    if (isClaimable) {
      text = 'RECLAMAR';
      statusText = 'Hora de recoger tu recompensa, pulsa en reclamar';
      textColor = 'white';
      backgroundColor = '#000000';
      explainingText = `Pulsa en "Reclamar" para que incluyamos las recompensas de ${isMission ? 'esta misión' : 'este reto'} en tu saldo.`;
    }

    if (isClaimed) {
      text = 'COMPLETADO';
      textColor = 'black';
      backgroundColor = '#FFFFF2';
      explainingText = `Pulsa en ${isMission ? 'Misión Completada' : 'Reto Completado'} para ver ${isMission ? 'la misión' : 'el reto'} que ya has completado con éxito.`;
    }

    if (isComingSoon) {
      text = 'PRÓXIMAMENTE';
      textColor = 'black';
      backgroundColor = '#FFFFF2';
      explainingText = `Pulsa en "Disponible pronto" para ver en qué va a consistir ${isMission ? 'esta misión' : 'este reto'}.`;
    }

    if (challengeStatus === 'declined') {
      text = 'RECHAZADO';
      statusText = 'Tu vídeo ha sido denegado, inténtalo de nuevo :(';
      backgroundColor = '#FF6961';
      explainingText = `Pulsa en "${isMission ? 'Repetir Misión' : 'Repetir Reto'}" para volverlo a intentar. Si hay plazas se te asignará. Si no hay, se te pondrá en espera.`;
    }

    // Si está lleno y no suscrito y NO tiene algún status..
    if (isFullAndUnsubscribed && !status) {
      text = 'SIN PARTICIPAR';
      statusText = '';
      textColor = 'black';
      backgroundColor = '#FFFFF2';
      explainingText =
        'Ahora mismo las plazas están llenas. Pulsa en "Participar" para que te avisemos cuando haya plazas libres.';
    }

    if (isFullAndSubscribed) {
      text = 'EN ESPERA';
      statusText = '';
      textColor = 'black';
      backgroundColor = '#FFFFF2';
      explainingText =
        'Ahora mismo las plazas están llenas. Te avisaremos por email cuando haya libres.';
    }

    return {
      text,
      statusText,
      textColor,
      backgroundColor,
      explainingText,
    };
  };

  // Modal vídeo ejemplo
  const {
    isOpen: isOpenVideoModal,
    onOpen: onOpenVideoModal,
    onClose: onCloseVideoModal,
  } = useDisclosure();

  return (
    <>
      {/* Card del reto */}
      <Box
        fontFamily="DM Sans"
        width="100%"
        borderRadius="24px"
        border="2px solid #000000"
        background="#FFFFF2"
        boxShadow="4px 4px 0px 0px #000"
        mb="24px"
      >
        {/* Cabecera */}
        {!isParticipating || !status || status === 'claimed' ? (
          <Box
            backgroundPosition="center"
            borderTopLeftRadius="22px"
            borderTopRightRadius="22px"
            height="100px"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundImage={`url("${bannerUrl || ChallengeDefaultHeaderImage}")`}
            borderBottom="2px solid black"
          />
        ) : (
          <Box
            backgroundPosition="center"
            borderTopLeftRadius="22px"
            borderTopRightRadius="22px"
            height="80px"
            backgroundColor={getRightStatusTextAndColor(status).backgroundColor}
            textAlign="center"
            p={4}
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            borderBottom="2px solid black"
            color={getRightStatusTextAndColor(status).textColor}
          >
            <Text mb={1} fontSize="12px">
              Estado de tu {isMission ? 'misión' : 'reto'}
            </Text>
            <Text lineHeight={1.3} maxW="250px" fontSize="12px" fontWeight="bold">
              {getRightStatusTextAndColor(status).statusText}
            </Text>
          </Box>
        )}

        {/* Estados */}
        <Flex justifyContent="space-between" alignItems="center" p={4}>
          <Box>
            <Box
              fontWeight="bold"
              fontSize="12px"
              bg={getRightStatusTextAndColor(status).backgroundColor}
              color={getRightStatusTextAndColor(status).textColor}
              borderRadius="24px"
              border="1px solid black"
              minW="140px"
              textAlign="center"
              className={isClaimable && 'pulse'}
            >
              {getRightStatusTextAndColor(status).text}
            </Box>
          </Box>
          <Box>
            <Text fontSize="12px" color="rgba(0, 0, 0, 0.56)">
              {calculateRelativeChallengeFinalizationDate(finalizationDate)}
            </Text>
          </Box>
        </Flex>

        {/* Reto y logo marca */}
        <Flex alignItems="center" w="100%" justifyContent="space-between" px={4}>
          <Flex flexDir="column">
            <Text mb={1} lineHeight={1.3} fontSize="20px" fontWeight="bold" fontFamily="DM Sans">
              {isMission ? 'Misión' : 'Reto'} {title}
            </Text>
            <Text lineHeight={1.3} fontFamily="DM Sans" fontSize="16px" fontWeight="500">
              Colabora con {brand.name}
            </Text>
          </Flex>
          <Box position="relative">
            <Box
              borderRadius="50%"
              height="52px"
              w="52px"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundImage={`url("${brand.logoUrl}")`}
              border="1px solid"
            />
            {isInstagram ? (
              <Box
                position="absolute"
                borderRadius="50%"
                height="24px"
                w="24px"
                backgroundSize="12px"
                backgroundRepeat="no-repeat"
                backgroundPosition="center center"
                bgColor="#FFFFF2"
                backgroundImage={`url("${InstagramImage}")`}
                border="1px solid"
                top="-3px"
                right="-3px"
              />
            ) : (
              <Box
                position="absolute"
                borderRadius="50%"
                height="24px"
                w="24px"
                backgroundSize="12px"
                backgroundPosition="center center"
                bgColor="#FFFFF2"
                backgroundRepeat="no-repeat"
                backgroundImage={`url("${TikTokImage}")`}
                border="1px solid"
                top="-3px"
                right="-3px"
              />
            )}
          </Box>
        </Flex>

        {/* Banner recompensas según sea reto o misión */}
        {isMission ? (
          <Box p={4} onClick={() => openChallengeItemModal()}>
            <Box
              position="relative"
              bg="radial-gradient(50% 1097.43% at 50% 50.68%, #EBD0FF 0%, #E4E4D9 100%)"
              p={3}
              borderRadius="8px"
              cursor="pointer"
            >
              <Box width="100%">
                <Text textAlign="center" fontSize="16px" fontWeight="bold">
                  💸 Recompensas 💸
                </Text>
              </Box>
              <Flex mt={1} justifyContent="center">
                <Box mr={3} textAlign="center">
                  <Text fontSize="12px">Primeras 1.000 views</Text>
                  <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                    {formatCentsToEurNoFractionDigits(fixed)}
                  </Text>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
                  <Text fontSize="20px" fontWeight="black">
                    +
                  </Text>
                </Box>
                <Box ml={3} mr={0} textAlign="center">
                  <Text fontSize="12px">x 1.000 views extra</Text>
                  <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                    {hasAffiliation
                      ? 'x conversiones'
                      : `${formatCentsToEurNoFractionDigits(variable)}`}
                  </Text>
                </Box>
              </Flex>
              {/* Si tiene bonus, lo mostramos */}
              {bonus > 0 && (
                <Flex
                  width="100%"
                  maxW="400px"
                  margin="0 auto"
                  minH="20px"
                  fontWeight="bold"
                  textAlign="center"
                  mt={2}
                  borderRadius="4PX"
                  bg="#FFFFF2"
                  fontSize="12px"
                  alignItems="center"
                  justifyContent="center"
                >
                  +{formatCentsToEurNoFractionDigits(bonus)} extras subiéndolo en las primeras 72h{' '}
                  <InfoOutlineIcon marginLeft="3px" height="10px" />
                </Flex>
              )}
              <Box
                opacity={0.8}
                top={2}
                right={2}
                position="absolute"
                px={4}
                py="1px"
                display="inline-block"
                borderRadius="4px"
                border="1px solid"
              >
                <Text fontSize="9px">+Info</Text>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box p={4} onClick={() => openChallengeItemModal()}>
            <Box
              position="relative"
              bg="radial-gradient(50% 1097.43% at 50% 50.68%, #EBD0FF 0%, #E4E4D9 100%)"
              p={3}
              borderRadius="8px"
              cursor="pointer"
            >
              <Box width="100%">
                <Text textAlign="center" fontSize="16px" fontWeight="bold">
                  💸 Recompensas 💸
                </Text>
              </Box>
              <Flex mt={1} justifyContent="center">
                <Box mr={4} textAlign="center">
                  <Text fontSize="12px">Fija (vídeo aprobado)</Text>
                  <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                    {formatCentsToEurNoFractionDigits(fixed)}
                  </Text>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
                  <Text fontSize="20px" fontWeight="black">
                    +
                  </Text>
                </Box>
                <Box ml={4} mr={7} textAlign="center">
                  <Text fontSize="12px">Variable</Text>
                  <Text fontWeight="bold" fontSize="12px" lineHeight={1.1}>
                    {hasAffiliation
                      ? 'x conversiones'
                      : `${formatCentsToEurNoFractionDigits(variable)}/1000 views`}
                  </Text>
                </Box>
              </Flex>
              {/* Si tiene bonus, lo mostramos */}
              {bonus > 0 && (
                <Flex
                  width="100%"
                  maxW="400px"
                  margin="0 auto"
                  minH="20px"
                  fontWeight="bold"
                  textAlign="center"
                  mt={2}
                  borderRadius="4PX"
                  bg="#FFFFF2"
                  fontSize="12px"
                  alignItems="center"
                  justifyContent="center"
                >
                  +{formatCentsToEurNoFractionDigits(bonus)} extras subiéndolo en las primeras 72h{' '}
                  <InfoOutlineIcon marginLeft="3px" height="10px" />
                </Flex>
              )}
              <Box
                opacity={0.8}
                top={2}
                right={2}
                position="absolute"
                px={4}
                py="1px"
                display="inline-block"
                borderRadius="4px"
                border="1px solid"
              >
                <Text fontSize="9px">+Info</Text>
              </Box>
            </Box>
          </Box>
        )}

        {/* Texto explicación según estado */}
        <Box px={4}>
          <Text fontFamily="DM Sans" fontSize="12px">
            {getRightStatusTextAndColor(status).explainingText}
          </Text>
        </Box>

        {/* Lógica botón principal */}
        <Box p={4}>
          {isParticipating && !isDeclined ? (
            <Box>
              {isClaimable ? (
                <Button
                  onClick={() => openChallengeItemModal()}
                  border="2px solid black"
                  boxShadow="1px 1px 0px 0px #000"
                  borderRadius="16px"
                  bg="#EBD0FF"
                  color="black"
                  width="100%"
                >
                  Reclamar
                </Button>
              ) : isClaimed ? (
                <Button
                  _hover={{ bg: '#edf2f7' }}
                  disabled="true"
                  border="2px solid black"
                  boxShadow="1px 1px 0px 0px #000"
                  borderRadius="16px"
                  bg="#EDEFF8"
                  color="black"
                  width="100%"
                >
                  {isMission ? 'Misión completada' : 'Reto completado'}
                </Button>
              ) : (
                <Button
                  onClick={() => openChallengeItemModal()}
                  border="2px solid black"
                  boxShadow="1px 1px 0px 0px #000"
                  borderRadius="16px"
                  bg="#FFFFF2"
                  color="black"
                  width="100%"
                >
                  Ver {isMission ? 'misión' : 'reto'}
                </Button>
              )}
            </Box>
          ) : isParticipating && isDeclined ? (
            <Button
              onClick={() => openChallengeItemModal()}
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
            >
              Repetir {isMission ? 'misión' : 'reto'}
            </Button>
          ) : (
            <Button
              onClick={() => openChallengeItemModal()}
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
            >
              {isFull && !isSubscribed
                ? 'Participar 🔒'
                : isSubscribed && isFull
                  ? 'En espera'
                  : isComingSoon
                    ? 'Disponible pronto 🔒'
                    : 'Participar'}
            </Button>
          )}
        </Box>

        {/* Modal vídeo */}
        <VideoModal
          brandName={brand.name}
          videoSrc={videoExampleUrls[0]}
          isOpen={isOpenVideoModal}
          close={onCloseVideoModal}
        />
      </Box>

      {/* Modal completo con los datos del reto */}
      <ChallengeItemModal
        isMission={isMission}
        userCanParticipateInMissions={userCanParticipateInMissions}
        hasAcceptedConditions={hasAcceptedConditions}
        toggleHasAcceptedConditions={toggleHasAcceptedConditions}
        challenge={challengeData}
        moneyToClaim={moneyToClaim}
        isOpen={isOpenChallengeItemModal}
        isComingSoon={isComingSoon}
        isFull={isFull}
        maxVideos={maxParticipants}
        participantsCount={participantsCount}
        isParticipating={isParticipating}
        isLoadingParticipate={isLoadingParticipate}
        isSubscribed={isSubscribed}
        isClaimable={isClaimable}
        isClaimed={isClaimed}
        isDeclined={isDeclined}
        closeModal={onCloseChallengeItemModal}
        openVideoModal={onOpenVideoModal}
        participate={participateInChallenge}
        unparticipate={stopParticipatingInChallenge}
        isLoadingStopParticipating={isLoadingStopParticipating}
        repeat={onRepeatChallenge}
        claim={claimMoney2}
        isLoadingClaim={isLoadingClaim}
        notifyMe={notifyMeWhenPlaceAvailable}
      />
    </>
  );
}
