import React from 'react';

import { Box, Heading, Text, Flex, Button } from '@chakra-ui/react';

export default function ReferralCard() {
  return (
    <Box
      minWidth="300px"
      maxWidth="300px"
      bg="#fffff2"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      border="2px solid black"
      p={3}
      mr={3}
      position="relative"
    >
      <Box>
        <Heading fontSize="16px">Invita a un amigo y ganad 2€</Heading>
        <Text fontSize="12px" lineHeight={1.2} mt={2}>
          Ganaréis 1€ cada uno cuando tu amigx complete un reto con éxito.
        </Text>
      </Box>
      <Flex mt={3} mb={3} justifyContent="space-between">
        <Flex flexDirection="column">
          <Text fontSize="12px" fontWeight="bold">
            Recompensa
          </Text>
          <Text fontSize="12px">1€</Text>
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize="12px" fontWeight="bold">
            Tiempo estimado
          </Text>
          <Text fontSize="12px">3 min</Text>
        </Flex>
      </Flex>
      <Button
        height="38px"
        fontSize="14px"
        _hover={{ bg: '#000000' }}
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        borderRadius="16px"
        bg="#FFFFF2"
        color="black"
        width="100%"
        size="md"
      >
        Invitar amigxs
      </Button>
      <Box
        fontFamily="DM Sans"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="0"
        left="0"
        height="100%"
        width="100%"
        background="#edf2f7e6"
        position="absolute"
        borderRadius="16px"
      >
        <Flex maxWidth="250px">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Text fontSize="25px">🔐</Text>
          </Box>
          <Box marginLeft="10px">
            <Text fontWeight="bold">GANA DINERO INVITANDO</Text>
            <Text fontSize="12px" lineHeight={1.3} fontWeight="500">
              Completa un reto para poder ganar dinero invitando a amigxs
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
