/* eslint-disable no-console */

import React from 'react';
import { Box, Text, List, ListItem, ListIcon } from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';

export default function ChallengeItemModalRequirements({ challenge }) {
  if(challenge.requirements.length === 0) return null;
  return (
    <Box pb={5} pl={5} pr={5}>
      <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
        Requisitos
      </Text>
      <Box>
        <List>
          {challenge?.requirements && challenge?.requirements.map((r) => (
            <ListItem display="flex" alignItems="center" key={r} fontFamily="DM Sans" fontSize="14px">
              <ListIcon as={CheckIcon} color="black" />
              {r}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
