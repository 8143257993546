import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react';

import { useUpdateCollabMutation } from '../../../../Redux/features/collabs/collabsApiSlice';

export default function BriefingClaimPaymentModal({
  collabData,
  isOpenClaimPaymentModal,
  onCloseClaimPaymentModal,
}) {
  // Redux
  const [updateCollab, { isLoading: isLoadingUpdateCollab }] = useUpdateCollabMutation();

  // Toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 8000,
    });
  };

  // Función de reclamar el pago
  const handleClaimPayment = async () => {
    await updateCollab({ collabId: collabData._id, status: 'claiming' });
    showToast(
      'Pago solicitado con éxito. Nos pondremos en contacto contigo en las próximas 48h para hacerte el pago.',
      'success',
    );
    onCloseClaimPaymentModal();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpenClaimPaymentModal}
      onClose={onCloseClaimPaymentModal}
      closeOnOverlayClick
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="24px" m={5}>
        <ModalCloseButton />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          <Box mt={5}>
            <Text fontSize="20px" fontWeight="bold" mb={3}>
              Reclama tu pago
            </Text>
            <Text fontSize="14px">
              Cuando pulses en ‘Reclamar Pago’ nos pondremos en contacto contigo para gestionar
              personalmente tu pago de esta colaboración.
            </Text>

            <Button
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
              onClick={handleClaimPayment}
              mt={10}
              isLoading={isLoadingUpdateCollab}
            >
              Reclamar pago
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
