import { Box, Heading, Text, Badge} from '@chakra-ui/react';

import UGCCard from './UGCCard';

export default function UGCSection() {
  return (
    <Box maxWidth="700px" margin="0 auto">
      <Box mt={5}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          UGC by CRUWI <Badge ml={1} bg="#EBD0FF">NUEVO</Badge>
        </Heading>
        <Text fontStyle="italic" opacity="0.8" fontSize="12px">
          Descubre la nueva sección de UGC de CRUWI
        </Text>
      </Box>

      <Box display="flex" mt={5} overflowX="auto" pb={7}>
        <UGCCard />
      </Box>
    </Box>
  );
}
