import { Flex, Text, Image } from '@chakra-ui/react';
import { BsJournalRichtext } from 'react-icons/bs';
import CollabIcon from '../../Assets/Images/fa-solid_hands-helping.svg';

export default function NoBriefingsOrCollabsAvailables({ isCollab }) {
  const icon = isCollab ? <Image src={CollabIcon} height="30px" /> : <BsJournalRichtext size="30px" />;
  return (
    <Flex
      fontFamily="DM Sans"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      minH="300px"
    >
      {icon}
      {isCollab ? (
        <Text mt={3} fontSize="14px">
          Aún no tienes colaboraciones
        </Text>
      ) : (
        <Text mt={3} fontSize="14px">
          No hay briefings disponibles para ti
        </Text>
      )}
    </Flex>
  );
}
