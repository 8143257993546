import apiSlice from '../../api/apiSlice';

export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    markNotificationsAsRead: builder.mutation({
      query: (notificationsIdArray) => ({
        url: `user/notifications/read`,
        method: 'POST',
        body: notificationsIdArray,
      }),
      invalidatesTags: ['UserData'],
    }),
  }),
});

export const {
  useMarkNotificationsAsReadMutation
} = notificationsApiSlice;
