// Main imports
import React from "react";
import ReactDOM from "react-dom/client";
import * as amplitude from "@amplitude/analytics-browser";

// Redux
import { Provider } from "react-redux";

// Chakra UI
import { ChakraProvider } from '@chakra-ui/react';

// Web Vitals
import reportWebVitals from "./reportWebVitals";

// Main component
import App from "./App";

// RTK Store
import store from "./Redux/store";

// Translation Configuration
import "./i18n";

// Amplitude initialization
const amplitudeProductionApiKey = "8b826e9543921125d139ec0e19d9c13b";
const amplitudeStagingApiKey = "833ac2672b5f04057ffaa2b71872f395";

if (process.env.NODE_ENV === "production") {
  amplitude.init(amplitudeProductionApiKey);
} else {
  amplitude.init(amplitudeStagingApiKey);
}

// Main APP
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
