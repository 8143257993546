import React from 'react';
import { Box, Image } from '@chakra-ui/react';

import { CloseIcon } from '@chakra-ui/icons';

import ChallengeDefaultHeaderImage from '../../../../Assets/Images/challenge-modal_header.png';

export default function ChallengeItemModalHeader({ challenge, closeModal }) {
  return (
    <Box
      height="150px"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundImage={`url("${challenge.bannerUrl || ChallengeDefaultHeaderImage}")`}
      position="relative"
      borderRadius="6px"
    >
      <Box height="150px" display="flex" justifyContent="initial" alignItems="center">
        <Image
          marginLeft="30px"
          borderRadius="50%"
          width="80px"
          height="80px"
          border="1px solid white"
          bg="white"
          src={challenge?.brand.logoUrl}
        />
      </Box>
      <Box
        onClick={closeModal}
        backgroundColor="white"
        height="40px"
        width="40px"
        border="1px solid"
        borderRadius="50%"
        position="absolute"
        top={4}
        right={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CloseIcon _hover={{ cursor: 'pointer' }} width={5} />
      </Box>
    </Box>
  );
}
