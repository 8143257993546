// Función para obtener el texto de las edades elegidas
export default function createAgeText(ageRanges) {
  // Check if "Any age" is in the array
  if (ageRanges.includes('Any age')) {
    return 'de cualquier edad';
  }

  // Check if "45+" is in the array
  const containsOver45 = ageRanges.includes('45+');
  let minAge = 0;
  let maxAge = 0;

  if (containsOver45) {
    // Remove "45+" for processing other ranges
    ageRanges = ageRanges.filter((ageRange) => ageRange !== '45+');

    // Find the minimum age from the remaining ranges
    if (ageRanges.length > 0) {
      minAge = Math.min(...ageRanges.map((ageRange) => parseInt(ageRange.split('-')[0], 10)));
      return `de entre ${minAge} y más de 45 años de edad`;
    }

    // If only "45+" is in the array
    return 'de más de 45 años';
  }

  // Find the minimum and maximum ages from the ranges
  minAge = Math.min(...ageRanges.map((ageRange) => parseInt(ageRange.split('-')[0], 10)));
  maxAge = Math.max(...ageRanges.map((ageRange) => parseInt(ageRange.split('-')[1], 10)));

  return `de entre ${minAge} y ${maxAge} años de edad`;
}
