import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import './ChallengeItemModalDescription.css';

export default function ChallengeItemModalDescription({ isMission, description, challenge }) {
  const { isInstagram } = challenge;
  return (
    <Box p="0px 20px 20px 20px">
      <Text fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={4}>
        {isMission ? 'Misión' : 'Reto'} {challenge?.title}
      </Text>

      <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
        Plataforma
      </Text>
      <Box mb={2}>
        <Text fontFamily="DM Sans" fontSize="14px">
          Es un reto en {isInstagram ? 'Instagram' : 'TikTok'}, por lo que debes subirlo a tu cuenta de {isInstagram ? 'Instagram' : 'TikTok'}.
        </Text>
      </Box>
     
      <Text fontFamily="DM Sans" mb={2} fontWeight="bold">
        Descripción {isMission ? 'de la misión' : 'del reto'}
      </Text>
      <div className="description-html">
        <Box
          fontFamily="DM Sans"
          fontSize="14px"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Box>
  );
}
