import { Flex, Text } from '@chakra-ui/react';

export default function LinkItemInAppText({ challenge }) {
  const { brand: { name } } = challenge;
  return (
    <Flex mt={5} mb={5} justifyContent="center">
      <Text fontSize="12px">
      Encuentra el link y tu código en la app de {name}.
      </Text>
    </Flex>
  );
}
