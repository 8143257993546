import apiSlice from '../../api/apiSlice';

export const briefingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBriefings: builder.query({
      query: () => 'briefings',
      providesTags: ['Briefings'],
    }),
    applyToBriefing: builder.mutation({
      query: ({ briefingId, videos }) => ({
        url: `briefings/${briefingId}/participate`,
        method: 'POST',
        body: {
          videos,
        },
      }),
      invalidatesTags: ['Briefings'],
    }),
  }),
});

export const {
  useGetBriefingsQuery,
  useApplyToBriefingMutation,
} = briefingsApiSlice;
