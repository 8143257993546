/* eslint-disable no-console */

import { useCallback } from 'react';

const useShareApi = () => {
  const share = useCallback(async ({ title, text, url }) => {

    if (!navigator.share) {
      console.warn('Sorry, but your browser does not support the Share API');
      return;
    }

    try {
      await navigator.share({
        title,
        text,
        url,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  }, []);

  return share;
};

export default useShareApi;