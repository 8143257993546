import React from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Flex, Text, Image, useMediaQuery } from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';

import HomeIcon from '../../Assets/Images/fluent_home-20-filled.svg';
import CollabIcon from '../../Assets/Images/fa-solid_hands-helping.svg';
import UGCProfileIcon from '../../Assets/Images/iconamoon_profile-fill.svg';

export default function FooterNavUGC() {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // TODO: Mejorar esta lógica..
  const location = useLocation();
  const isUgcHomePage = location.pathname === '/ugc';
  const isUgcCollabPage = location.pathname === '/ugc/collabs';
  const isUgcProfilePage = location.pathname === '/ugc/profile';
  return (
    <Flex
      fontFamily="DM Sans"
      maxWidth="700px"
      margin="0 auto"
      boxShadow="0 -3px 10px 1px #b79f9f6e"
      bg="#ddded1"
      width="100%"
      left="50%"
      transform="translateX(-50%)"
      position="fixed"
      bottom={0}
      height="50px"
      borderRadius={isLargerThan768 ? "24px 24px 0px 0px" : ''}
      px={5}
    >
      <Box display="flex" alignItems="center" justifyContent="left" width="100%">
        <CustomLink to="/ugc">
          <Box
            border="2px solid black"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100px"
            padding="6px"
            bg={isUgcHomePage ? '#EBD0FF' : 'transparent'}
            boxShadow="1px 1px 0px 0px #000"
          >
            <Image mr={1} boxSize="16px" objectFit="cover" src={HomeIcon} alt="Home Icon" />
            <Text fontWeight="bold" fontSize="14px">
              Home
            </Text>
          </Box>
        </CustomLink>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CustomLink to="/ugc/collabs">
          <Box
            border="2px solid black"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100px"
            padding="6px"
            bg={isUgcCollabPage ? '#EBD0FF' : 'transparent'}
            boxShadow="1px 1px 0px 0px #000"
          >
            <Image mr={1} boxSize="20px" objectFit="cover" src={CollabIcon} alt="Collabs Icon" />
            <Text fontWeight="bold" fontSize="14px">
              Collabs
            </Text>
          </Box>
        </CustomLink>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="right" width="100%">
        <CustomLink to="/ugc/profile">
          <Box
            border="2px solid black"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100px"
            padding="6px"
            bg={isUgcProfilePage ? '#EBD0FF' : 'transparent'}
            boxShadow="1px 1px 0px 0px #000"
          >
            <Image
              mr={1}
              boxSize="20px"
              objectFit="cover"
              src={UGCProfileIcon}
              alt="Profile UGC Icon"
            />
            <Text fontWeight="bold" fontSize="14px">
              Perfil
            </Text>
          </Box>
        </CustomLink>
      </Box>
    </Flex>
  );
}
