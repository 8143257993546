/* eslint-disable no-console */

import React from 'react';
import { Box, Text, List, ListItem } from '@chakra-ui/react';


export default function ChallengeItemModalNecessaryLinks({ challenge }) {
  if(challenge.necessaryLinks.length === 0) return null;
  return (
    <Box pb={5} pl={5} pr={5}>
      <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
        Enlaces
      </Text>
      <Box>
        <List>
          {challenge?.necessaryLinks && challenge?.necessaryLinks.map((r) => (
            <ListItem  alignItems="center" key={r.url} fontFamily="DM Sans" fontSize="14px">
              <Text>
              {r.text}: <a style={{ textDecoration: 'underline' }} href={r.url} target="_blank" rel="noopener noreferrer">{r.url}</a>
              </Text>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
