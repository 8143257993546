/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Divider,
  Box,
  Flex,
  Input,
  useMediaQuery,
  useToast,
  Button,
  Popover,
  Checkbox,
  PopoverTrigger,
  FormControl,
  FormLabel,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  UnorderedList,
  Switch,
  ListItem,
} from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import { setUserData } from '../../Redux/features/auth/authSlice';
import {
  useUpdateMutation,
  useWithdrawBalanceMutation,
} from '../../Redux/features/auth/authApiSlice';

import formatCentsToEurs from '../../Utils/formatCentsToEurs';
import validateDNIOrNIE from '../../Utils/validateDNIOrNIE';
import capitalizeFirstLetter from '../../Utils/capitalizeFirstLetter';

import AnimatedCheck from '../AnimatedCheck/AnimatedCheck';

import './KYCModal.css';

export default function KYCModal({ step, setStep, showEditData, isOpen, close, balance }) {
  // Toast and show toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 8000,
    });
  };

  // Redux
  const dispatch = useDispatch();
  const [update] = useUpdateMutation();
  const [withdrawBalance, { isLoading }] = useWithdrawBalanceMutation();

  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Nombre
  const [name, setName] = useState('');
  const handleChangeName = (e) => setName(e.target.value);

  // Primer Apellido
  const [firstSurname, setFirstSurname] = useState('');
  const handleChangeFirstSurname = (e) => setFirstSurname(e.target.value);

  // Segundo Apellido
  const [secondSurname, setSecondSurname] = useState('');
  const handleChangeSecondSurname = (e) => setSecondSurname(e.target.value);

  // DNI
  const [dni, setDNI] = useState('');
  const handleChangeDNI = (e) => setDNI(e.target.value);

  // DNI
  const [nie, setNie] = useState('');
  const handleChangeNie = (e) => setNie(e.target.value);

  // Es NIE o DNI
  const [isNieChecked, setIsNieChecked] = useState(false);
  const handleSwitchIsNieChecked = () => {
    setIsNieChecked(!isNieChecked);
  };

  // Calle
  const [street, setStreet] = useState('');
  const handleChangeStreet = (e) => setStreet(e.target.value);

  // Número
  const [streetNumber, setStreetNumber] = useState('');
  const handleChangeStreetNumber = (e) => setStreetNumber(e.target.value);

  // Piso
  const [floor, setFloor] = useState('');
  const handleChangeFloor = (e) => setFloor(e.target.value);

  // Localidad
  const [locality, setLocality] = useState('');
  const handleChangeLocality = (e) => setLocality(e.target.value);

  // Código postal
  const [postalCode, setPostalCode] = useState('');
  const handleChangePostalCode = (e) => setPostalCode(e.target.value);

  // País
  const [country, setCountry] = useState('');
  const handleChangeCountry = (e) => setCountry(e.target.value);

  // User sign contract terms
  const [sign, setSign] = useState(false);

  const sendDataToValidationStep = () => {
    if (
      name === '' ||
      firstSurname === '' ||
      street === '' ||
      streetNumber === '' ||
      locality === '' ||
      postalCode === '' ||
      country === ''
    ) {
      showToast('Por favor, rellena todos los campos', 'error');
      return;
    }

    const identificationNumberToValidate = isNieChecked ? nie : dni;

    if (!validateDNIOrNIE(identificationNumberToValidate)) {
      showToast(`Por favor, introduce un ${isNieChecked ? 'NIE' : 'DNI'} válido`, 'error');
      return;
    }

    setStep(1);
  };

  // Acción de retirar saldo
  const handleUpdateDataAndWithdrawBalance = async () => {
    if (!sign && !showEditData) {
      showToast('Debes aceptar los términos y condiciones de creador', 'error');
      return;
    }

    const finalAddress = `${capitalizeFirstLetter(street)}, ${streetNumber} ${floor} - ${postalCode}, ${capitalizeFirstLetter(locality)}, ${capitalizeFirstLetter(country)}`;

    const data = {
      taxData: {
        name: capitalizeFirstLetter(name),
        firstSurname: capitalizeFirstLetter(firstSurname),
        secondSurname: capitalizeFirstLetter(secondSurname),
        dni: isNieChecked ? nie : dni,
        address: finalAddress,
        hasSigned: sign,
      },
    };

    try {
      if (!showEditData) {
        const userUpdatedData = await update(data).unwrap();
        showToast('Tus datos fiscales se han enviado con éxito', 'success');
        dispatch(setUserData(userUpdatedData));
      }
      await withdrawBalance().unwrap();
      showToast(
        'Hemos recibido tu solicitud de retirada. Realizaremos el pago el próximo día 28.',
        'success',
      );
      setStep(2);
    } catch (error) {
      showToast('Hubo un problema enviando tus datos fiscales', 'error');
      console.log(error);
    }
  };

  // Montante final (ecuación)
  const finalAmountToWithdraw = formatCentsToEurs(
    balance - balance * 0.05 - (balance - balance * 0.05) * 0.15,
  );

  // Paso 1: Introduce datos de KYC
  const DataStep = (
    <Flex bg="#fffff2" direction="column" p={5}>
      <Box flex="1" pt={5}>
        <Text mb={2} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
          Antes de continuar...
        </Text>

        <Text fontSize="16px" fontFamily="DM Sans" lineHeight={1.3} mb={5}>
          Para poder hacerte los pagos con seguridad, necesitamos tus datos fiscales. Tranqui, no te
          los volveremos a pedir ;)
        </Text>

        <Text fontSize="16px" fontFamily="DM Sans" lineHeight={1.3} mb={3}>
          Datos básicos
        </Text>

        <Box mb="15px">
          <Text fontSize="14px" fontFamily="DM Sans" fontWeight="semibold" lineHeight={1.1} mb={1}>
            Nombre*
          </Text>
          <Input
            value={name}
            onChange={handleChangeName}
            size="sm"
            placeholder="Nombre"
            borderRadius="8px"
            bg="white"
          />
        </Box>

        <Box mb="15px">
          <Text fontSize="14px" fontFamily="DM Sans" fontWeight="semibold" lineHeight={1.1} mb={1}>
            Primer Apellido*
          </Text>
          <Input
            value={firstSurname}
            onChange={handleChangeFirstSurname}
            size="sm"
            borderRadius="8px"
            bg="white"
            placeholder="Primer apellido"
          />
        </Box>

        <Box mb="15px">
          <Text fontSize="14px" fontFamily="DM Sans" fontWeight="semibold" lineHeight={1.1} mb={1}>
            Segundo Apellido (si tiene)
          </Text>
          <Input
            value={secondSurname}
            onChange={handleChangeSecondSurname}
            size="sm"
            borderRadius="8px"
            bg="white"
            placeholder="Segundo apellido"
          />
        </Box>

        {isNieChecked ? (
          <Box mb="10px">
            <Text
              fontSize="14px"
              fontFamily="DM Sans"
              fontWeight="semibold"
              lineHeight={1.1}
              mb={1}
            >
              NIE*
            </Text>
            <Input
              value={nie}
              onChange={handleChangeNie}
              size="sm"
              borderRadius="8px"
              bg="white"
              placeholder="NIE"
            />
          </Box>
        ) : (
          <Box mb="10px">
            <Text
              fontSize="14px"
              fontFamily="DM Sans"
              fontWeight="semibold"
              lineHeight={1.1}
              mb={1}
            >
              DNI*
            </Text>
            <Input
              value={dni}
              onChange={handleChangeDNI}
              size="sm"
              borderRadius="8px"
              bg="white"
              placeholder="DNI"
            />
          </Box>
        )}

        <FormControl mb="30px" display="flex" alignItems="center">
          <FormLabel fontWeight="medium" fontSize="12px" htmlFor="isNie" mb="0">
            Cambiar DNI por NIE
          </FormLabel>
          <Switch id="switch" isChecked={isNieChecked} onChange={handleSwitchIsNieChecked} />
        </FormControl>

        <Text fontSize="16px" fontFamily="DM Sans" lineHeight={1.3} mb={3}>
          Dirección completa
        </Text>

        <Box mb="15px">
          <Text fontSize="14px" fontFamily="DM Sans" fontWeight="semibold" lineHeight={1.1} mb={1}>
            Calle*
          </Text>
          <Input
            value={street}
            onChange={handleChangeStreet}
            size="sm"
            placeholder="Calle"
            borderRadius="8px"
            bg="white"
          />
        </Box>

        <Flex justifyContent="space-between">
          <Box mb="15px" w="100%" mr={4}>
            <Text
              fontSize="14px"
              fontFamily="DM Sans"
              fontWeight="semibold"
              lineHeight={1.1}
              mb={1}
            >
              Número*
            </Text>
            <Input
              value={streetNumber}
              onChange={handleChangeStreetNumber}
              size="sm"
              placeholder="Número"
              borderRadius="8px"
              bg="white"
            />
          </Box>

          <Box mb="15px" w="100%">
            <Text
              fontSize="14px"
              fontFamily="DM Sans"
              fontWeight="semibold"
              lineHeight={1.1}
              mb={1}
            >
              Piso (si procede)
            </Text>
            <Input
              value={floor}
              onChange={handleChangeFloor}
              size="sm"
              placeholder="Piso"
              borderRadius="8px"
              bg="white"
            />
          </Box>
        </Flex>

        <Flex justifyContent="space-between">
          <Box mb="15px" w="100%" mr={4}>
            <Text
              fontSize="14px"
              fontFamily="DM Sans"
              fontWeight="semibold"
              lineHeight={1.1}
              mb={1}
            >
              Localidad*
            </Text>
            <Input
              value={locality}
              onChange={handleChangeLocality}
              size="sm"
              placeholder="Localidad"
              borderRadius="8px"
              bg="white"
            />
          </Box>

          <Box mb="15px" w="100%">
            <Text
              fontSize="14px"
              fontFamily="DM Sans"
              fontWeight="semibold"
              lineHeight={1.1}
              mb={1}
            >
              Código postal*
            </Text>
            <Input
              value={postalCode}
              onChange={handleChangePostalCode}
              size="sm"
              placeholder="Código postal"
              borderRadius="8px"
              bg="white"
            />
          </Box>
        </Flex>

        <Box mb="15px">
          <Text fontSize="14px" fontFamily="DM Sans" fontWeight="semibold" lineHeight={1.1} mb={1}>
            País*
          </Text>
          <Input
            value={country}
            onChange={handleChangeCountry}
            size="sm"
            placeholder="País"
            borderRadius="8px"
            bg="white"
          />
        </Box>
      </Box>

      <Box marginTop="50px">
        <Button
          onClick={() => sendDataToValidationStep()}
          width="100%"
          _hover={{ bg: '#000000' }}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          bg="#000000"
          color="white"
          variant="solid"
          size="md"
        >
          Siguiente
        </Button>
      </Box>
    </Flex>
  );

  // Paso 2: Review datos y check (firma)
  const ReadyStep = (
    <Flex direction="column" p={5} minHeight="540px">
      <Box flex="1">
        <Text mb={2} fontSize={22} fontFamily="DM Sans" fontWeight="bold" lineHeight={1.2}>
          Información retirada
        </Text>

        <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={5}>
          El próximo día 28 revisaremos tu petición de retirada y, si está todo bien, recibirás el
          dinero al método de cobro que tienes configurado.
        </Text>

        <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={5}>
          Si hay cualquier problema, nos pondremos en contacto contigo :)
        </Text>

        <Box mb="30px">
          <Popover>
            <PopoverTrigger>
              <Text
                fontFamily="DM Sans"
                fontWeight="semibold"
                fontSize="16px"
                lineHeight={1.3}
                mb={2}
              >
                Desglose del pago <InfoOutlineIcon cursor="pointer" width="10px" />
              </Text>
            </PopoverTrigger>
            <PopoverContent boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontFamily="DM Sans" fontSize="12px" fontWeight="bold">
                Información retirada
              </PopoverHeader>
              <PopoverBody>
                <Text fontFamily="DM Sans" fontSize="12px">
                  El importe que recibirás será igual al saldo solicitado menos 2 conceptos. El
                  primer concepto es la comisión de CRUWI que es de un 5%. Posteriormente, sobre el
                  importe que queda (saldo - comisión de CRUWI), hay que aplicar otro 15% en
                  concepto de retenciones IRPF.
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <UnorderedList fontSize="14px">
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={2}>
                Balance bruto reclamado: {formatCentsToEurs(balance)}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={2}>
                Comisión de CRUWI (5%): {formatCentsToEurs(balance * 0.05)}
              </Text>
            </ListItem>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={5}>
                Retención (15%): {formatCentsToEurs((balance - balance * 0.05) * 0.15)}
              </Text>
            </ListItem>
          </UnorderedList>

          <Divider />

          <Text fontWeight="bold" fontFamily="DM Sans" fontSize="16px" lineHeight={1.3} mb={2} mt={2}>
            Importe final a recibir:{' '}
            {formatCentsToEurs(balance - balance * 0.05 - (balance - balance * 0.05) * 0.15)}
          </Text>
          <Divider />
        </Box>

        {!showEditData && (
          <Box>
            <Divider />

            <Box mt="30px">
              <Popover>
                <PopoverTrigger>
                  <Flex mb={3} alignItems="center" justifyContent="space-between">
                    <Text
                      fontFamily="DM Sans"
                      fontWeight="semibold"
                      fontSize="16px"
                      lineHeight={1.3}
                    >
                      Revisa tus datos <InfoOutlineIcon width="10px" />
                    </Text>
                    <Button
                      _hover={{ bg: '#ffffff' }}
                      paddingRight="12px"
                      paddingLeft="12px"
                      bg="#ffffff"
                      color="black"
                      variant="solid"
                      border="1px solid black"
                      size="xs"
                      onClick={() => setStep(0)}
                    >
                      Editar datos
                    </Button>
                  </Flex>
                </PopoverTrigger>
                <PopoverContent boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader fontFamily="DM Sans" fontSize="12px" fontWeight="bold">
                    Información retirada
                  </PopoverHeader>
                  <PopoverBody>
                    <Text fontFamily="DM Sans" fontSize="12px">
                      Estos son los datos que irán en tu factura. Asegúrate de tener todos los
                      campos bien escritos. Si hay algún error, por favor, corrígelo y vuelve a
                      pulsar en siguiente. De lo contrario, podrías tener problemas con el pago.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              <UnorderedList>
                <ListItem mb={1} fontFamily="DM Sans" fontSize="16px">
                  <b>Nombre:</b> {capitalizeFirstLetter(name)}
                </ListItem>
                <ListItem mb={1} fontFamily="DM Sans" fontSize="16px">
                  <b>Primer apellido:</b> {capitalizeFirstLetter(firstSurname)}
                </ListItem>
                <ListItem mb={1} fontFamily="DM Sans" fontSize="16px">
                  <b>Segundo apellido:</b> {capitalizeFirstLetter(secondSurname) || '-'}
                </ListItem>
                <ListItem mb={1} fontFamily="DM Sans" fontSize="16px">
                  <b>DNI / NIE:</b> {dni}
                </ListItem>
                <ListItem mb={1} fontFamily="DM Sans" fontSize="16px">
                  <b>Domicilio:</b>{' '}
                  {`${capitalizeFirstLetter(street)}, ${streetNumber} ${floor !== '' ? floor : ''} - ${postalCode} ${capitalizeFirstLetter(locality)}, ${capitalizeFirstLetter(country)}`}
                </ListItem>
              </UnorderedList>
              <Box mt={5}>
                <Checkbox
                  size="lg"
                  name="gdprAccepted"
                  isChecked={sign}
                  onChange={(e) => setSign(e.target.checked)}
                >
                  <Text fontSize="16px">
                    Acepto las{' '}
                    <a
                      style={{ textDecoration: 'underline' }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.cruwi.com/creators-conditions"
                    >
                      Condiciones de creador
                    </a>{' '}
                    .
                  </Text>
                </Checkbox>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Box marginTop="50px">
        <Button
          onClick={() => handleUpdateDataAndWithdrawBalance()}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          variant="solid"
          size="md"
          isLoading={isLoading}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          Reclamar {finalAmountToWithdraw}
        </Button>
      </Box>
    </Flex>
  );

  // Paso 3: Reclamación hecha con éxito
  const SuccessStep = (
    <Flex direction="column" p={5}>
      <Box flex="1">
        <Box>
          <AnimatedCheck />
        </Box>

        <Box maxWidth="250px" margin="0 auto">
          <Text textAlign="center" fontFamily="DM Sans" fontSize="14px" lineHeight={1.3} mb={5}>
            Tu solicitud de retirada se ha procesado con éxito
          </Text>
        </Box>
      </Box>
      <Box mt="20px">
        <Button
          onClick={() => close()}
          width="100%"
          _hover={{ bg: '#000000' }}
          fontFamily="DM Sans"
          paddingRight="12px"
          paddingLeft="12px"
          bg="#000000"
          color="white"
          variant="solid"
          size="md"
          isLoading={isLoading}
        >
          Cerrar
        </Button>
      </Box>
    </Flex>
  );

  // Renderizado de los pasos
  const renderStep = () => {
    if (step === 0) return DataStep;
    if (step === 1) return ReadyStep;
    return null;
  };

  if (step === 2) {
    return (
      <Modal
        size={isLargerThan768 ? 'sm' : 'sm'}
        isOpen={isOpen}
        onClose={close}
        isCentered
        scrollBehavior={isLargerThan768 ? 'inside' : 'outside'}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="24px"
          bg="#feffef"
          m={5}
          maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
        >
          <ModalCloseButton />
          <ModalBody>{SuccessStep}</ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      size={isLargerThan768 ? 'lg' : 'full'}
      isOpen={isOpen}
      onClose={close}
      isCentered
      scrollBehavior={isLargerThan768 ? 'inside' : 'outside'}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={isLargerThan768 ? '24px' : ''}
        bg="#feffef"
        maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
      >
        <ModalCloseButton />
        <ModalBody display="flex" direction="column" height="100%" m={0} padding={0}>
          {renderStep()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
