/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Flex,
  Button,
  useToast,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import { GoChevronLeft } from 'react-icons/go';

import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import HeaderUGC from '../../Components/HeaderUGC/HeaderUGC';
import LoadingBriefings from '../../Components/LoadingBriefings/LoadingBriefings';
import VideosUploadUGC from '../../Components/VideosUploadUGC/VideosUploadUGC';

import {
  useGetUserDataQuery,
  useUploadVideosMutation,
  useDeleteVideoMutation,
  useUpdateVideoMutation,
} from '../../Redux/features/auth/authApiSlice';

export default function UGCProfileEdit() {
  const {
    isOpen: isOpenModalSaveChanges,
    onOpen: onOpenModalSaveChanges,
    onClose: onCloseModalSaveChanges,
  } = useDisclosure();

  // Changes for desktop
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Toast and show toast
  const toast = useToast();
  const showToast = (message, type, duration) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration,
    });
  };

  // Navigation
  const navigate = useNavigate();

  // Redux
  const [uploadVideos] = useUploadVideosMutation();
  const [deleteVideo, { isLoading: isLoadingDeleteVideo }] = useDeleteVideoMutation();
  const [updateVideo, { isLoading: isLoadingUpdateVideo }] = useUpdateVideoMutation();

  // Add a state to track if any video has been uploaded or edited
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  // State for videos, their types, and categories
  const [videos, setVideos] = useState(
    Array.from({ length: 10 }, () => ({ url: '', file: null, type: '', category: '' })),
  );

  // Function to handle file change
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const newVideos = [...videos];
      newVideos[index] = { ...newVideos[index], url: URL.createObjectURL(file), file };
      setVideos(newVideos);
      setIsSaveDisabled(false); // Enable "Guardar" button
    }
  };

  // Function to handle removal of a video
  const handleRemove = (index) => {
    const newVideos = [...videos];
    newVideos[index] = { url: '', type: '', category: '' };
    setVideos(newVideos);
  };

  // Function to update video type
  const handleUpdateType = (event, index) => {
    const newVideos = [...videos];
    newVideos[index].type = event.target.value;
    setVideos(newVideos);
  };

  // Function to update video category
  const handleUpdateCategory = (event, index) => {
    const newVideos = [...videos];
    newVideos[index].category = event.target.value;
    setVideos(newVideos);
  };

  // Pedimos los datos del usuario
  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery();

  // Update videos state with user's existing videos
  useEffect(() => {
    if (user && user.ugcVideos) {
      // Sort the videos by most recently created date first
      const sortedVideos = [...user.ugcVideos].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      );

      const preFilledVideos = sortedVideos.map((v) => ({
        id: v._id,
        url: v.url,
        file: null,
        type: v.type,
        category: v.category,
        original: { type: v.type, category: v.category }, // Track original state
      }));

      const emptyVideos = Array.from({ length: 10 - preFilledVideos.length }, () => ({
        url: '',
        file: null,
        type: '',
        category: '',
      }));

      setVideos([...preFilledVideos, ...emptyVideos]);
    }
  }, [user]);

  // Mientras carga el usuario
  if (isUserLoading) {
    return <LoadingBriefings />;
  }

  // Función para enviar al usuario a otro lado
  const goTo = (url) => {
    navigate(url);
  };

  // Función que gestiona si el usuario pulsa en atrás sin guardar sus cambios..
  const handleGoBackAndCheckIfChanges = () => {
    if (isSaveDisabled === false) {
      onOpenModalSaveChanges();
    } else {
      goTo('/ugc/profile');
    }
  };

  // Función que elimina un vídeo, solo para cuando ya está en el perfil y tiene vídeos.
  const handleDeleteVideo = async (videoId) => {
    try {
      await deleteVideo({ userId: user.id, videoId }).unwrap();
      showToast('El vídeo ha sido eliminado correctamente', 'success');
    } catch (error) {
      showToast('Vaya, parece que hubo algún eror', 'error');
      console.log(error);
    }
  };

  // Enviamos los datos al backend
  const handleSubmit = async () => {
    // Check if there is changes in videos
    const updates = videos
      .filter(
        (video) =>
          video.original && // It has an original state
          (video.type !== video.original.type || video.category !== video.original.category), // Check for changes
      )
      .map(({ id, type, category }) => ({ id, type, category }));

    try {
      // Si hay actualizaciones, las hacemos primero
      if (updates.length > 0) {
        // Concurrently update videos
        const updatePromises = updates.map((video) =>
          updateVideo({
            userId: user.id,
            videoId: video.id,
            videoData: { type: video.type, category: video.category },
          }).unwrap(),
        );

        // Await all update promises to resolve
        await Promise.all(updatePromises);
        showToast('Videos actualizados correctamente', 'success');
        setIsSaveDisabled(true);
      }

      // Check if there are videos to upload
      const videosToUpload = videos.filter((video) => video.file);

      if (videosToUpload.length > 0) {
        const videosFormData = new FormData();
        videosToUpload.forEach((video, index) => {
          videosFormData.append('videos', video.file);
          videosFormData.append(`videoTypes[${index}]`, video.type);
          videosFormData.append(`videoCategories[${index}]`, video.category);
        });

        await uploadVideos({ userId: user.id, videosData: videosFormData }).unwrap();
        showToast(
          'Tus vídeos se están subiendo. Pueden tardar hasta 2 minutos en aparecer.',
          'success',
          5000,
        );

        setIsSaveDisabled(true);

        setTimeout(() => {
          goTo('/ugc/profile');
        }, 2000);
      }
    } catch (error) {
      showToast(
        'Vaya, parece que hubo algún error actualizando tus videos. Comprueba que no pesan más de 300MB',
        'error',
      );
      console.log(error);
    }
  };

  return (
    <Box bg="#ddded1" minH="100vh">
      <ScrollToTop />
      <HeaderUGC userData={user} />
      <Box pb={10} fontFamily="DM Sans">
        <Box mb={8} maxWidth="700px" margin="0 auto" fontFamily="DM Sans">
          {/* Textos */}
          <Box
            p="16px 16px 16px 16px"
            boxShadow={isLargerThan768 ? '' : '0px 4px 10px 0px #FFFFF2'}
          >
            <Flex
              cursor="pointer"
              onClick={() => handleGoBackAndCheckIfChanges()}
              alignItems="center"
              mb={2}
            >
              <GoChevronLeft size="14px" />
              <Text fontSize="14px">Atrás</Text>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize="12px" color="rgba(0, 0, 0, 0.64)">
                Sube tus mejores vídeos
              </Text>
              <Text fontSize="24px" fontWeight="bold" mb="8px">
                Tu portfolio
              </Text>
              <Text fontSize="12px" lineHeight={1.3}>
                Las marcas decidirán si colaborar contigo basándose en tus trabajos anteriores.
                Cuando vayas a aplicar a una oferta, eligirás entre los vídeos que tengas subidos en
                tu portfolio.
              </Text>
            </Box>
          </Box>

          {/* Modal guardar cambios */}
          <Modal
            onClose={onCloseModalSaveChanges}
            isOpen={isOpenModalSaveChanges}
            isCentered
            closeOnOverlayClick
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent borderRadius="24px" m={5}>
              <ModalCloseButton />
              <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
                <Box mt={5}>
                  <Text fontSize="20px" fontWeight="bold" mb={3}>
                    Tienes cambios sin guardar
                  </Text>
                  <Text fontSize="14px">
                    Has realizado cambios en tus vídeos y no se han guardado, ¿estás seguro que
                    quieres seguir sin guardar?
                  </Text>

                  <Button
                    border="2px solid black"
                    boxShadow="1px 1px 0px 0px #000"
                    borderRadius="16px"
                    bg="#EBD0FF"
                    color="black"
                    width="100%"
                    mt={10}
                    onClick={() => goTo('/ugc/profile')}
                  >
                    Sí, salir
                  </Button>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* Main Content */}
          <Box p="40px 24px 70px 24px">
            {/* Subir videos al portafolio */}
            <Box>
              <VideosUploadUGC
                isProfileEdit
                videos={videos}
                onFileChange={handleFileChange}
                onRemove={handleRemove}
                onUpdateType={handleUpdateType}
                onUpdateCategory={handleUpdateCategory}
                onDeleteVideo={handleDeleteVideo}
                isLoadingDeleteVideo={isLoadingDeleteVideo}
                setIsSaveDisabled={setIsSaveDisabled}
              />
            </Box>
            <Flex
              fontFamily="DM Sans"
              maxWidth="700px"
              margin="0 auto"
              boxShadow="0 -3px 10px 1px #b79f9f6e"
              bg="#ddded1"
              width={isLargerThan768 ? '652px' : '100%'}
              left="50%"
              transform="translateX(-50%)"
              position="fixed"
              bottom={0}
              height="70px"
              borderRadius={isLargerThan768 ? '12px 12px 0px 0px' : ''}
            >
              <Box width="100%" display="flex" alignItems="center" p="0px 24px">
                <Button
                  onClick={handleSubmit}
                  borderRadius="16px"
                  border="2px solid black"
                  bg="#EBD0FF"
                  width="100%"
                  isDisabled={isSaveDisabled}
                  isLoading={isLoadingUpdateVideo}
                >
                  Guardar
                </Button>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
