/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Text,
  Button,
  Flex,
  Image,
  Input,
  FormControl,
  FormLabel,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

import {
  useGetUserDataQuery,
  useUpdateMutation,
  useUploadVideosMutation,
} from '../../Redux/features/auth/authApiSlice';

import LoadingBriefings from '../../Components/LoadingBriefings/LoadingBriefings';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import CustomChip2 from '../../Components/CustomChip2/CustomChip2';
import VideosUploadUGC from '../../Components/VideosUploadUGC/VideosUploadUGC';

import DefaultProfileImage from '../../Assets/Images/default_profile_image.png';

const getRightGender = (gender) => {
  let label = 'Hombre';
  if (gender === 'female') {
    label = 'Mujer';
  }
  return label;
};

export default function UGCOnboarding() {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Toast and show toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery();

  // Redux
  const [update, { isLoading }] = useUpdateMutation();
  const [uploadVideos] = useUploadVideosMutation();

  const [currentStep, setCurrentStep] = useState(1);

  // Datos creador básicos
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    ugcTikTok: '',
    instagramUser: '',
    portfolioLink: '',
  });

  // Sexo
  const [selectedSex, setSelectedSex] = useState('');
  const handleSelectSex = (label) => {
    setSelectedSex(label);
  };

  // Fecha de nacimiento
  const [userBirthDate, setUserBirthDate] = useState(null);
  const handleChangeUserBirthDate = (e) => {
    setUserBirthDate(new Date(e.target.value));
  };

  // Update formData once user data is loaded
  useEffect(() => {
    // updateamos con los datos del usuario que ya tenga
    if (user) {
      setFormData({
        name: user.name || '',
        lastname: user.lastName || '',
        email: user.email || '',
        phone: user.phone || '',
        ugcTikTok: user.ugcTikTok || '',
        instagramUser: user.instagramUser || '',
        portfolioLink: user.portfolioLink || '',
      });
      setUserBirthDate(user.birthDate);
      setSelectedSex(getRightGender(user.gender));
    }
    // si ya ha pasado el onboardin, hasta luego, pasa a la home de UGC
    if (user && user.hasPassedUGCOnboarding) {
      navigate('/ugc');
    }
  }, [user, navigate]);

  // Imagen de perfil
  const [selectedImage, setSelectedImage] = useState((user && user.profilePicture) || null);
  const [selectedImageToSend, setSelectedImageToSend] = useState(null);
  const handleChangeLogo = (e) => {
    const file = e.target.files[0];
    setSelectedImageToSend(file);
    if (file) {
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // State for videos, their types, and categories
  // Add the videos state
  const [videos, setVideos] = useState(
    Array.from({ length: 10 }, () => ({ url: '', file: null, type: '', category: '' })),
  );

  // Function to handle file change
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const newVideos = [...videos];
      newVideos[index] = {
        ...newVideos[index],
        url: URL.createObjectURL(file),
        file,
      };
      setVideos(newVideos);
    }
  };

  // Function to handle removal of a video
  const handleRemove = (index) => {
    const newVideos = [...videos];
    newVideos[index] = { url: '', type: '', category: '' };
    setVideos(newVideos);
  };

  // Function to update video type
  const handleUpdateType = (event, index) => {
    const newVideos = [...videos];
    newVideos[index].type = event.target.value;
    setVideos(newVideos);
  };

  // Function to update video category
  const handleUpdateCategory = (event, index) => {
    const newVideos = [...videos];
    newVideos[index].category = event.target.value;
    setVideos(newVideos);
  };

  if (isUserLoading) {
    return <LoadingBriefings />;
  }

  // Cambio de paso
  const handleNext = () => {
    // Hacemos todos los campos obligatorios
    if (currentStep === 2) {
      if (
        formData.lastname === '' ||
        formData.name === '' ||
        selectedSex === '' ||
        Boolean(userBirthDate) === false
      ) {
        showToast('Debes rellenar todos los campos', 'warning');
        return;
      }
    }

    if (currentStep === 4) {
      if (formData.instagramUser === '' || formData.ugcTikTok === '') {
        showToast('Instagram y TikTok son obligatorios', 'warning');
        return;
      }
    }

    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === 'instagramUser') {
      newValue = value.startsWith('@') ? value.substring(1) : value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  // Enviamos los datos al backend
  const handleSubmit = async () => {
    // Junstamos los datos
    const creatorData = {
      ...formData,
      gender: selectedSex === 'Hombre' ? 'male' : 'female',
      birthDate: userBirthDate,
      hasPassedUGCOnboarding: true,
    };

    try {
      const creatorFormData = new FormData();

      // imagen si hay
      creatorFormData.append('image', selectedImageToSend);

      // todos los demás campos para actualizar al usuario
      creatorFormData.append('name', creatorData.name);
      creatorFormData.append('lastName', creatorData.lastname);
      creatorFormData.append('email', creatorData.email);
      creatorFormData.append('phone', creatorData.phone);
      creatorFormData.append('ugcTikTok', creatorData.ugcTikTok);
      creatorFormData.append('instagramUser', creatorData.instagramUser);
      creatorFormData.append('portfolioLink', creatorData.portfolioLink);
      creatorFormData.append('gender', creatorData.gender);
      creatorFormData.append('birthDate', creatorData.birthDate);
      creatorFormData.append('hasPassedUGCOnboarding', creatorData.hasPassedUGCOnboarding);

      await update(creatorFormData).unwrap();
      showToast('Tu perfil ha sido actualizado', 'success');

      // Check if there are videos to upload
      const videosToUpload = videos.filter((video) => video.file);
      if (videosToUpload.length > 0) {
        const videosFormData = new FormData();
        videosToUpload.forEach((video, index) => {
          videosFormData.append('videos', video.file);
          videosFormData.append(`videoTypes[${index}]`, video.type);
          videosFormData.append(`videoCategories[${index}]`, video.category);
        });

        await uploadVideos({ userId: user.id, videosData: videosFormData }).unwrap();
        showToast(
          'Tus vídeos se están subiendo.  Puedes seguir navegando y verlos en tu perfil una vez estén subidos.',
          'success',
        );

        setTimeout(() => {
          navigate('/ugc');
        }, 3000);
      }
    } catch (error) {
      showToast(
        'Vaya, parece que hubo algún error con el onboarding. Contacta con nosotros ',
        'error',
      );
      console.log(error);
    }
  };

  // Bienvenida e información
  const step1 = (
    <Box>
      <Swiper pagination modules={[Pagination]} spaceBetween={50} slidesPerView={1}>
        {/* Primera diapositiva */}
        <SwiperSlide
          style={{
            padding: '24px',
            minHeight: '75vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box textAlign="center">
            <Text fontWeight="bold" fontSize="24px" lineHeight={1.3} mb={5}>
              Bienvenido a
            </Text>
            <Image
              margin="0 auto"
              src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
              alt="CRUWI Logo"
              width="200px"
              mb={2}
            />
            <Text lineHeight={1} fontSize="50px" fontWeight="black">
              UGC
            </Text>
            <Text fontWeight="bold" fontSize="24px" lineHeight={1.3} mt={5}>
              Consigue colaboraciones UGC con tus marcas favoritas
            </Text>
          </Box>
        </SwiperSlide>

        {/* Segunda diapositiva */}
        <SwiperSlide
          style={{
            padding: '24px',
            minHeight: '75vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box textAlign="left" p={4}>
            <Box textAlign="center" mb={4}>
              <Image
                margin="0 auto"
                src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
                alt="CRUWI Logo"
                width="100px"
                mb="3px"
              />
              <Text lineHeight={1} fontSize="20px" fontWeight="black">
                UGC
              </Text>
            </Box>

            <Text fontWeight="bold" fontSize="16px" lineHeight={1.3} mb={1}>
              ¿Por qué lanzamos CRUWI UGC?
            </Text>
            <Text lineHeight={1.2} fontSize="16px" fontWeight="normal" mb={4}>
              Buscamos facilitaros siempre formas de monetizar lo que se os da bien. Y el UGC es una
              de las mejores formas.
            </Text>
            <Text fontWeight="bold" fontSize="16px" lineHeight={1.3} mb={1}>
              ¿Para quién es?
            </Text>
            <Text lineHeight={1.2} fontSize="16px" fontWeight="normal" mb={4}>
              Para creadoras y creadores con cierta experiencia que buscan colaborar con marcas a
              cambio de producto y cash; sin importar tu número de seguidores.
            </Text>
            <Text fontWeight="bold" fontSize="16px" lineHeight={1.3} mb={1}>
              ¿Quién tiene acceso?
            </Text>
            <Text lineHeight={1.2} fontSize="16px" fontWeight="normal">
              Sólo creadores con invitación. El acceso de momento es limitado.
            </Text>
          </Box>
        </SwiperSlide>
      </Swiper>
      <Box maxW="180px" m="20px auto 0 auto">
        <Text fontWeight="normal" lineHeight={1.2} textAlign="center" fontSize="12px">
          Desliza para saber por qué y para quién lo creamos
        </Text>
      </Box>
    </Box>
  );

  // Datos básicos
  const step2 = (
    <Box textAlign="left" p="24px">
      <Box textAlign="center" mb={4}>
        <Image
          margin="0 auto"
          src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
          alt="CRUWI Logo"
          width="100px"
          mb="3px"
        />
        <Text lineHeight={1} fontSize="20px" fontWeight="black">
          UGC
        </Text>
      </Box>

      <Text lineHeight={1.2} fontSize="16px" fontWeight="normal" mb={5}>
        Necesitamos algunos datos que nos ayuden a conseguirte colaboraciones y podamos ponerte en
        contacto con las marcas. Si ya tenías cuenta en CRUWI, verás datos completados. Corrígelos
        si no están bien.
      </Text>
      <Text fontWeight="bold" fontSize="22px" lineHeight={1.3} mb={1}>
        Empecemos por lo básico
      </Text>
      <Text lineHeight={1.2} fontSize="16px" fontWeight="normal" mb={4}>
        Las marcas suelen buscar perfiles por género y edad. Bueno y conocer cómo te llamas siempre
        es importante.
      </Text>

      <Box>
        <FormControl mb={2}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Nombre
          </FormLabel>
          <Input
            name="name"
            value={formData.name}
            onChange={(e) => handleInputChange(e, 'name')}
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
        <FormControl mb={2}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Apellidos
          </FormLabel>
          <Input
            name="lastname"
            value={formData.lastname}
            onChange={(e) => handleInputChange(e, 'lastname')}
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
        <FormControl mb={2}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Sexo
          </FormLabel>
          <Box>
            <CustomChip2
              label="Mujer"
              isSelected={selectedSex === 'Mujer'}
              onSelected={handleSelectSex}
            />
            <CustomChip2
              label="Hombre"
              isSelected={selectedSex === 'Hombre'}
              onSelected={handleSelectSex}
            />
          </Box>
        </FormControl>
        <FormControl mb={2}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Fecha de nacimiento
          </FormLabel>
          <Input
            value={userBirthDate ? new Date(userBirthDate).toISOString().split('T')[0] : ''}
            onChange={handleChangeUserBirthDate}
            type="date"
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
      </Box>
    </Box>
  );

  // Contacto
  const step3 = (
    <Box textAlign="left" p="24px">
      <Box textAlign="center" mb={4}>
        <Image
          margin="0 auto"
          src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
          alt="CRUWI Logo"
          width="100px"
          mb="3px"
        />
        <Text lineHeight={1} fontSize="20px" fontWeight="black">
          UGC
        </Text>
      </Box>

      <Text fontWeight="bold" fontSize="22px" lineHeight={1.3} mb={1}>
        Tendremos que contactarte
      </Text>
      <Text lineHeight={1.2} fontSize="16px" fontWeight="normal" mb={4}>
        Si ya tenías cuenta en CRUWI, verás datos completados. Corrígelos si no están bien.
      </Text>

      <Box>
        <FormControl mb={4}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Email
          </FormLabel>
          <Input
            disabled
            name="email"
            value={formData.email}
            onChange={(e) => handleInputChange(e, 'email')}
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
        <FormControl mb={2}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Móvil
          </FormLabel>
          <Input
            name="phone"
            value={formData.phone}
            onChange={(e) => handleInputChange(e, 'phone')}
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
      </Box>
    </Box>
  );

  // Redes sociales
  const step4 = (
    <Box textAlign="left" p="24px">
      <Box textAlign="center" mb={4}>
        <Image
          margin="0 auto"
          src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
          alt="CRUWI Logo"
          width="100px"
          mb="3px"
        />
        <Text lineHeight={1} fontSize="20px" fontWeight="black">
          UGC
        </Text>
      </Box>

      <Text fontWeight="bold" fontSize="22px" lineHeight={1.3} mb={1}>
        Tus redes sociales
      </Text>
      <Text lineHeight={1.2} fontSize="16px" fontWeight="normal" mb={4}>
        Si ya tenías cuenta en CRUWI, verás datos completados. Corrígelos si no están bien.
      </Text>

      <Box>
        <FormControl mb={4}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            TikTok
          </FormLabel>
          <Input
            name="ugcTikTok"
            value={formData.ugcTikTok}
            onChange={(e) => handleInputChange(e, 'ugcTikTok')}
            placeholder="@cruwi"
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Instagram (sin @)
          </FormLabel>
          <Input
            name="instagramUser"
            value={formData.instagramUser}
            onChange={(e) => handleInputChange(e, 'instagram')}
            placeholder="cruwi"
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
        <FormControl mb={2}>
          <FormLabel mb={0} fontWeight="bold" fontSize="12px">
            Enlace al portafolio (opcional)
          </FormLabel>
          <Input
            name="portfolioLink"
            value={formData.portfolioLink}
            onChange={(e) => handleInputChange(e, 'portfolioLink')}
            placeholder="https://www.alejandro-ugc.com"
            size="sm"
            border="1px solid #000"
            borderRadius="8px"
            bg="white"
          />
        </FormControl>
      </Box>
    </Box>
  );

  // Foto y vídeos
  const step5 = (
    <Box textAlign="left" p="24px">
      <Box textAlign="center" mb={4}>
        <Image
          margin="0 auto"
          src="https://uploads-ssl.webflow.com/62ea5c239bacb85550bf44ea/6328573bad60f760ac2b5fbb_CRUWI%20(3).svg"
          alt="CRUWI Logo"
          width="100px"
          mb="3px"
        />
        <Text lineHeight={1} fontSize="20px" fontWeight="black">
          UGC
        </Text>
      </Box>

      <Text fontWeight="bold" fontSize="22px" lineHeight={1.3} mb={1}>
        Por último, contenido
      </Text>
      <Text lineHeight={1.2} fontSize="16px" fontWeight="normal" mb={4}>
        Es esencial que subas tus mejores vídeos UGC. Las marcas decidirán si colaborar contigo
        basándose en tus trabajos anteriores. Y tener una foto de perfil siempre viene bien.
      </Text>

      <Text lineHeight={1.2} fontSize="16px" fontWeight="bold" mb={5}>
        Puedes terminar y subir los contenidos más tarde.
      </Text>

      <Box>
        <Box>
          <Text fontSize="14px" fontWeight="bold" mb={1}>
            Foto de perfil
          </Text>
          <Box>
            <Input type="file" onChange={handleChangeLogo} display="none" id="file-input" />
            <label htmlFor="file-input">
              <Box
                width="64px"
                height="64px"
                mr={4}
                borderRadius="50%"
                backgroundPosition="center"
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundColor="white"
                backgroundImage={
                  selectedImage || (user.profilePicture ? user.profilePicture : DefaultProfileImage)
                }
              />
            </label>
          </Box>
        </Box>
        <Box mt={4}>
          <Text fontSize="14px" fontWeight="bold" mb={1}>
            Tus mejores vídeos UGC
          </Text>
          <Box mb={10}>
            <VideosUploadUGC
              videos={videos}
              onFileChange={handleFileChange}
              onRemove={handleRemove}
              onUpdateType={handleUpdateType}
              onUpdateCategory={handleUpdateCategory}
            />
          </Box>
        </Box>
      </Box>

      <Flex
        fontFamily="DM Sans"
        maxWidth="700px"
        margin="0 auto"
        boxShadow="0 -3px 10px 1px #b79f9f6e"
        bg="#ddded1"
        width={isLargerThan768 ? '452px' : '100%'}
        left="50%"
        transform="translateX(-50%)"
        position="fixed"
        bottom={0}
        height="88px"
        borderRadius={isLargerThan768 ? '12px 12px 0px 0px' : ''}
      >
        <Box width="100%" display="flex" alignItems="center" p="0px 24px">
          <Button
            onClick={handleSubmit}
            borderRadius="16px"
            border="2px solid black"
            bg="#EBD0FF"
            width="100%"
            isLoading={isLoading}
          >
            Terminar
          </Button>
        </Box>
      </Flex>
    </Box>
  );

  return (
    <Box bg="#ddded1" minH="100vh" fontFamily="DM Sans">
      <Box mb={8} maxWidth="500px" margin="0 auto" fontFamily="DM Sans">
        <ScrollToTop />
        <Flex minH="100vh" flexDirection="column" justifyContent="space-between">
          {/* Conditional rendering based on currentStep */}
          {currentStep === 1 && step1}
          {currentStep === 2 && step2}
          {currentStep === 3 && step3}
          {currentStep === 4 && step4}
          {currentStep === 5 && step5}
          <Box p="24px">
            {currentStep < 5 && (
              <Button
                borderRadius="16px"
                border="2px solid black"
                bg="#EBD0FF"
                width="100%"
                onClick={handleNext}
              >
                Siguiente
              </Button>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
