const getStatusColor = (status) => {
  let color = 'black';
  if (status === 'applied') {
    color = '#EBD0FF';
  }
  if (status === 'not_selected') {
    color = '#FF002C';
  }
  if (status === 'selected') {
    color = '#FFFFF2';
  }
  return color;
};

export default getStatusColor;
