/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */

import React from 'react';

import { Box, Text, Flex, useDisclosure } from '@chakra-ui/react';

import RewardIcon from '../../Assets/Images/basil_present-solid.svg';
import PlayIcon from '../../Assets/Images/ion_play.svg';
import InfluencerIcon from '../../Assets/Images/fluent_person-edit-20-filled.svg';
import BriefingItemModal from './BriefingItemModal/BriefingItemModal';

import calculateRelativeDateBriefingFinalization from '../../Utils/calculateRelativeDateBriefingFinalization';
import describeVideoNeeds from '../../Utils/describeVideoNeeds';
import createAgeText from '../../Utils/createAgeText';
import getFinalTextFromGender from '../../Utils/getFinalTextFromGender';

import getStatusColor from '../../Utils/getStatusColor';
import geStatusText from '../../Utils/getStatusText';
import BriefingButton from './BriefingButton/BriefingButton';

export default function BriefingItem({ isCollab, collabData, briefingData, userData }) {
  const { title, creatorGender, creatorAge, rewardAmount, launchDate, brand, selectedVideoNeeds } =
    briefingData;

  // Vemos si tiene o no applicantVideos para saber si ha aplicado o no
  const hasApplied = Object.prototype.hasOwnProperty.call(briefingData, 'applicantVideos');

  // Modal completo de briefing o collab
  const {
    isOpen: isOpenBriefingModal,
    onOpen: onOpenBriefingModal,
    onClose: onCloseBriefingModal,
  } = useDisclosure();

  // Calculamos el estado del briefing para el usuario
  let applicantStatus = 'not_applied';
  if (hasApplied) {
    applicantStatus = 'applied';
  } else if (isCollab) {
    applicantStatus = 'selected';
  }

  return (
    <>
      {/* Main Briefing Card */}
      <Box
        borderRadius="24px"
        border="2px solid #000000"
        background="#FFFFF2"
        boxShadow="4px 4px 0px 0px #000"
        mb="24px"
        fontFamily="DM Sans"
      >
        <Box p={5} bg="#c0bfb7" borderRadius="24px 24px 0 0">
          <Text textAlign="center" fontSize="24px" fontWeight="bold">
            {brand && brand.name}
          </Text>
        </Box>
        <Flex justifyContent="space-between" alignItems="center" p={3}>
          <Box
            fontWeight="bold"
            fontSize="12px"
            bg={getStatusColor(applicantStatus)}
            color={applicantStatus === 'not_applied' ? 'white' : 'black'}
            borderRadius="24px"
            border={applicantStatus === 'selected' ? '1px solid #000' : '1px solid #FFFFF2'}
            boxShadow={applicantStatus === 'selected' ? '4px 4px 0px 0px #000' : ''}
            px="10px"
            py="2px"
          >
            {geStatusText(applicantStatus)}
          </Box>
          <Box>
            <Text fontSize="12px" color="rgba(0, 0, 0, 0.56)">
              {calculateRelativeDateBriefingFinalization(launchDate)}
            </Text>
          </Box>
        </Flex>
        <Box p="0px 16px 0px 16px">
          <Text fontWeight="bold" fontSize="20px">
            {title}
          </Text>
        </Box>
        <Box p={4}>
          <Box p="0 12px 12px 0" borderBottom="1px solid #E4E4D9">
            <Flex alignItems="center">
              <Box
                width="25px"
                height="25px"
                borderRadius="50%"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundImage={`url(${brand && brand.logoUrl})`}
                mr={2}
              />
              <Text fontWeight="bold" fontSize="12px">
                {brand && brand.name}
              </Text>
            </Flex>
          </Box>
          <Box py={3} borderBottom="1px solid #E4E4D9">
            <Flex alignItems="center">
              <Box
                border="1px solid black"
                boxShadow="0.5px 0.5px 0px 0px #000"
                width="25px"
                height="25px"
                borderRadius="50%"
                backgroundRepeat="no-repeat"
                backgroundSize="15px"
                backgroundPosition="center"
                backgroundColor="#ddded1"
                backgroundImage={`url(${RewardIcon})`}
                mr={2}
              />
              <Text fontWeight="bold" fontSize="12px">
                Producto {rewardAmount > 0 ? `+ ${rewardAmount} €` : ''}
              </Text>
            </Flex>
          </Box>
          <Box py={3} borderBottom="1px solid #E4E4D9">
            <Flex alignItems="center">
              <Box
                border="1px solid black"
                boxShadow="0.5px 0.5px 0px 0px #000"
                width="25px"
                height="25px"
                borderRadius="50%"
                backgroundRepeat="no-repeat"
                backgroundSize="15px"
                backgroundPosition="center"
                backgroundColor="#ddded1"
                backgroundImage={`url(${PlayIcon})`}
                mr={2}
              />
              <Text fontWeight="normal" fontSize="12px">
                {describeVideoNeeds(selectedVideoNeeds)}
              </Text>
            </Flex>
          </Box>
          <Box py={3}>
            <Flex alignItems="center">
              <Box
                border="1px solid black"
                boxShadow="0.5px 0.5px 0px 0px #000"
                width="25px"
                height="25px"
                borderRadius="50%"
                backgroundRepeat="no-repeat"
                backgroundSize="15px"
                backgroundPosition="center"
                backgroundColor="#ddded1"
                backgroundImage={`url(${InfluencerIcon})`}
                mr={2}
              />
              <Text fontWeight="normal" fontSize="12px">
                {getFinalTextFromGender(creatorGender)} {createAgeText(creatorAge)}
              </Text>
            </Flex>
          </Box>
        </Box>
        <Box p={2}>
          {/* Briefing Main Button */}
          <BriefingButton
            onOpenBriefingModal={onOpenBriefingModal}
            applicantStatus={applicantStatus}
          />
        </Box>
      </Box>

      {/* Briefing Card Main Modal */}
      <BriefingItemModal
        isCollab={isCollab}
        applicantStatus={applicantStatus}
        userData={userData}
        briefingData={briefingData}
        collabData={collabData}
        isOpenBriefingModal={isOpenBriefingModal}
        close={onCloseBriefingModal}
      />
    </>
  );
}
