import { Box, Heading, Text, Button } from '@chakra-ui/react';

export default function UGCCard() {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <Box
      minWidth="300px"
      maxWidth="300px"
      bg="#fffff2"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      p={3}
      mr={3}
    >
      <Box>
        <Heading fontSize="16px">Accede a tu panel como UGC Creator</Heading>
        <Text fontSize="12px" lineHeight={1.2} mt={2} mb={5}>
          Accede, de manera exclusiva, al nuevo mundo de CRUWI. Donde podrás colaborar con marcas a
          cambio de producto y cash.
        </Text>
      </Box>

      <Button
        height="38px"
        fontSize="14px"
        onClick={() => openInNewTab('ugc')}
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        borderRadius="16px"
        bg="#FFFFF2"
        color="black"
        width="100%"
        size="md"
        _hover={{ bg: '#FFFFF2' }}
        _focus={{ bg: '#FFFFF2' }}
      >
        Acceder a mi panel UGC
      </Button>
    </Box>
  );
}
