/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';

import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Link,
  Spacer,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
} from '@chakra-ui/react';

import calculateChallengeDifficulty from '../../Utils/calculateChallengeDifficulty';

import { getDiffInHours } from '../../Utils/date';

import './ChallengeItem.css';

export default function ChallengeItemBlocked({ status, challengeData, userData, videoCreateTime }) {
  const { _id, subtitle, announcement, brand, description, hashtags, mentions, reward } = challengeData;

  // Vemos si ya tiene el reto participando
  const isParticipating =
    userData?.challenges.findIndex((challenge) => challenge.challenge._id === _id) !== -1;

  const {
    isOpen: isOpenConfirmation,
  } = useDisclosure();

  const isClaimable = status === 'approved' && getDiffInHours(videoCreateTime, new Date()) > 48;

  const [showFullDescription] = useState(false);

  const getDisplayedDescription = () => {
    if (showFullDescription) {
      return `${description  } `;
    } 

    return description.slice(0, 100) + (description.length > 100 ? ' ... ' : '');
  };
    
  return (
    <>
      {announcement && (
        <Box
          display="inline-block"
          fontSize="12px"
          py={1}
          paddingLeft={2}
          paddingRight={2}
          lineHeight={1.3}
          fontFamily="DM Sans"
          bg="#FFFFF2"
          width="auto"
          borderStyle="dashed"
          borderColor="#000000"
          borderWidth="1px 1px 0px 1px"
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
          borderTopLeftRadius={4}
          borderTopRightRadius={4}
        >
          <strong>{announcement}</strong>
        </Box>
      )}
      <Box
        fontFamily="DM Sans"
        bg="#fff"
        width="100%"
        borderStyle="dashed"
        borderColor="#000000"
        borderWidth="1px"
        mb={4}
        borderBottomLeftRadius={4}
        borderBottomRightRadius={4}
        borderTopLeftRadius={(status || announcement) || 4}
        borderTopRightRadius={(announcement && !status) || (!announcement && !status) ? 4 : 0}
        position="relative"
        >
        <Flex>
          <Box p={2}>
            <Box
              width="50px"
              borderRadius="50%"
              height="50px"
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundImage={brand.logoUrl}
            />
          </Box>
          <Box p={2} width="100%">
            <Flex mb={1} alignItems="center">
              <Heading fontSize="16px">{brand.name} {subtitle}</Heading>
              <Spacer />
              <Box
                fontSize="12px"
                px={1}
                bg="#FFFFF2"
                border="1px solid #eee9e9"
                borderRadius={4}
              >
                {calculateChallengeDifficulty(reward)}
              </Box>
            </Flex>

            <Text display="inline-block" fontSize="12px" lineHeight={1.3}>
              {getDisplayedDescription()}
              {description.length > 100 && (
              <Link display="inline" fontSize="12px" textDecoration="underline" lineHeight={1.3}>
                {showFullDescription ? 'leer menos' : 'leer más'}
              </Link>
            )}
            </Text>
            
            <Box mt={2}>
              <Text opacity={0.8} fontSize="12px">
                Incluye: {mentions.map((m) => `@${m}`)} {hashtags.map((h) => `#${h} `)}
              </Text>
            </Box>
            <Flex mt={3} mb={1} pr={1}>
              <Button
                textDecoration="underline"
                fontWeight="medium"
                variant="unstyled"
                size="xs"
              >
                Ver ejemplo
              </Button>
              <Spacer />
              {isParticipating ? (
                <Popover
                  returnFocusOnClose={false}
                  isOpen={isOpenConfirmation}
                  placement="bottom"
                  closeOnBlur={false}
                >
                  <PopoverTrigger>
                    {isClaimable ? (
                      <Button
                        className="button-pulse"
                        _hover={{ bg: '#000000' }}
                        paddingRight="12px"
                        paddingLeft="12px"
                        bg="#000000"
                        color="white"
                        variant="solid"
                        size="xs"
                      >
                        Reclamar
                      </Button>
                    ) : (
                      <Button
                        _hover={{ bg: '#FFFFFF' }}
                        paddingRight="12px"
                        paddingLeft="12px"
                        bg="#FFFFFF"
                        color="black"
                        variant="solid"
                        size="xs"
                        border="1px solid #000000"
                      >
                        Desapuntarse
                      </Button>
                    )}
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontSize="12px" fontWeight="semibold">
                      Confirma tu acción
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody fontSize="12px">
                      ¿Estás seguro que deseas desapuntarte del reto?
                    </PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="xs">
                        <Button fontSize="12px" variant="outline">
                          Cancelar
                        </Button>
                        <Button
                          fontSize="12px"
                          colorScheme="red"
                        >
                          Sí, desapuntarme
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              ) : (
                <Button
                  _hover={{ bg: '#000000' }}
                  paddingRight="12px"
                  paddingLeft="12px"
                  bg="#000000"
                  color="white"
                  variant="solid"
                  size="xs"
                >
                  Participar
                </Button>
              )}
            </Flex>
          </Box>
        </Flex>
        <Box fontFamily="DM Sans" display="flex" justifyContent="center" alignItems="center" top="0" height="100%" width="100%" background="#fffff2e3" position="absolute">
          <Flex maxWidth="250px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Text fontSize="25px">🔐</Text>
            </Box>
            <Box marginLeft="10px">
              <Text fontWeight="bold">LÍMITE ALCANZADO</Text>
              <Text fontSize="12px" lineHeight={1.3} fontWeight="500">Disponible de nuevo próximamente. Stay tuned.</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
