/* eslint-disable no-console */

import React from 'react';

import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { TbBell } from "react-icons/tb";

import NotificationsDrawer from '../NotificationsDrawer/NotificationsDrawer';

export default function NotificationBell({ user }) {
  
  const { notifications } = user;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const challengesNotifications = notifications.filter((notification) => notification.type === 'challenges');
  const unreadNotifications = notifications.filter((notification) => (!notification.read && notification.type === 'challenges'));
  const unreadNotificationsNumber = unreadNotifications.length;

  return (
    <Box>
      <Flex marginBottom="-4px" justifyContent="flex-end">
        <Box borderRadius="50%" width="6px" height="6px" bg={unreadNotificationsNumber > 0 ? 'red' : 'white'} />
      </Flex>
      <TbBell cursor="pointer" onClick={onOpen} size="20px" />
      <NotificationsDrawer notifications={challengesNotifications} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}
