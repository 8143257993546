import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Profile from '../Components/Profile/Profile';

import { selectCurrentToken, selectCurrentUser } from '../Redux/features/auth/authSlice';

function ProfilePage() {
  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!token || !user) {
      navigate('/login');
    }
  }, [token, navigate, user]);

  return <Profile />;
}

export default ProfilePage;
