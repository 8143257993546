import { Button } from '@chakra-ui/react';

export default function BriefingButton({
  applicantStatus,
  onOpenBriefingModal,
}) {
  // Cambiamos el contenido y acción del botón según el estado del challenge y las plazas

  // Por defecto, será el botón de aplicar
  let buttonContent = 'Aplicar';
  let buttonAction = onOpenBriefingModal;
  let isDisabled = false;

  if (applicantStatus === 'applied') {
    buttonContent = 'Ya aplicaste';
    buttonAction = onOpenBriefingModal;
  }

  if (applicantStatus === 'selected') {
    buttonContent = 'Ver briefing';
    buttonAction = onOpenBriefingModal;
    isDisabled = false;
  }

  return (
    <Button
      border="2px solid black"
      boxShadow="1px 1px 0px 0px #000"
      borderRadius="16px"
      bg="#EBD0FF"
      color="black"
      width="100%"
      onClick={() => buttonAction()}
      disabled={isDisabled}
    >
      {buttonContent}
    </Button>
  );
}
