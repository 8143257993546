import { Box, Flex, useDisclosure } from '@chakra-ui/react';

import NotificationIcon from '../../Assets/Images/ion_notifications.svg';
import NotificationsDrawer from '../NotificationsDrawer/NotificationsDrawer';

export default function UGCNotificationBell({ userData }) {
  const { notifications } = userData;

  // Abre el drawer de notifications
  const {
    isOpen: isOpenNotificationsDrawer,
    onOpen: onOpenNotificationsDrawer,
    onClose: onCloseNotificationsDrawer,
  } = useDisclosure();

  const ugcNotifications = notifications.filter((notification) => notification.type === 'ugc');
  const unreadNotifications = notifications.filter((notification) => (!notification.read && notification.type === 'ugc'));
  const unreadNotificationsNumber = unreadNotifications.length;

  return (
    <Box position="relative">
      <Box
        border="1px solid black"
        boxShadow="0.5px 0.5px 0px 0px #000"
        width="20px"
        height="20px"
        borderRadius="50%"
        backgroundRepeat="no-repeat"
        backgroundSize="12px"
        backgroundPosition="center"
        backgroundColor="#ddded1"
        backgroundImage={`url(${NotificationIcon})`}
        cursor="pointer"
        onClick={onOpenNotificationsDrawer}
        _hover={{
          backgroundColor: '#EBD0FF',
        }}
      />
      {unreadNotificationsNumber > 0 && (
        <Flex
          border="1px solid"
          borderRadius="50%"
          position="absolute"
          top="-1px"
          right="-1px"
          marginBottom="-4px"
          justifyContent="flex-end"
        >
          <Box borderRadius="50%" width="6px" height="6px" bg="red" />
        </Flex>
      )}

      <NotificationsDrawer
        isUGC
        notifications={ugcNotifications}
        isOpen={isOpenNotificationsDrawer}
        onClose={onCloseNotificationsDrawer}
      />
    </Box>
  );
}
