/* eslint-disable no-console */

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Box,
  Text,
  VStack,
  Flex,
  Input,
  Container,
  Avatar,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/react';

import { GoChevronLeft } from 'react-icons/go';
import { RiSendPlaneFill } from 'react-icons/ri';
import { TbMessages } from 'react-icons/tb';

import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import HeaderUGC from '../../Components/HeaderUGC/HeaderUGC';

import LoadingBriefings from '../../Components/LoadingBriefings/LoadingBriefings';

import {
  useGetCollabByIDQuery,
  useSendMessageToCollabMutation,
} from '../../Redux/features/collabs/collabsApiSlice';

import { useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';

// Milisegundos que solicitamos los datos del usuario
const POLLING_INTERVAL = 30000;

function ChatMessage({ msg }) {
  const getInitials = (name) => name.match(/\b(\w)/g).join(''); // Get initials from name
  return (
    <Box
      display="flex"
      flexDirection={msg.sender === 'user' ? 'row-reverse' : 'row'}
      alignItems="flex-end"
      width="100%"
    >
      <Avatar
        name={msg.sender}
        size="sm"
        bg={msg.sender === 'user' ? 'blue.500' : 'gray.500'}
        color="white"
        ml={msg.sender === 'user' ? 3 : 0}
        mr={msg.sender === 'user' ? 0 : 3}
      >
        {getInitials(msg.sender)}
      </Avatar>
      <Box
        bg={msg.sender === 'user' ? 'white' : '#FFFFF2'}
        color={msg.sender === 'user' ? 'black' : 'black'}
        padding={3}
        borderRadius="lg"
        flexGrow={1}
        maxWidth="100%"
      >
        {msg.type === 'revision' && (
          <Box mb={2} fontWeight="bold" fontSize="14px">
            {' '}
            📹 Mensaje de revisión 📹
          </Box>
        )}
        <Text fontSize="sm">{msg.content}</Text>
        <Text mt={2} fontSize="xs" color={msg.isUser ? 'gray.500' : 'gray.500'}>
          {new Date(msg.sentAt).toLocaleTimeString([], { timeStyle: 'short' })}
        </Text>
      </Box>
    </Box>
  );
}

function ChatBox({ messages }) {
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <VStack spacing={4} align="stretch" overflowY="auto" height="72vh" width="100%" p={4}>
      {messages.map((msg) => (
        <ChatMessage key={msg._id} msg={msg} />
      ))}
      {messages.length === 0 && (
        <Flex flexDir="column" minH="300px" justifyContent="center" alignItems="center">
          <Text mb={1}>
            <TbMessages size="30px" />
          </Text>
          <Text>No hay mensajes</Text>
        </Flex>
      )}
      <div ref={bottomRef} />
    </VStack>
  );
}

function InputBox({ onSendMessage, isLoadingSendMessage }) {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() !== '') {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <Flex maxW="700px" p={4} position="fixed" bottom={0} zIndex="500000" width="100%">
      <Input
        placeholder="Escribe un mensaje..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        size="md"
        flexGrow={1}
        bg="white"
        mr={2}
        fontSize="sm"
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        _focus={{ outline: 'none' }}
        _hover={{ outline: 'none' }}
        _focusVisible={{ outline: 'none' }}
      />
      <IconButton
        borderRadius="50%"
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        onClick={handleSubmit}
        aria-label="Search database"
        icon={<RiSendPlaneFill />}
        bg="#EBD0FF"
        color="black"
        fontSize="20px"
        isLoading={isLoadingSendMessage}
      />
    </Flex>
  );
}

export default function UGCCollabChat() {
  // Changes for desktop
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const navigate = useNavigate();

  // Cogemos el ID de la collab a la que redirigimos al usuario
  const { collabId } = useParams();

  // Pedimos datos de la collab
  const { data: collabData, isLoading: isLoadingCollabs } = useGetCollabByIDQuery(collabId, {
    pollingInterval: POLLING_INTERVAL,
  });

  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery();

  // Función para enviar un mensaje al backend
  const [sendMessage, { isLoadingSendMessage }] = useSendMessageToCollabMutation();

  const handleSendMessage = async (messageContent) => {
    try {
      await sendMessage({ collabId, messageContent }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const goTo = (url) => {
    navigate(url);
  };

  if (isLoadingCollabs || isUserLoading) {
    return <LoadingBriefings />;
  }

  return (
    <Box bg="#ddded1" minH="100vh">
      <ScrollToTop />
      <HeaderUGC userData={user} />
      <Box pb={10} fontFamily="DM Sans">
        <Box mb={8} maxWidth="700px" margin="0 auto">
          <Box
            p="16px 16px 16px 16px"
            boxShadow={isLargerThan768 ? '' : '0px 4px 10px 0px #FFFFF2'}
          >
            <Flex onClick={() => goTo('/ugc/collabs')} alignItems="center" mb={2}>
              <GoChevronLeft size="14px" />
              <Text fontSize="14px">Atrás</Text>
            </Flex>
            <Box>
              <Text fontWeight="bold" fontSize="16px" color="rgba(0, 0, 0, 0.64)">
                Chat con {collabData && collabData.briefing.brand.name}
              </Text>
              <Text fontSize="18px" fontWeight="bold">
                {collabData && collabData.briefing.title}
              </Text>
            </Box>
          </Box>
          <Container>
            <VStack spacing={4}>
              <ChatBox messages={collabData?.messages} />
              <InputBox
                onSendMessage={handleSendMessage}
                isLoadingSendMessage={isLoadingSendMessage}
              />
            </VStack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
