/* eslint-disable no-alert */
/* eslint-disable no-console */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Button,
  FormControl,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';

import { setUserData } from '../../Redux/features/auth/authSlice';
import { useUpdateMutation } from '../../Redux/features/auth/authApiSlice';

export default function AmbassadorForm() {
  // Redux
  const dispatch = useDispatch();
  const [update, { isLoading }] = useUpdateMutation();

  // Toast
  const toast = useToast();

  // Referral Code of the friend
  const [referralCode, setReferralCode] = useState('');
  const handleChangeReferralCode = (e) => setReferralCode(e.target.value);

  // Show toast and type
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Enviamos al backend los cambios del usuario
  const handleSubmitEditProfile = async () => {
    if (referralCode.length === 0) {
      alert('El código no es válido');
      return;
    }

    const newUserInfo = {
      referralCode,
    };

    try {
      const userUpdatedData = await update(newUserInfo).unwrap();
      showToast('Código de invitación enviado con éxito', 'success');
      dispatch(setUserData(userUpdatedData));
    } catch (error) {
      showToast('Hubo un problema con el código de invitación o no es válido', 'error');
      setReferralCode('');
      console.log(error);
    }
  };

  return (
    <Box maxWidth="700px" margin="0 auto 40px auto">
      <Box mt={4}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Código de invitación
        </Heading>
        <Flex justifyContent="space-between">
          <Text fontStyle="italic" opacity="0.8" fontSize="12px">
            Introduce el código de tu amigx para ganar las recompensas completando tu primer reto
          </Text>
        </Flex>
      </Box>

      <Box mt={4}>
        <FormControl>
          <InputGroup size="md">
            <Input
              value={referralCode}
              onChange={handleChangeReferralCode}
              borderRadius="8px"
              size="sm"
              bg="white"
              type="text"
              placeholder="Código de tu amigx"
              maxLength={6}
              height="32px"
            />
            <InputRightElement height="32px" mr="4px" w="60px">
              <Button
                isLoading={isLoading}
                onClick={() => handleSubmitEditProfile()}
                size="xs"
                fontSize="12px"
                minW="100px"
                mr="40px"
              >
                Usar código
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Box mb={2} />
      </Box>
    </Box>
  );
}
