import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react';

import { useUpdateCollabMutation } from '../../../../Redux/features/collabs/collabsApiSlice';

export default function BriefingConfirmApplicationModal({
  collabData,
  isOpenConfirmApplicationModal,
  onCloseConfirmApplicationModal,
}) {
  // Redux
  const [updateCollab, { isLoading: isLoadingUpdateCollab }] = useUpdateCollabMutation();

  // Toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 8000,
    });
  };

  // Función confirmación de la participación
  const handleConfirmApplication = async () => {
    await updateCollab({ collabId: collabData._id, status: 'confirmed' });
    showToast(
      'Has confirmado tu participación. Recuerda confirmar también la recepción del producto cuando te llegue.',
      'success',
    );
    onCloseConfirmApplicationModal();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpenConfirmApplicationModal}
      onClose={onCloseConfirmApplicationModal}
      closeOnOverlayClick
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="24px" m={5}>
        <ModalCloseButton />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          <Box mt={5}>
            <Text fontSize="20px" fontWeight="bold" mb={3}>
              Confirma que quieres realizar esta collab
            </Text>
            <Text fontSize="14px">
              Una vez que confirmes tu participación, se cerrará el acuerdo con la marca. La marca
              te enviará los productos para que puedas realizar el contenido.
            </Text>

            <Button
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
              onClick={handleConfirmApplication}
              mt={10}
              isLoading={isLoadingUpdateCollab}
            >
              Confirmar participación
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
