import { Flex, Box, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <Flex
      minH="100vh"
      justifyContent="center"
      alignItems="center"
      minW="100%"
      fontFamily="DM Sans"
      background="linear-gradient(105.32deg, #FFFFF2 0%, #EBD0FF 0.01%, #FFFFF2 16.67%)"
    >
      <Box textAlign="center">
        <Text mb={3} fontWeight="bold" fontFamily="DM Sans" fontSize="14px">
          ¡Ups! Esta dirección no existe
        </Text>
        <Button margin="0 auto" size="xs" onClick={() => goToHome()}>
          Volver a la home
        </Button>
      </Box>
    </Flex>
  );
}
