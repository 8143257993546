import { Box, Flex, Image, Text, AvatarGroup, Avatar } from '@chakra-ui/react';

export default function RegisterFormV2Card({ data }) {
  const { name, followers, earned, image, colaborations } = data;
  return (
    <Flex bg="#F1DFFF" p={["20px", "20px", "20px", "24px", "24px"]} borderRadius="24px" alignItems="center">
      <Box mr={3}>
        <Image
          boxSize={['60px', '60px', '60px', '80px', '80px']}
          minW={['60px', '60px', '60px', '80px', '80px']}
          minH={['60px', '60px', '60px', '80px', '80px']}
          objectFit="cover"
          src={image}
        />
      </Box>
      <Flex width="100%" flexDir="column">
        <Box>
          <Text fontSize={['12px', '12px', '12px', '16px', '16px']}>
            {name}, {String(followers)} seguidores
          </Text>
        </Box>

        <Box mt={[2, 2, 2, 3, 3]}>
          <Text
            fontWeight="bold"
            fontSize={['12px', '12px', '12px', '16px', '16px']}
            lineHeight={[1, 1, 1, 1.1, 1.1]}
          >
            Ha ganado +{earned}€
          </Text>
          <Flex alignItems="center" h="24px">
            <Text
              fontWeight="bold"
              fontSize={['12px', '12px', '12px', '16px', '16px']}
              lineHeight={1.1}
            >
              colaborando con
            </Text>
            <AvatarGroup ml={1} size={['xs', 'xs', 'xs', 'sm', 'sm']} max={3}>
              {colaborations.map((c) => (
                <Avatar key={c} name="Company Logo" src={c} />
              ))}
            </AvatarGroup>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
