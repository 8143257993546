import { Flex, Image } from '@chakra-ui/react';

import CruwiLogo from '../../Assets/Images/logo-cruwi-negro.svg';

import UGCNotificationBell from './UGCNotificationBell';
import UGCHelpButton from './UGCHelpButton';

export default function HeaderUGC({ userData }) {
  return (
    <Flex
      position="relative"
      pt={4}
      pb={4}
      alignItems="center"
      justifyContent="space-between"
      maxWidth="700px"
      margin="0 auto"
      borderBottom="1px solid #eee9e9"
      textAlign="left"
      height="55px"
    >
      <Image marginLeft="20px" src={CruwiLogo} alt="CRUWI Logo" width="80px" />
      <Flex marginRight="25px">
        <UGCHelpButton />
        <UGCNotificationBell userData={userData}  />
      </Flex>
    </Flex>
  );
}
