import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useMediaQuery,
  Box,
  ModalHeader,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react';

const faqs = [
  {
    id: 1,
    question: '¿Por qué no veo ningún briefing?',
    answer:
      'Si no ves ningún briefing en la pantalla de inicio puede ser debido a que tu perfil no encaja con ninguno de los disponibles. Cada vez que una marca lance un nuevo briefing que encaje contigo, te lo haremos saber por email. ¡Estate atent@!',
  },
  {
    id: 2,
    question: '¿Cuántos vídeos puedo subir?',
    answer:
      'Puedes subir hasta un máximo de 10 vídeos en tu portafolio. Idealmente deberán ser lo más cortos posibles y de hasta un máximo de 100mb de tamaño. Intenta que tus vídeos tengan estos requisitos para tener más opciones a ser elegido por una marca para una colaboración.',
  },
  {
    id: 3,
    question: 'He sido seleccionado, ¿ahora qué?',
    answer:
      'Una vez hayas sido seleccionado por una marca deberás CONFIRMAR tu participación en la colaboración.',
  },
  {
    id: 4,
    question: 'La marca me ha enviado el producto, ¿ahora qué?',
    answer:
      'Una vez que hayas completado tu dirección en tu perfil, la marca tendrá acceso a ella y te enviará el producto. Una vez lo recibas, deberás CONFIRMAR la recepción del mismo. Ya podrás ponerte a crear los contenidos, dispones de 7 días naturales.',
  },
  {
    id: 5,
    question: 'He creado los contenidos, ¿ahora qué?',
    answer:
      'Una vez hayas creado los contenidos requeridos por la marca, tendrás que subirlos a WeTransfer y compartir el enlace con la marca. Verás el botón de "Compartir vídeos" y te aparecerá un popup para compartir el enlace con la marca. Ahora sólo toca esperar el feedback de la marca.',
  },
  {
    id: 6,
    question: 'He sido seleccionado pero no he recibido el producto',
    answer:
      'Si has sido seleccionado por una marca y no has recibido el producto puede ser por varios motivos. Primero asegúrate de que has completado tu dirección completa en tu perfil (incluyendo calle, piso, código postal, municipio y país). También ten en cuenta que desde que eres seleccionado pueden pasar unos 5-7 días hasta que te llegue al domicilio.',
  },
];

export default function HelpModalUGC({ isOpen, onClose }) {
  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <Modal
      closeOnOverlayClick
      size={isLargerThan768 ? 'xl' : 'full'}
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent borderRadius={isLargerThan768 ? '24px' : '0px'}>
        <ModalHeader borderRadius={isLargerThan768 ? '24px 24px 0px 0px' : '0px'} bg="#E4E4D9">
          Ayuda UGC Creadores
        </ModalHeader>
        <ModalCloseButton bg="#EBD0FF" borderRadius="50%"  m={1} boxShadow="1px 1px 0px 0px #000" border="2px solid black" />
        <ModalBody
          borderRadius={isLargerThan768 ? '0px 0px 24px 24px' : '0px'}
          fontFamily="DM Sans"
          p={0}
          bg="#E4E4D9"
        >
          <Box p={5}>
            <Accordion allowToggle>
              {faqs.map((faq) => (
                <AccordionItem
                  mb={2}
                  border="1px solid #e2e8f0"
                  borderRadius="8px"
                  key={faq.question}
                  bg="white"
                >
                  <AccordionButton borderRadius="24px" bg="#fffff2" _hover={{ bg: '#fffff2' }}>
                    <Box pt={1} pb={1} fontWeight="bold" flex="1" textAlign="left">
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
