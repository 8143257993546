/* eslint-disable no-console */

import React from 'react';

import { Box } from '@chakra-ui/react';

import { useGetBriefingsQuery } from '../../Redux/features/briefings/briefingsApiSlice';
import { useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';

import LoadingBriefings from '../../Components/LoadingBriefings/LoadingBriefings';
import NoBriefingsOrCollabsAvailables from '../../Components/NoBriefingsOrCollabsAvailables/NoBriefingsOrCollabsAvailables';

import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import HeaderUGC from '../../Components/HeaderUGC/HeaderUGC';
import FooterNavUGC from '../../Components/FooterNavUGC/FooterNavUGC';
import BriefingItem from '../../Components/BriefingItem/BriefingItem';

// Milisegundos que solicitamos los datos del usuario
const POLLING_INTERVAL = 30000;

export default function UGCHome() {
  const { data: briefings, isLoading: isLoadingBriefings } = useGetBriefingsQuery();

  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  if (isLoadingBriefings || isUserLoading) {
    return <LoadingBriefings />;
  }

  return (
    <Box bg="#ddded1" minH="100vh">
      <ScrollToTop />
      <HeaderUGC userData={user} />
      <Box mt={5} ml={5} mr={5} mb={[5, 0, 0, 0, 0]}>
        <Box pb={[10, 10, 10, 10, 20]} mb={8} maxWidth="700px" margin="0 auto">
          {briefings &&
            briefings.data?.map((b) => (
              <BriefingItem key={b._id} briefingData={b} userData={user} />
            ))}
          {briefings && briefings.data?.length === 0 && <NoBriefingsOrCollabsAvailables />}
        </Box>
      </Box>
      <FooterNavUGC />
    </Box>
  );
}
