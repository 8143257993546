import React from 'react';

import {
  Box,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useMediaQuery,
} from '@chakra-ui/react';

import { GoChevronLeft } from 'react-icons/go';

export default function BriefingItemModalFullBriefing({ isOpen, onClose, briefingData }) {

  const { title, finalText = '' } = briefingData;
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <Modal
      isOpen={isOpen}
      size={isLargerThan768 ? 'md' : 'full'}
      scrollBehavior="inside"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody fontFamily="DM Sans" p={0}>
          <Box bg="#E4E4D9" minH="100vh">
            <Box pb={3} fontFamily="DM Sans">
              <Box mb={8} maxWidth="700px" margin="0 auto">
                <Box p="16px 16px 16px 16px" boxShadow="0px 4px 10px 0px #FFFFF2">
                  <Flex alignItems="center" mb={2} _hover={{ cursor: 'pointer' }} onClick={onClose}>
                    <GoChevronLeft size="14px" />
                    <Text fontSize="14px">Atrás</Text>
                  </Flex>
                  <Box>
                    <Text fontWeight="bold" fontSize="16px" color="rgba(0, 0, 0, 0.64)">
                      Briefing
                    </Text>
                    <Text fontSize="18px" fontWeight="bold">
                      {title}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box p="0px 16px 16px 16px">
              <div className="description-html">
                <Box
                  fontFamily="DM Sans"
                  fontSize="14px"
                  dangerouslySetInnerHTML={{ __html: finalText }}
                />
              </div>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
