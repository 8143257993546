import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';

import BriefingItemModalHeader from './BriefingItemModalHeader/BriefingItemModalHeader';
import BriefingItemModalStatus from './BriefingItemModalStatus/BriefingItemModalStatus';
import BriefingItemModalDetails from './BriefingItemModalDetails/BriefingItemModalDetails';
import BriefingItemModalYourVideos from './BriefingItemModalYourVideos/BriefingItemModalYourVideos';
import BriefingItemModalNextSteps from './BriefingItemModalNextSteps/BriefingItemModalNextSteps';
import BriefingItemModalButton from './BriefingItemModalButton/BriefingItemModalButton';

import BriefingApplyModal from '../BriefingApplyModal/BriefingApplyModal';
import BriefingConfirmApplicationModal from './BriefingConfirmApplicationModal/BriefingConfirmApplicationModal';
import BriefingConfirmProductReceptionModal from './BriefingConfirmProductReceptionModal/BriefingConfirmProductReceptionModal';
import BriefingShareVideosModal from './BriefingShareVideosModal/BriefingShareVideosModal';
import BriefingClaimPaymentModal from './BriefingClaimPaymentModal/BriefingClaimPaymentModal';

export default function BriefingItemModal({
  isCollab,
  applicantStatus,
  userData,
  briefingData,
  collabData,
  isOpenBriefingModal,
  close,
}) {
  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Abrir modal aplicar
  const {
    isOpen: isOpenModalApply,
    onOpen: onOpenModalApply,
    onClose: onCloseModalApply,
  } = useDisclosure();

  // Abrir modal confirmar participación
  const {
    isOpen: isOpenConfirmApplicationModal,
    onOpen: onOpenConfirmApplicationModal,
    onClose: onCloseConfirmApplicationModal,
  } = useDisclosure();

  // Abrir modal confirmar recepción del producto
  const {
    isOpen: isOpenConfirmProductReceptionModal,
    onOpen: onOpenConfirmProductReceptionModal,
    onClose: onCloseConfirmProductReception,
  } = useDisclosure();

  // Abrir modal compartir vídeos creados
  const {
    isOpen: isOpenShareVideosModal,
    onOpen: onOpenShareVideosModal,
    onClose: onCloseShareVideosModal,
  } = useDisclosure();

  // Abrir modal reclamar el pago cuando esté closed la collab
  const {
    isOpen: isOpenClaimPaymentModal,
    onOpen: onOpenClaimPaymentModal,
    onClose: onCloseClaimPaymentModal,
  } = useDisclosure();

  let modalContent = (
    <Box>
      <BriefingItemModalHeader briefingData={briefingData} closeModal={close} />
      <BriefingItemModalStatus briefingData={briefingData} applicantStatus={applicantStatus} />
      <BriefingItemModalDetails briefingData={briefingData} />
    </Box>
  );

  if (applicantStatus === 'applied') {
    modalContent = (
      <Box>
        <BriefingItemModalHeader briefingData={briefingData} closeModal={close} />
        <BriefingItemModalStatus briefingData={briefingData} applicantStatus={applicantStatus} />
        <BriefingItemModalYourVideos briefingData={briefingData} />
        <BriefingItemModalDetails briefingData={briefingData} />
      </Box>
    );
  }

  if (applicantStatus === 'selected') {
    modalContent = (
      <Box>
        <BriefingItemModalHeader briefingData={briefingData} closeModal={close} />
        <BriefingItemModalStatus briefingData={briefingData} applicantStatus={applicantStatus} />
        <BriefingItemModalNextSteps briefingData={briefingData} collabData={collabData} />
        <BriefingItemModalDetails briefingData={briefingData} />
      </Box>
    );
  }

  return (
    <>
      {/* Main Modal */}
      <Modal
        closeOnOverlayClick
        size={isLargerThan768 ? 'md' : 'full'}
        isOpen={isOpenBriefingModal}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent borderRadius={isLargerThan768 ? '24px' : '0px'}>
          <ModalBody
            borderRadius={isLargerThan768 ? '24px 24px 24px 24px' : '0px'}
            fontFamily="DM Sans"
            bg="#E4E4D9"
            p={0}
          >
            {modalContent}

            {/* Briefing Apply Modal */}
            <BriefingApplyModal
              applicantStatus={applicantStatus}
              userData={userData}
              briefingData={briefingData}
              isOpenModalApply={isOpenModalApply}
              onCloseModalApply={onCloseModalApply}
            />

            {/* Briefing Confirm Application Modal */}
            <BriefingConfirmApplicationModal
              collabData={collabData}
              isOpenConfirmApplicationModal={isOpenConfirmApplicationModal}
              onCloseConfirmApplicationModal={onCloseConfirmApplicationModal}
            />

            {/* Briefing Confirm Product Reception Modal */}
            <BriefingConfirmProductReceptionModal
              collabData={collabData}
              isOpenConfirmProductReceptionModal={isOpenConfirmProductReceptionModal}
              onCloseConfirmProductReception={onCloseConfirmProductReception}
            />

            {/* Briefing Share Content Link Modal */}
            <BriefingShareVideosModal
              collabData={collabData}
              isOpenShareVideosModal={isOpenShareVideosModal}
              onCloseShareVideosModal={onCloseShareVideosModal}
            />

            {/* Claim Payment Modal */}
            <BriefingClaimPaymentModal
              collabData={collabData}
              isOpenClaimPaymentModal={isOpenClaimPaymentModal}
              onCloseClaimPaymentModal={onCloseClaimPaymentModal}
            />
          </ModalBody>

          <BriefingItemModalButton
            isCollab={isCollab}
            applicantStatus={applicantStatus}
            isLargerThan768={isLargerThan768}
            briefingData={briefingData}
            collabData={collabData}
            onOpenModalApply={onOpenModalApply}
            onOpenConfirmApplicationModal={onOpenConfirmApplicationModal}
            onOpenConfirmProductReceptionModal={onOpenConfirmProductReceptionModal}
            onOpenShareVideosModal={onOpenShareVideosModal}
            onOpenClaimPaymentModal={onOpenClaimPaymentModal}
          />
        </ModalContent>
      </Modal>
    </>
  );
}
