const geStatusText = (status) => {
  let text = 'NO APLICADO';

  if (status === 'not_applied') {
    text = 'NO APLICADO';
  }
  if (status === 'applied') {
    text = 'APLICADO';
  }
  if (status === 'not_selected') {
    text = 'NO SELECCIONADO';
  }
  if (status === 'selected') {
    text = '🫶🏽 SELECCIONADO 🫶🏽';
  }
  return text;
};
export default geStatusText;
