import React, { useCallback } from 'react';

import { Box, Heading, Text, Flex, Button, useToast } from '@chakra-ui/react';

import { BiLockAlt } from 'react-icons/bi';
import { useClaimFirstChallengeRewardMutation } from '../../../Redux/features/auth/authApiSlice';

export default function FirstChallengeRewardCard({ isDisabled }) {
  const [claimFirstChallengeReward] = useClaimFirstChallengeRewardMutation();
  const toast = useToast();
  const showToast = useCallback(
    (message, type) => {
      toast({
        title: `${message}`,
        status: type,
        isClosable: true,
        duration: 2000,
      });
    },
    [toast],
  );

  const handleClaimReward = async () => {
    try {
      await claimFirstChallengeReward();
      showToast('La recompensa ha sido añadida a tu saldo :)', 'success');
    } catch (error) {
      showToast(
        'No se pudo reclamar la recompensa, contáctanos para solucionar el problema :)',
        'error',
      );
    }
  };

  return (
    <Box
      minWidth="300px"
      maxWidth="300px"
      bg="#fffff2"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      border="2px solid black"
      p={3}
      mr={3}
    >
      <Box>
        <Heading fontSize="16px">Completa tu primer reto</Heading>
        <Text fontSize="12px" lineHeight={1.2} mt={2}>
          Gana 1€ si completas tu primer reto en los próximos 10 días.
        </Text>
      </Box>
      <Flex mt={3} mb={3} justifyContent="space-between">
        <Flex flexDirection="column">
          <Text fontSize="12px" fontWeight="bold">
            Recompensa
          </Text>
          <Text fontSize="12px">1€</Text>
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize="12px" fontWeight="bold">
            Tiempo estimado
          </Text>
          <Text fontSize="12px">Depende de ti :)</Text>
        </Flex>
      </Flex>
      <Button
        onClick={() => handleClaimReward()}
        height="38px"
        fontSize="14px"
        isDisabled={isDisabled}
        _hover={{ bg: '#FFFFF2' }}
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        borderRadius="16px"
        bg="#FFFFF2"
        color="black"
        width="100%"
        size="md"
      >
        {isDisabled ? (
          <>
            Reclamar &nbsp;
            <BiLockAlt />
          </>
        ) : (
          'Reclamar'
        )}
      </Button>
    </Box>
  );
}
