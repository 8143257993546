/* eslint-disable no-console */

import { useState } from 'react';

import { useToast } from '@chakra-ui/react';

function useCopyToClipboard(successText, errorText) {

  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const successTextToShow = successText || 'Copiado correctamente';
  const errorTextToShow = errorText || 'Hubo un error copiando el texto';

  const [copiedText, setCopiedText] = useState(null);

  const copy = async text => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      showToast(successTextToShow, 'success');
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      showToast(errorTextToShow, 'error');
      setCopiedText(null);
      return false;
    }
  }

  return [copiedText, copy]
}

export default useCopyToClipboard;
