/* eslint-disable no-console */

import React from 'react';
import { Box, Text, Divider } from '@chakra-ui/react';

export default function ChallengeItemModalBrandInfo({ challenge }) {
  const { brand } = challenge;
  if (brand.description?.length === 0) return null;
  return (
    <Box pb={5} pl={5} pr={5}>
      <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
        Información de la marca
      </Text>
      <Box>
        <Text fontFamily="DM Sans" fontSize="14px">
          {brand.description}
        </Text>
      </Box>
      <Box mt={5} mb={5}>
        <Divider />
      </Box>
      <Box>
        <Text fontFamily="DM Sans" fontSize="12px" lineHeight={1.3}>
          <span style={{ textDecoration: 'underline' }}>Importante</span>: al pulsar en participar
          tendrás un plazo de 72h para subir el vídeo. De lo contrario tu participación será
          eliminada en el reto.
        </Text>
      </Box>
    </Box>
  );
}
