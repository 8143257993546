import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

import ChallengeIcon from '../../Assets/Images/empty-folder.png';

export default function NoChallengesAvailables() {
  return (
    <Box
      fontFamily="DM Sans"
      bg="#fff"
      width="100%"
      borderStyle="dashed"
      borderColor="#000000"
      borderWidth="1px"
      borderRadius={4}
      mb={4}
    >
      <Box textAlign="center" p={8}>
        <Image margin="5px auto 5px auto" src={ChallengeIcon} alt="Challenge Icon" width="30px" />
        <Text fontSize="12px">¡Vaya!, no hay retos disponibles actualmente.</Text>
        <Text fontSize="12px">No hace falta que estés pendiente, avisamos por correo con los nuevos retos.</Text>
      </Box>
    </Box>
  );
}
