/* eslint-disable no-console */

import React from 'react';

import {
  Box,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

export default function ChallengeItemModalSocialTags({ challenge, isParticipating }) {
  const { hashtags, mentions } = challenge;
  return (
    <Box pb={5} pl={5} pr={5}>
      <Popover>
        <PopoverTrigger>
          <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
            Menciones, etiquetas y hashtags <InfoOutlineIcon width="10px" />
          </Text>
        </PopoverTrigger>
        <PopoverContent boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontFamily="DM Sans" fontSize="12px" fontWeight="bold">
            Tu vídeo debe incluir
          </PopoverHeader>
          <PopoverBody fontFamily="DM Sans" fontSize="12px" fontWeight="normal">
            Las menciones y hashtags que la marca solicita. Son muy
            importantes ya que nos permitirá detectar tu vídeo.
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {isParticipating === true ? (
        <Box>
          <UnorderedList>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="14px">
                Etiqueta: <span style={{ color: 'black' }}>{mentions.map((m) => `@${m}`)}</span>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontFamily="DM Sans" fontSize="14px">
                Incluye en la descripción:{' '}
                <span style={{ color: 'black' }}>{hashtags.map((h) => `#${h} `)}</span>
              </Text>
            </ListItem>
          </UnorderedList>
        </Box>
      ) : (
        <Box>
          <Text fontFamily="DM Sans" fontSize="14px">Debes participar en el reto para poder ver los hashtags y menciones. Sin los hashtags tu vídeo no podrá ser revisado.</Text>
        </Box>
      )}
    </Box>
  );
}
