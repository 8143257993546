/* eslint-disable no-console */

import React from 'react';

import { Box, Image } from '@chakra-ui/react';

import { CloseIcon } from '@chakra-ui/icons';

import ImageDefaultHeader from '../../../../Assets/Images/banner-ugc-min.png';

export default function BriefingItemModalHeader({ briefingData, closeModal }) {
  const {
    brand: { logoUrl },
  } = briefingData;
  return (
    <Box
      height="150px"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundImage={ImageDefaultHeader}
      position="relative"
    >
      <Box height="150px" display="flex" justifyContent="initial" alignItems="center">
        <Image
          marginLeft="30px"
          borderRadius="50%"
          width="80px"
          height="80px"
          border="1px solid white"
          bg="white"
          src={logoUrl}
        />
      </Box>
      <Box
        onClick={closeModal}
        backgroundColor="white"
        height="40px"
        width="40px"
        border="1px solid"
        borderRadius="50%"
        position="absolute"
        top={4}
        right={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CloseIcon _hover={{ cursor: 'pointer' }} width={5} />
      </Box>
    </Box>
  );
}
