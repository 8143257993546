/* eslint-disable no-console */
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
  useDisclosure,
} from '@chakra-ui/react';

import { logOut } from '../../Redux/features/auth/authSlice';

import { useDeleteMutation } from '../../Redux/features/auth/authApiSlice';

export default function DeleteAccount() {

  const dispatch = useDispatch();
  const [deleteAccount] = useDeleteMutation();

  const { isOpen: isOpenConfirmation, onOpen: onOpenConfirmation, onClose: onCloseConfirmation } = useDisclosure();

  // Cerramos la sesión del usuario primero y luego borramos la cuenta
  const handleDeleteAccount = async () => {
    try {
      await deleteAccount().unwrap();
      // Borramos para que no pete la web al no tener el usuario
      dispatch(logOut());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpenConfirmation}
      onClose={onCloseConfirmation}
      placement="bottom"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          _hover={{ bg: '#FFFFFF' }}
          paddingRight="12px"
          paddingLeft="12px"
          color="black"
          variant="solid"
          size="xs"
          fontSize="14px"
          opacity="0.65"
          onClick={onOpenConfirmation}
        >
          <Flex alignItems="center">
            <Text textAlign="left" marginRight="5px">Eliminar cuenta</Text>
            {' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user-x-2"><path d="M14 19a6 6 0 0 0-12 0"/><circle cx="8" cy="9" r="4"/><line x1="17" x2="22" y1="8" y2="13"/><line x1="22" x2="17" y1="8" y2="13"/></svg>
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody fontSize="12px">
          <Text textAlign="left">¿Estás seguro que quieres borrar tu cuenta? </Text>
          <Text textAlign="left">Esta acción no se puede deshacer.</Text>
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <ButtonGroup size="xs">
            <Button onClick={onCloseConfirmation} fontSize="12px" variant="outline">Cancelar</Button>
            <Button fontSize="12px" colorScheme="red" onClick={() => handleDeleteAccount() }>Eliminar mi cuenta</Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
