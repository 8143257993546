/* eslint-disable no-console */

import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';

import { useUpdateCollabMutation } from '../../../../Redux/features/collabs/collabsApiSlice';

export default function BriefingShareVideosModal({
  collabData,
  isOpenShareVideosModal,
  onCloseShareVideosModal,
}) {

  // Redux
  const [updateCollab, { isLoading: isLoadingUpdateCollab }] = useUpdateCollabMutation();

  // Toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 10000,
    });
  };

  const [contentLink, setContentLink] = useState('');
  const handleChangeContentLink = (event) => setContentLink(event.target.value);

  // Función compartir los contenidos
  const handleShareCreatedContent = async () => {
    await updateCollab({ collabId: collabData._id, status: 'produced', contentLink });
    showToast(
      'Tus archivos se han compartido con la marca. Estate atento al chat y a las notificaciones para seguir las novedades en tu colaboración',
      'success',
    );
    setContentLink('');
    onCloseShareVideosModal();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpenShareVideosModal}
      onClose={onCloseShareVideosModal}
      closeOnOverlayClick
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="24px" m={5}>
        <ModalCloseButton />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          <Box mt={5}>
            <Text fontSize="20px" fontWeight="bold" mb={3}>
              Comparte el link a tus vídeos
            </Text>
            <Text fontSize="14px">
              Sube tus archivos a Wetransfer (cómo subir los vídeos a wetransfer) y comparte el link
              generado para que la marca pueda ver tus vídeos y darles el OK final. Si lo reciben,
              desbloquearemos el pago de la collab.
            </Text>
            <FormControl mt={5} mb={10}>
              <FormLabel mb={1} fontWeight="bold" fontSize="14px">
                Link WeTransfer
              </FormLabel>
              <Input
                placeholder="https://go.wetransfer.com/t-ig7Tohxs"
                name="name"
                size="sm"
                border="1px solid #000"
                borderRadius="8px"
                bg="white"
                value={contentLink}
                onChange={handleChangeContentLink}
              />
            </FormControl>
            <Button
              border="2px solid black"
              boxShadow="1px 1px 0px 0px #000"
              borderRadius="16px"
              bg="#EBD0FF"
              color="black"
              width="100%"
              onClick={handleShareCreatedContent}
              isLoading={isLoadingUpdateCollab}
            >
              Enviar
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
