/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import { FaCheck } from 'react-icons/fa';
import { MdPlayCircleOutline, MdPauseCircleOutline } from 'react-icons/md';

import {
  Box,
  ModalCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  useToast,
  IconButton,
  Center,
  Grid,
  Icon,
  ModalFooter,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';

import { useApplyToBriefingMutation } from '../../../Redux/features/briefings/briefingsApiSlice';

import PlayIcon from '../../../Assets/Images/ion_play.svg';

import CustomLink from '../../CustomLink/CustomLink';

function CustomCheckbox({ isChecked, onChange }) {
  return (
    <Center
      position="absolute"
      top="0"
      right="0"
      m="2"
      p="1"
      h="20px"
      w="20px"
      bg={isChecked ? '#EBD0FF' : '#E4E4D9'}
      color={isChecked ? 'black' : ''}
      border="1px solid #000"
      boxShadow="0.5px 0.5px 0px 0px #000"
      rounded="full"
      zIndex={2}
      onClick={onChange}
      cursor="pointer"
    >
      {isChecked && <Icon boxSize="10px" as={FaCheck} />}
    </Center>
  );
}

function CustomCheckbox2({ isChecked, onChange }) {
  return (
    <Center
      p="1"
      h="20px"
      width="20px"
      bg={isChecked ? '#EBD0FF' : '#E4E4D9'}
      color={isChecked ? 'black' : ''}
      border="1px solid #000"
      boxShadow="0.5px 0.5px 0px 0px #000"
      rounded="full"
      zIndex={2}
      onClick={onChange}
      cursor="pointer"
    >
      {isChecked && <Icon boxSize="10px" as={FaCheck} />}
    </Center>
  );
}

function VideoGrid({ videos, selectedVideos, setSelectedVideos }) {
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const toast = useToast();

  const handleCheckboxChange = (index) => {
    if (selectedVideos.includes(index)) {
      setSelectedVideos(selectedVideos.filter((videoIndex) => videoIndex !== index));
    } else if (selectedVideos.length >= 3) {
      toast({
        title: 'Selection limit reached',
        description: "You can't select more than 3 videos.",
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } else {
      setSelectedVideos([...selectedVideos, index]);
    }
  };

  return (
    <Grid
      maxH="400px"
      overflowY="auto"
      mt={1}
      templateColumns="repeat(2, 1fr)"
      gap={2}
      mb={2}
      p={2}
    >
      {videos.map((video, index) => (
        <Box
          border={selectedVideos.includes(index) ? '2px solid black' : '2px solid #feffef'}
          borderRadius="12px"
          key={video.url}
          position="relative"
          padding="5px"
          overflow="hidden"
        >
          <ReactPlayer
            url={video.url}
            playing={currentPlaying === index}
            width="100%"
            height="100%"
            style={{
              display: 'block',
              borderRadius: '8px',
              objectFit: 'cover',
              overflow: 'hidden',
            }}
            onClick={() => setCurrentPlaying(currentPlaying === index ? null : index)}
          />
          <IconButton
            aria-label={currentPlaying === index ? 'Pause' : 'Play'}
            icon={currentPlaying === index ? <MdPauseCircleOutline /> : <MdPlayCircleOutline />}
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            size="sm"
            color="black"
            onClick={() => setCurrentPlaying(currentPlaying === index ? null : index)}
            zIndex="1"
            bg="#ffffffab"
            fontSize="20px"
            isRound
          />
          <CustomCheckbox
            isChecked={selectedVideos.includes(index)}
            onChange={() => handleCheckboxChange(index)}
          />
        </Box>
      ))}
    </Grid>
  );
}

export default function BriefingApplyModal({
  userData,
  briefingData,
  isOpenModalApply,
  onCloseModalApply,
}) {
  const { title } = briefingData;

  const { ugcVideos } = userData;

  const toast = useToast();

  // Redux
  const [applyToBriefing, { isLoading: isLoadingApplyToBriefing }] = useApplyToBriefingMutation();

  // Para la selección de vídeos
  const [selectedVideos, setSelectedVideos] = useState([]);

  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // State for checkboxes
  const [isFirstChecked, setIsFirstChecked] = useState(false);
  const [isSecondChecked, setIsSecondChecked] = useState(false);
  const [isThirdChecked, setIsThirdChecked] = useState(false);

  // Toggle functions for each checkbox
  const toggleFirstCheckbox = () => setIsFirstChecked(!isFirstChecked);
  const toggleSecondCheckbox = () => setIsSecondChecked(!isSecondChecked);
  const toggleThirdCheckbox = () => setIsThirdChecked(!isThirdChecked);

  // Check if all checkboxes are checked
  const allChecked = isFirstChecked && isSecondChecked && isThirdChecked;

  // Simulated handleSubmit function
  const handleSubmit = async () => {
    // Todos los checks deben estar aceptados y leídos
    if (!allChecked) {
      toast({
        description: 'Debes aceptar todas las condiciones para poder aplicar',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    // Ensure there are selected videos
    if (selectedVideos.length === 0) {
      toast({
        description: 'Debes seleccionar al menos 1 vídeo para aplicar',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    // Según el index, cogemos los vídeos que ha elegido del array
    const selectedVideoData = selectedVideos.map((index) => ugcVideos[index]);

    try {
      // Enviamos los vídeos de confirmación para la aplicación
      await applyToBriefing({ briefingId: briefingData._id, videos: selectedVideoData }).unwrap();
      toast({
        description: 'Tu aplicación se ha enviado correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onCloseModalApply();
    } catch (error) {
      toast({
        description: 'No se ha podido enviar tu aplicación',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      closeOnOverlayClick
      isOpen={isOpenModalApply}
      size={isLargerThan768 ? 'md' : 'full'}
      scrollBehavior="inside"
      onClose={onCloseModalApply}
    >
      <ModalOverlay />
      <ModalContent borderRadius={isLargerThan768 ? '20px' : '0px'}>
        <ModalCloseButton
          bg="#EBD0FF"
          borderRadius="50%"
          m={1}
          boxShadow="1px 1px 0px 0px #000"
          border="2px solid black"
        />
        <ModalBody
          borderRadius={isLargerThan768 ? '20px' : '0px'}
          fontFamily="DM Sans"
          bg="#feffef"
          p={0}
        >
          {ugcVideos.length > 0 ? (
            <Box p={4}>
              {/* Intro y textos */}
              <Text fontWeight="bold" fontSize="20px" fontFamily="DM Sans">
                {title}
              </Text>
              <Flex mt={4} alignItems="center">
                <Box
                  border="1px solid black"
                  boxShadow="0.5px 0.5px 0px 0px #000"
                  width="20px"
                  height="20px"
                  borderRadius="50%"
                  backgroundRepeat="no-repeat"
                  backgroundSize="12px"
                  backgroundPosition="center"
                  backgroundColor="#ddded1"
                  backgroundImage={`url(${PlayIcon})`}
                  mr={2}
                  cursor="pointer"
                  _hover={{
                    backgroundColor: '#EBD0FF',
                  }}
                />
                <Text fontWeight="bold" fontSize="12px">
                  Selecciona tus mejores vídeos
                </Text>
              </Flex>
              <Box ml="27px" mt={2}>
                <Text fontSize="12px">
                  Elige mínimo 1 vídeo y máximo 3 de los que tienes subidos en tu perfil. Si no
                  tienes subidos ningún vídeo puedes realizarlo desde tu perfil. No podrás aplicar
                  sin vídeos.
                </Text>
              </Box>
              {/* Elige sus videos para la candidatura */}
              <Box mb={8}>
                <VideoGrid
                  selectedVideos={selectedVideos}
                  setSelectedVideos={setSelectedVideos}
                  videos={ugcVideos}
                />
              </Box>
              {/* Acepta los términos de todo */}
              <Box>
                <Flex alignItems="center" position="relative" mt={3} mb={3}>
                  <Box>
                    <CustomCheckbox2 isChecked={isFirstChecked} onChange={toggleFirstCheckbox} />
                  </Box>
                  <Text ml={2} fontSize="12px" onClick={toggleFirstCheckbox}>
                    Confirmo que soy capaz de cumplir lo que se me pide.
                  </Text>
                </Flex>
                <Flex alignItems="center" position="relative" mt={3} mb={3}>
                  <Box>
                    <CustomCheckbox2 isChecked={isSecondChecked} onChange={toggleSecondCheckbox} />
                  </Box>
                  <Text lineHeight={1.3} ml={2} fontSize="12px" onClick={toggleSecondCheckbox}>
                    Confirmo que produciré el contenido en 8 días a partir de la recepción del
                    producto
                  </Text>
                </Flex>
                <Flex alignItems="center" position="relative" mt={3} mb={3}>
                  <Box>
                    <CustomCheckbox2 isChecked={isThirdChecked} onChange={toggleThirdCheckbox} />
                  </Box>
                  <Text lineHeight={1.3} ml={2} fontSize="12px" onClick={toggleThirdCheckbox}>
                    Confirmo que devolveré el producto si no consigo producir el contenido en el
                    plazo establecido.
                  </Text>
                </Flex>
              </Box>
            </Box>
          ) : (
            <Flex alignItems="center" minH="300px" p={10}>
              <Box>
                <Text fontSize="12px" mb={5}>
                  Vaya, parece que aún tienes ningún vídeo para aplicar o se están subiendo todavía. Por favor, ve a tu perfil y
                  sube mínimo 1 vídeo para poder aplicar o vuelve en unos minutos.
                </Text>
                <CustomLink to="/ugc/profile/edit">
                  <Button
                    _hover={{ bg: '#000000' }}
                    paddingRight="12px"
                    paddingLeft="12px"
                    bg="#000000"
                    color="white"
                    variant="solid"
                    size="xs"
                  >
                    Subir mi primer vídeo
                  </Button>
                </CustomLink>
              </Box>
            </Flex>
          )}
        </ModalBody>
        {ugcVideos.length > 0 ? (
          <ModalFooter
            borderRadius="0px 0px 24px 24px"
            bg="#feffef"
            p="16px"
            boxShadow="0px -5px 10px #8888886b"
          >
            <Box width="100%">
              <Button
                border="2px solid black"
                boxShadow="1px 1px 0px 0px #000"
                borderRadius="16px"
                bg="#EBD0FF"
                color="black"
                width="100%"
                onClick={handleSubmit}
                isLoading={isLoadingApplyToBriefing}
              >
                Aplicar
              </Button>
            </Box>
          </ModalFooter>
        ) : (
          ''
        )}
      </ModalContent>
    </Modal>
  );
}
