import { Box, Flex, Text } from '@chakra-ui/react';

import calculateRelativeChallengeFinalizationDate from '../../../../Utils/calculateRelativeChallengeFinalizationDate';

export default function ChallengeItemModalRewards({ challenge, participantsCount, maxVideos }) {
  return (
    <Flex height="50px" backgroundColor="#fffff2">
      <Flex p={5} width="100%" justifyContent="space-between" alignItems="center">
        <Box textAlign="left">
          <Text lineHeight={1.2} fontFamily="DM Sans" fontSize="12px" fontWeight="normal">
            Participantes
          </Text>
          <Box fontFamily="DM Sans" fontSize="12px" fontWeight="semibold">
            {participantsCount}/{maxVideos}
          </Box>
        </Box>
        <Box textAlign="left">
          <Text lineHeight={1.2} fontFamily="DM Sans" fontSize="12px" fontWeight="normal">
            Fecha finalización
          </Text>
          <Text fontFamily="DM Sans" fontSize="12px" fontWeight="semibold">
            {calculateRelativeChallengeFinalizationDate(challenge.finalizationDate)}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
