// Main Imports
import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

// Main CSS
import './App.css';

// Public pages
import RegisterPage from './Pages/RegisterPage';
import LoginPage from './Pages/LoginPage';
import NotFound from './Pages/NotFound';

// App pages (login)
import ProtectedRoute from './Pages/ProtectedRoute';
import ChallengesPage from './Pages/ChallengesPage';
import HistoryPage from './Pages/HistoryPage';
import MissionsPage from './Pages/MissionsPage';

import ProfilePage from './Pages/ProfilePage';
import AffiliationsPage from './Pages/AffiliationsPage';

import UGCHomePage from './Pages/UGC/UGCHome';
import UGCOnboardingPage from './Pages/UGC/UGCOnboarding';
import UGCCollabsPage from './Pages/UGC/UGCCollabs';
// import UGCCollabPage from './Pages/UGC/UGCCollab';
import UGCCollabChatPage from './Pages/UGC/UGCCollabChat';
import UGCProfilePage from './Pages/UGC/UGCProfile';
import UGCProfileEditPage from './Pages/UGC/UGCProfileEdit';

import ProtectedRouteUGC from './Pages/ProtectedRouteUGC';

// Main App
export default function App() {
  const router = createBrowserRouter([
    {
      path: '/register',
      element: <RegisterPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/',
      element: <Navigate to="challenges" />,
    },
    {
      path: '/challenges',
      element: (
        <ProtectedRoute>
          <ChallengesPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/missions',
      element: (
        <ProtectedRoute>
          <MissionsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/history',
      element: (
        <ProtectedRoute>
          <HistoryPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/profile',
      element: (
        <ProtectedRoute>
          <ProfilePage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/links',
      element: (
        <ProtectedRoute>
          <AffiliationsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: '/ugc',
      element: (
        <ProtectedRoute>
          <ProtectedRouteUGC>
            <UGCHomePage />
          </ProtectedRouteUGC>
        </ProtectedRoute>
      ),
    },
    {
      path: '/ugc/onboarding',
      element: (
        <ProtectedRoute>
          <ProtectedRouteUGC>
            <UGCOnboardingPage />
          </ProtectedRouteUGC>
        </ProtectedRoute>
      ),
    },
    {
      path: '/ugc/collabs',
      element: (
        <ProtectedRoute>
          <ProtectedRouteUGC>
            <UGCCollabsPage />
          </ProtectedRouteUGC>
        </ProtectedRoute>
      ),
    },
    {
      path: '/ugc/collabs/:collabId',
      element: (
        <ProtectedRoute>
          <ProtectedRouteUGC>
            <UGCCollabChatPage />
          </ProtectedRouteUGC>
        </ProtectedRoute>
      ),
    },
    {
      path: '/ugc/collabs/:collabId/chat',
      element: (
        <ProtectedRoute>
          <ProtectedRouteUGC>
            <UGCCollabChatPage />
          </ProtectedRouteUGC>
        </ProtectedRoute>
      ),
    },
    {
      path: '/ugc/profile',
      element: (
        <ProtectedRoute>
          <ProtectedRouteUGC>
            <UGCProfilePage />
          </ProtectedRouteUGC>
        </ProtectedRoute>
      ),
    },
    {
      path: '/ugc/profile/edit',
      element: (
        <ProtectedRoute>
          <ProtectedRouteUGC>
            <UGCProfileEditPage />
          </ProtectedRouteUGC>
        </ProtectedRoute>
      ),
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);
  return <RouterProvider router={router} />;
}
