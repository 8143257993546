import { Box, useDisclosure } from '@chakra-ui/react';

import HelpModalUGC from '../HelpModalUGC/HelpModalUGC';

import InfoIcon from '../../Assets/Images/pepicons-pop_question.svg';

export default function UGCHelpButton() {
  const {
    isOpen: isOpenHelpModalUGC,
    onOpen: onOpenHelpModalUGC,
    onClose: onCloseHelpModalUGC,
  } = useDisclosure();
  return (
    <Box
      border="1px solid black"
      boxShadow="0.5px 0.5px 0px 0px #000"
      width="20px"
      height="20px"
      borderRadius="50%"
      backgroundRepeat="no-repeat"
      backgroundSize="12px"
      backgroundPosition="center"
      backgroundColor="#ddded1"
      backgroundImage={`url(${InfoIcon})`}
      mr={2}
      cursor="pointer"
      _hover={{
        backgroundColor: '#EBD0FF',
      }}
      onClick={onOpenHelpModalUGC}
    >
      <HelpModalUGC isOpen={isOpenHelpModalUGC} onClose={onCloseHelpModalUGC} />
    </Box>
  );
}
