/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import {
  Box,
  VStack,
  HStack,
  Select,
  Button,
  FormControl,
  FormLabel,
  Input,
  Center,
  Text,
  Flex,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { CloseIcon } from '@chakra-ui/icons';

import { MdOutlineFileUpload, MdPlayCircleOutline, MdPauseCircleOutline } from 'react-icons/md';

function VideoCard({ video, onFileChange, index }) {
  const [playing, setPlaying] = useState(false);
  const fileInputRef = React.useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <Box
      position="relative"
      borderRadius="12px"
      overflow="hidden"
      width="120px"
      height="210px"
      mr={2}
    >
      {video.url ? (
        <ReactPlayer
          url={video.url}
          width="100%"
          height="100%"
          style={{ borderRadius: '12px', objectFit: 'cover' }}
          playing={playing}
          onClick={() => setPlaying(!playing)}
        />
      ) : (
        <Center
          height="210px"
          flexDirection="column"
          cursor="pointer"
          onClick={triggerFileInput}
          bg="linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #C4C4C4"
        >
          <MdOutlineFileUpload size="35px" color="white" />
          <Text
            lineHeight={1.3}
            textAlign="center"
            color="white"
            fontWeight="bold"
            fontSize="12px"
            p="0 8px"
          >
            Pulsa para subir un video
          </Text>
        </Center>
      )}
      <Input
        type="file"
        accept="video/*"
        hidden
        onChange={(e) => onFileChange(e, index)}
        ref={fileInputRef}
      />
      {video.url && (
        <IconButton
          isRound
          icon={playing ? <MdPauseCircleOutline /> : <MdPlayCircleOutline />}
          aria-label={playing ? 'Pause' : 'Play'}
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
          size="sm"
          bg="#ffffffab"
          fontSize="20px"
          onClick={() => setPlaying(!playing)}
        />
      )}
    </Box>
  );
}

export default function VideosUploadUGC({
  isProfileEdit,
  videos,
  onFileChange,
  onRemove,
  onUpdateType,
  onUpdateCategory,
  onDeleteVideo,
  isLoadingDeleteVideo,
  setIsSaveDisabled,
}) {
  // State to track which video's categories are being edited
  const [editMode, setEditMode] = useState(Array(videos.length).fill(false));

  // Function to enable edit mode for video categorization
  const enableEdit = (index) => {
    const updatedEditMode = [...editMode];
    updatedEditMode[index] = true;
    setEditMode(updatedEditMode);
    if (isProfileEdit) {
      setIsSaveDisabled(false);
    }
  };

  // Modal to remove a video and confirm
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [videoToRemove, setVideoToRemove] = useState(null); // Track the video to remove
  const [videoIdToRemove, setVideoIdToRemove] = useState(null); // Track the video to remove (video id)

  const confirmRemoveVideo = (index) => {
    setVideoToRemove(index); // Set the video to remove
    onOpen(); // Open the modal
  };

  const confirmRemoveVideoProfileEdit = (videoId, index) => {
    setVideoToRemove(index);
    setVideoIdToRemove(videoId);
    onOpen();
  };

  const handleRemoveConfirmedVideo = () => {
    if (videoToRemove !== null) onRemove(videoToRemove);
    onClose(); // Close the modal
    setVideoToRemove(null); // Reset the removal state
  };

  const handleRemoveConfirmedVideoProfileEdit = async () => {
    // Si hay ID, es que viene de mongo y YA es un vídeo del usuario..
    if (videoIdToRemove) {
      await onDeleteVideo(videoIdToRemove);
    }

    if (videoToRemove !== null) onRemove(videoToRemove);

    setVideoToRemove(null);
    setVideoIdToRemove(null);

    onClose();
  };

  return (
    <>
      {/* Modal for confirming video removal */}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
        <ModalContent borderRadius="24px" m={5}>
          <ModalCloseButton />
          <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
            <Box mt={5}>
              <Text fontSize="20px" fontWeight="bold" mb={3}>
                ¿Seguro que quieres eliminar este vídeo?
              </Text>
              <Button
                border="2px solid black"
                boxShadow="1px 1px 0px 0px #000"
                borderRadius="16px"
                bg="#EBD0FF"
                color="black"
                width="100%"
                onClick={() =>
                  isProfileEdit
                    ? handleRemoveConfirmedVideoProfileEdit()
                    : handleRemoveConfirmedVideo()
                }
                mt={10}
                isLoading={isLoadingDeleteVideo}
              >
                Sí, eliminar
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* All videos by the user */}
      <VStack spacing={4}>
        {videos.map((video, index) => (
          <HStack
            background="#FFFFF2"
            p={4}
            borderRadius="24px"
            key={index}
            align="start"
            width="100%"
            position="relative"
          >
            {/* Video */}
            <VideoCard video={video} onFileChange={(e) => onFileChange(e, index)} index={index} />

            {/* Remove video X button con PopOver */}
            {video?.url && (
              <IconButton
                icon={<CloseIcon />}
                aria-label="Remove video"
                position="absolute"
                top="-5px"
                right="-5px"
                size="xs"
                borderRadius="50%"
                border="1px solid #000"
                onClick={() =>
                  isProfileEdit
                    ? confirmRemoveVideoProfileEdit(video?.id, index)
                    : confirmRemoveVideo(index)
                }
                bg="white"
              />
            )}

            {/* Categorización del vídeo */}
            <Flex flexDirection="column" height="210px" justifyContent="space-between">
              <Box>
                <Text fontWeight="bold" fontSize="16px" mb="8px">
                  Categoriza el vídeo
                </Text>

                {/* Tipo de vídeo */}
                <FormControl id={`video-type-${index}`} mb={2}>
                  <FormLabel mb={0} fontWeight="bold" fontSize="12px">
                    Tipo de Video
                  </FormLabel>
                  <Select
                    value={video.type}
                    onChange={(e) => onUpdateType(e, index)}
                    size="xs"
                    border="1px solid #000"
                    borderRadius="8px"
                    bg="white"
                    isDisabled={(isProfileEdit && !editMode[index]) || false}
                  >
                    <option value="nothing">--</option>
                    <option value="unboxing">Unboxing</option>
                    <option value="testimonial">Testimonio</option>
                    <option value="productAction">Producto en acción</option>
                    <option value="haul">Haul</option>
                    <option value="other">Otro</option>
                  </Select>
                </FormControl>

                {/* Tipo de producto */}
                <FormControl id={`video-category-${index}`}>
                  <FormLabel mb={0} fontWeight="bold" fontSize="12px">
                    Tipo de Producto
                  </FormLabel>
                  <Select
                    value={video.category}
                    onChange={(e) => onUpdateCategory(e, index)}
                    size="xs"
                    border="1px solid #000"
                    borderRadius="8px"
                    bg="white"
                    isDisabled={(isProfileEdit && !editMode[index]) || false}
                  >
                    <option value="nothing">--</option>
                    <option value="wellness">Bienestar</option>
                    <option value="food">Comida</option>
                    <option value="beverages">Bebida</option>
                    <option value="fashion">Moda</option>
                    <option value="beauty">Belleza</option>
                    <option value="home">Hogar</option>
                    <option value="fitness">Fitness</option>
                    <option value="pets">Mascotas</option>
                    <option value="technology">Tecnología</option>
                    <option value="travel">Viajes</option>
                    <option value="app">App - plataforma</option>
                    <option value="other">Otro</option>
                  </Select>
                </FormControl>
              </Box>

              {video.url && isProfileEdit && (
                <Box p={0}>
                  <Text
                    fontSize="12px"
                    fontWeight="light"
                    textDecoration="underline"
                    lineHeight={1.1}
                    p={0}
                    onClick={() => enableEdit(index)}
                  >
                    Editar categorización
                  </Text>
                </Box>
              )}
            </Flex>
          </HStack>
        ))}
      </VStack>
    </>
  );
}
