import apiSlice from '../../api/apiSlice';

export const collabsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCollabs: builder.query({
      query: () => 'collabs',
      providesTags: ['Collabs'],
    }),
    getCollabByID: builder.query({
      query: (id) => `collabs/${id}`,
      providesTags: ['Collabs'],
    }),
    sendMessageToCollab: builder.mutation({
      query: ({ collabId, messageContent }) => ({
        url: `collabs/${collabId}/messages`,
        method: 'POST',
        body: {
          content: messageContent,
        },
      }),
      invalidatesTags: ['Collabs'],
    }),
    updateCollab: builder.mutation({
      query: ({ collabId, status, contentLink }) => ({
        url: `collabs/${collabId}`,
        method: 'PATCH',
        body: {
          status,
          contentLink,
        },
      }),
      invalidatesTags: ['Collabs'],
    }),
  }),
});

export const {
  useGetCollabsQuery,
  useGetCollabByIDQuery,
  useSendMessageToCollabMutation,
  useUpdateCollabMutation,
} = collabsApiSlice;
