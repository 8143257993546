// Función para obtener el texto según los vídeos que se solicitan
export default function describeVideoNeeds(videoNeeds) {
  const videoTypes = {
    testimonialVideo: 'testimonial',
    unboxingVideo: 'unboxing',
    productActionVideo: 'en acción',
  };

  // Generate descriptions for each video type
  const descriptions = Object.entries(videoNeeds).map(([key, value]) => {
    // Determine the correct form of "vídeo" based on the count
    const videoWord = value > 1 ? 'vídeos' : 'vídeo';
    return `${value} ${videoWord} ${videoTypes[key]}`;
  });

  // Join the descriptions with a comma and space
  return descriptions.join(', ');
}
