import React from 'react';

import { Box } from '@chakra-ui/react';

import { useGetCollabsQuery } from '../../Redux/features/collabs/collabsApiSlice';
import { useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';

import LoadingBriefings from '../../Components/LoadingBriefings/LoadingBriefings';
import NoBriefingsOrCollabsAvailables from '../../Components/NoBriefingsOrCollabsAvailables/NoBriefingsOrCollabsAvailables';

import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import HeaderUGC from '../../Components/HeaderUGC/HeaderUGC';
import FooterNavUGC from '../../Components/FooterNavUGC/FooterNavUGC';
import BriefingItem from '../../Components/BriefingItem/BriefingItem';

// Milisegundos que solicitamos los datos del usuario
const POLLING_INTERVAL = 30000;

export default function UGCCollabs() {
  // Pedimos datos de los briefings que hay disponibles
  const { data: collabs, isLoading: isLoadingCollabs } = useGetCollabsQuery();

  const { data: user, isLoading: isUserLoading } = useGetUserDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  if (isLoadingCollabs || isUserLoading) {
    return <LoadingBriefings />;
  }

  return (
    <Box bg="#ddded1" minH="100vh">
      <ScrollToTop />
      <HeaderUGC userData={user} />
      <Box pb={10} m={5}>
        <Box mb={8} maxWidth="700px" margin="0 auto">
          {collabs &&
            collabs.data &&
            collabs.data.map((collab) => (
              <BriefingItem
                key={collab._id}
                isCollab
                collabData={collab}
                briefingData={collab.briefing}
                userData={user}
              />
            ))}
          {collabs && collabs.data?.length === 0 && <NoBriefingsOrCollabsAvailables isCollab />}
        </Box>
      </Box>
      <FooterNavUGC />
    </Box>
  );
}
