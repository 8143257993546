import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

import ChallengeIcon from '../../Assets/Images/empty-folder.png';

export default function NoMissionsAvailablesToParticipate() {
  return (
    <Box
      fontFamily="DM Sans"
      width="100%"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      bg="fffff2"
      mb={4}
    >
      <Box textAlign="center" p={8}>
        <Image margin="5px auto 5px auto" src={ChallengeIcon} alt="Challenge Icon" width="30px" />
        <Text fontSize="12px">No hay más misiones en las que participar.</Text>
        <Text fontSize="12px">
          No hace falta que estés pendiente, avisamos por correo con las nuevas misiones.
        </Text>
      </Box>
    </Box>
  );
}
