/* eslint-disable no-alert */
/* eslint-disable no-console */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  useBoolean,
  useToast,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react';

import { EditIcon } from '@chakra-ui/icons';

import LogOut from '../LogOut/LogOut';
import DeleteAccount from '../DeleteAccount/DeleteAccount';
import CreatorInfoModal from '../CreatorInfoModal/CreatorInfoModal';

import { setUserData } from '../../Redux/features/auth/authSlice';
import { useUpdateMutation } from '../../Redux/features/auth/authApiSlice';

export default function UserData({ userData }) {
  const { phone, location, gender, categories, birthDate } = userData;

  // Show new modal for user info logic
  const shouldShowModal =
    Object.keys(userData.tikTokInfo || {}).length > 0 &&
    (!gender || !categories?.length || !birthDate);

  // Redux
  const dispatch = useDispatch();
  const [update, { isLoading }] = useUpdateMutation();

  // Collapse
  const { isOpen, onToggle } = useDisclosure();

  // Toast
  const toast = useToast();

  // Bloqueo de edición
  const [flag, setFlag] = useBoolean(true);

  // Móvil
  const [userPhone, setUserPhone] = useState(phone || '');
  const handleChangeUserPhone = (e) => setUserPhone(e.target.value);

  // Ubicación
  const [userUbication, setUserUbication] = useState(location || '');
  const handleChangeUserUbication = (e) => setUserUbication(e.target.value);

  // Fecha de nacimiento
  const [userBirthDate, setUserBirthDate] = useState('');

  const handleChangeUserBirthDate = (e) => {
    setUserBirthDate(e.target.value);
  };

  // Show toast and type
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Enviamos al backend los cambios del usuario
  const handleSubmitEditProfile = async () => {
    if (userPhone.length === 0 || userUbication.length === 0 || userBirthDate.length === 0) {
      alert('Ningún campo puede estar vacío');
      return;
    }

    const newUserInfo = {
      birthDate: userBirthDate,
      location: userUbication,
      phone: userPhone,
    };

    try {
      const userUpdatedData = await update(newUserInfo).unwrap();
      showToast('Tu perfil ha sido actualizado', 'success');
      dispatch(setUserData(userUpdatedData));
    } catch (error) {
      showToast('Hubo un problema con el login', 'error');
      console.log(error);
    }
  };

  return (
    <Box maxWidth="700px" margin="0 auto">
      <Box mt={6}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Tus datos
        </Heading>
        <Flex justifyContent="space-between">
          <Text maxWidth="250px" fontStyle="italic" opacity="0.8" fontSize="12px">
            Mantén actualizados tus datos para acceder a retos exclusivos
          </Text>
          <Button
            onClick={setFlag.toggle}
            rightIcon={<EditIcon />}
            size="xs"
            colorScheme="black"
            variant="link"
          >
            Editar perfil
          </Button>
        </Flex>
      </Box>

      <Box mt={4} mb={5}>
        <FormControl>
          <FormLabel mb={1}>Móvil</FormLabel>
          <Input
            disabled={flag}
            value={userPhone}
            onChange={handleChangeUserPhone}
            borderRadius="8px"
            size="sm"
            type="tel"
            bg="white"
          />
        </FormControl>
        <Box mb={2} />
        <FormControl>
          <FormLabel mb={1}>Ubicación</FormLabel>
          <Input
            disabled={flag}
            value={userUbication}
            onChange={handleChangeUserUbication}
            borderRadius="8px"
            size="sm"
            bg="white"
            type="text"
          />
          <FormHelperText fontSize="12px" mt={1}>
            Accede a retos exclusivos en tu zona
          </FormHelperText>
        </FormControl>
        <Box mb={2} />
        <FormControl>
          <FormLabel mb={1}>Fecha de nacimiento</FormLabel>
          <Input
            disabled={flag}
            value={birthDate ? new Date(birthDate).toISOString().substr(0, 10) : userBirthDate}
            onChange={handleChangeUserBirthDate}
            borderRadius="8px"
            size="sm"
            type="date"
            bg="white"
          />
        </FormControl>
        <Box mb={2} />
        <Button
          isLoading={isLoading}
          onClick={handleSubmitEditProfile}
          isDisabled={flag}
          mt={2}
          size="md"
          height="38px"
          width="100%"
          fontSize="14px"
          _hover={{ bg: '#FFFFF2' }}
          _focus={{ bg: '#FFFFF2' }}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#FFFFF2"
          color="black"
        >
          Guardar
        </Button>
      </Box>

      <Box mt={10} mb={5}>
        <hr />
      </Box>

      <Box textAlign="center" mb={5}>
        <Button
          _hover={{ bg: '#FFFFFF' }}
          bg="#FFFFFF"
          paddingRight="12px"
          paddingLeft="12px"
          color="black"
          size="xs"
          fontSize="14px"
          opacity="0.65"
          onClick={onToggle}
        >
          <Text textAlign="left" marginRight="3px">
            Otras acciones
          </Text>{' '}
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-up"
            >
              <path d="m18 15-6-6-6 6" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-down"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          )}
        </Button>
        <Collapse in={isOpen} transition={{ exit: { delay: 0.1 }, enter: { duration: 0.1 } }}>
          <Box justifyContent="center" display="flex" mt={5} mb={5} textAlign="center">
            <DeleteAccount />
            <Box marginRight={2} />
            <LogOut />
          </Box>
        </Collapse>
      </Box>

      <CreatorInfoModal isOpen={shouldShowModal} />
    </Box>
  );
}
