import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import getStatusColor from '../../../../Utils/getStatusColor';
import geStatusText from '../../../../Utils/getStatusText';
import calculateRelativeDateBriefingFinalization from '../../../../Utils/calculateRelativeDateBriefingFinalization';

export default function BriefingItemModalStatus({ briefingData, applicantStatus }) {
  const { launchDate, title } = briefingData;
  return (
    <Box bg="#E4E4D9" boxShadow="0px 4px 10px 0px #FFFFF2" borderRadius="0px 0px 24px 24px">
      <Flex justifyContent="space-between" alignItems="center" p={3}>
        <Box
          fontWeight="bold"
          fontSize="12px"
          bg={getStatusColor(applicantStatus)}
          color={applicantStatus === 'not_applied' ? 'white' : 'black'}
          border={applicantStatus === 'selected' ? '1px solid #000' : '1px solid #FFFFF2'}
          boxShadow={applicantStatus === 'selected' ? '4px 4px 0px 0px #000' : ''}
          borderRadius="24px"
          px="10px"
          py="2px"
        >
          {geStatusText(applicantStatus)}
        </Box>
        <Box>
          <Text fontSize="12px" color="rgba(0, 0, 0, 0.56)">
            {calculateRelativeDateBriefingFinalization(launchDate)}
          </Text>
        </Box>
      </Flex>
      <Box p="0px 16px 16px 16px">
        <Text fontWeight="bold" fontSize="20px">
          {title}
        </Text>
      </Box>
    </Box>
  );
}
