/* eslint-disable no-console */

import React from 'react';

import { Box, Text, Flex, useToast } from '@chakra-ui/react';

import { useClaimReferralMutation } from '../../../Redux/features/challenges/challengesApiSlice';

import Status1 from '../../../Assets/Images/state1.svg';
import Status2 from '../../../Assets/Images/state2.svg';
import Status3 from '../../../Assets/Images/state3.svg';

const funnyImagesArray = [
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/misc/referral1.png',
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/misc/referral2.png',
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/misc/referral3.png',
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/misc/referral4.png',
  'https://cruwi-creators.s3.eu-west-3.amazonaws.com/misc/referral5.png',
];

const getRandomProfileImage = () => {
  const randomIndex = Math.floor(Math.random() * funnyImagesArray.length);
  return funnyImagesArray[randomIndex];
};

const getRightStatus = (status) => {
  let imageUrl = Status1;
  if (status === 'pending') {
    imageUrl = Status1;
  } else if (status === 'approved') {
    imageUrl = Status2;
  } else if (status === 'claimed') {
    imageUrl = Status3;
  }
  return imageUrl;
};

const getRightStatusText = (friendStatus) => {
  let text = Status1;
  if (friendStatus === 'pending') {
    text = 'Pendiente de completar reto';
  } else if (friendStatus === 'approved') {
    text = 'Reto completado, reclama la recompensa';
  } else if (friendStatus === 'claimed') {
    text = 'Recompensa obtenida';
  }
  return text;
};

export default function ReferralFriendItem({ referralInfo }) {
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  const [claimReferral] = useClaimReferralMutation();

  const claimReferralReward = async () => {
    try {
      await claimReferral(referralInfo.id).unwrap();
      showToast('Las recompensas han sido añadidas a tu saldo', 'success');
    } catch (error) {
      showToast('Hubo un problema reclamando tu recompensa, escríbenos.', 'error');
      console.log(error);
    }
  };

  const handleClickStatus = () => {
    if (referralInfo.status === 'approved') {
      claimReferralReward();
    } else {
      console.log('Nada');
    }
  };

  return (
    <Box paddingBottom="10px" paddingTop="10px" borderBottom="1px solid #d9d4d4">
      <Flex alignItems="center">
        <Box
          width="40px"
          height="40px"
          borderRadius="50%"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundImage={`url(${getRandomProfileImage()})`}
        />
        <Flex marginLeft={2} flexDirection="column">
          <Text fontWeight="bold" fontSize="14px">
            {referralInfo.tikTok}
          </Text>
          <Text fontSize="12px">{getRightStatusText(referralInfo.status)}</Text>
        </Flex>
        <Box
          onClick={() => handleClickStatus()}
          marginLeft="auto"
          width="40px"
          height="40px"
          borderRadius="50%"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundImage={getRightStatus(referralInfo.status)}
        />
      </Flex>
    </Box>
  );
}
